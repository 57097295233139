import { Activity, ActivityStatus, ActivityType } from './activity';
import { ActivityLayoutFile } from './activity-layout-file';
import { User } from './user';

export enum ActivityLayoutStatus {
  CLIENT_APPROVE = 'client_approve',
  VENDOR_APPROVE = 'vendor_approve',
  APPROVED = 'approved',
  REPROVED = 'reproved',
  PAUSED = 'paused',
}

export type ActivityLayout = {
  id: number;
  activity: number;
  artUser: number;
  status: ActivityLayoutStatus;
  vendorName: string;
  vendorNote: string;
  clientName: string;
  clientNote: string;
  approval: string;
  createdAt: string;
};

export type ActivityLayoutPopulated = Omit<
  ActivityLayout,
  'activity' | 'artUser'
> & {
  activity: Activity;
  artUser: User;
};

export type ActivityLayoutByOrder = {
  activityLayout: ActivityLayout;
  files: ActivityLayoutFile[];
};

export type ActivityLayoutHistory = {
  id: number;
  activity: number;
  artUser: number;
  status: ActivityLayoutStatus;
  vendorName: string;
  vendorNote: string;
  clientName: string;
  clientNote: string;
  approval: string;
  createdAt: string;
  files: ActivityLayoutFile[];
};

export type ActivityLayoutHistoryResponse = {
  activity: {
    id: number;
    type: ActivityType;
    status: ActivityStatus;
    createdAt: string;
    layouts: ActivityLayoutHistory[];
  };
};
