import { useState } from 'react';
import { useGetPermissionsByUser } from '../data/hooks/permissions';
import { PermissionAlias } from '../utils/enums';

export type PermissionsState = PermissionAlias[];

export const usePermissions = () => {
  const { fetch: getPermissionsByUserFetcher } = useGetPermissionsByUser();

  const [permissions, setPermissions] = useState<PermissionAlias[]>([]);

  const getPermissions = async () => {
    await getPermissionsByUserFetcher({
      onSuccess: (notList) => set(notList ?? []),
    });
  };

  const set = (permissions: PermissionsState) => {
    setPermissions(permissions);
  };

  return {
    set,
    getPermissions,
    permissions,
  };
};
