import { AxiosResponse } from 'axios';
import {
  ServiceCheckItem,
  ServiceCheckItemPopulated,
} from '../../models/service-check-item';
import { api } from '../../providers/base-api';
import { CreateServiceCheckItemRequestData } from '../../utils/types/data/services/service-check-items';

export async function createServiceCheckItemRequest(
  serviceId: number,
  data: CreateServiceCheckItemRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `service-check-items/${serviceId}`,
    data,
  );

  return response;
}

export async function listServiceCheckItemByServiceRequest(
  serviceId: number,
): Promise<AxiosResponse<ServiceCheckItem[]>> {
  const response = await api.get<ServiceCheckItem[]>(
    `service-check-items/${serviceId}`,
  );

  return response;
}

export async function listServiceCheckItemRequest(): Promise<
  AxiosResponse<ServiceCheckItemPopulated[]>
> {
  const response = await api.get<ServiceCheckItemPopulated[]>(
    `service-check-items`,
  );

  return response;
}

export async function removeServiceCheckItemRequest(
  serviceId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`service-check-items/${serviceId}`);

  return response;
}

export async function restoreServiceCheckItemRequest(
  serviceId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`service-check-items/${serviceId}`);

  return response;
}
