import { ThemeIcon, NavLink } from '@mantine/core';
import { IconServicemark } from '@tabler/icons';
import { AppRoutePaths } from '../../../utils/enums';

interface ActivitiesLinkProps {
  navigate(to: string): void;
  themeColor: 'dark' | 'light';
}

export function ServicesLink({ navigate, themeColor }: ActivitiesLinkProps) {
  return (
    <NavLink
      styles={{
        label: {
          color: themeColor === 'light' ? '#373A40' : '#FBFBFB',
        },
      }}
      label="Serviços"
      fw="bold"
      color=""
      icon={
        <ThemeIcon color="blue" variant="light">
          <IconServicemark size={16} />
        </ThemeIcon>
      }
      childrenOffset={28}
    >
      <NavLink
        label="Itens de Checklist"
        onClick={() => navigate(AppRoutePaths.SERVICE_ITEM_LIST)}
      />
    </NavLink>
  );
}
