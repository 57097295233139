/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Accordion,
  Badge,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Text,
  Textarea,
  ThemeIcon,
  Title,
  Tooltip,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconArrowLeft,
  IconClock,
  IconHistory,
  IconMessage,
  IconPlayerPlay,
  IconPlayerStop,
} from '@tabler/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ActivityLayoutHistoryItem,
  ActivityLayoutItem,
} from '../../components/ActivityLayout';
import { CustomLoader } from '../../components/CustomLoader';
import { CustomMultipleFileInput } from '../../components/CustomMutipleFileInput';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { OrderDetails } from '../../components/OrderDetails';
import { Page } from '../../components/Page';
import {
  useCreateActivityChecklist,
  useListActivityChecklist,
} from '../../data/hooks/activity-checklist';
import {
  useAddActivityLayoutFile,
  useCreateActivityLayout,
  useGetActivityLayoutHistory,
} from '../../data/hooks/activity-layouts';
import { useListOptions } from '../../data/hooks/options';
import { useListServiceCheckItemByService } from '../../data/hooks/service-check-items';
import {
  finishActivityRequest,
  showActivityRequest,
} from '../../data/services/activities';
import {
  createArtActivityRequest,
  getArtActivityListRequest,
  getUserLasArtActivityRequest,
  setArtActivityClockoutRequest,
} from '../../data/services/art-activities';

import { getOrderRequest } from '../../data/services/orders';
import { useTimer } from '../../hooks/use-timer';
import {
  Activity,
  ActivityComplexity,
  ActivityStatus,
} from '../../models/activity';
import {
  ActivityLayoutHistory,
  ActivityLayoutStatus,
} from '../../models/activity-layout';
import {
  ArtProductionType,
  PopulatedArtActivity,
} from '../../models/art-activity';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { Order } from '../../models/order';
import { UserRole } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  activityComplexityColor,
  activityComplexityHumanized,
  activityStatusColor,
  activityStatusHumanized,
} from '../../utils/constants/activity';

import {
  useAddActivityProductionFile,
  useCreateActivityProduction,
  useGetActivityProductionHistory,
} from '../../data/hooks/activity-productions';
import { artActivityTypeHumanized } from '../../utils/constants/art-activity';
import { secondsToTime, translateServerHttpErrors } from '../../utils/helpers';
import { useUploadMultipleFiles } from '../../data/hooks/files';

type ActivityChecklistState = {
  [key: string]: {
    serviceCheckItem: number;
    value: boolean;
  };
};

type ArtProductionListProps = {
  productionList: PopulatedArtActivity[];
};

function ArtProductionList({ productionList }: ArtProductionListProps) {
  return (
    <>
      {productionList[0] && (
        <ScrollArea mt={8} h={120}>
          {productionList?.reverse().map((prodItem, index) => {
            const { hours, minutes, seconds } = secondsToTime(
              prodItem?.timeAmount,
            );

            return (
              <Flex key={index} mt={8} mb={8} align={'center'}>
                <ThemeIcon p={4} mr={8} color="green" variant="light">
                  <IconClock size={26} />
                </ThemeIcon>
                <Text mr={8}>{formatLocale(prodItem.clockIn, 'DD/MM/YY')}</Text>
                <Text mr={8}>{artActivityTypeHumanized[prodItem.type]} -</Text>
                <Text
                  maw={160}
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                  mr={8}
                >
                  {prodItem.artUser.username}
                </Text>
                <Badge>
                  {prodItem.clockOut !== null &&
                    `${hours}h ${minutes}m ${seconds}s`}
                </Badge>
                {prodItem.description && (
                  <Tooltip
                    maw={200}
                    multiline
                    label={prodItem.description}
                    withArrow
                  >
                    <Button
                      ml={'xs'}
                      variant="subtle"
                      compact
                      p={0}
                      children={<IconMessage />}
                    />
                  </Tooltip>
                )}
              </Flex>
            );
          })}
        </ScrollArea>
      )}
    </>
  );
}

export function ArtProduction() {
  const { id } = useParams();
  const { user } = useSelector((state: RootState) => state.auth);
  const productionForm = useRef<HTMLFormElement>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [lastLayout, setLastLayout] = useState<ActivityLayoutHistory>();
  // const [lastActivityProduction, setLastActivityProduction] =
  //   useState<ActivityProductionHistoryResponse>();
  const [pageModalVisible, setPageModalVisible] = useState(false);
  const [lastProduction, setLastProduction] = useState<PopulatedArtActivity>();
  const [isOnProgress, setisOnProgress] = useState(false);
  const [activity, setActivity] = useState<Activity>();
  const [order, setOrder] = useState<Order>();
  const [productionList, setProductionList] = useState<PopulatedArtActivity[]>(
    [],
  );
  // const [activityChecklist, setActivityChecklist] =
  //   useState<ActivityChecklistState>({});

  const {
    fetch: listStepsOptionsFetcher,
    reponseData: listStepsOptionsData,
    loading: listStepsOptionsLoader,
  } = useListOptions();
  const {
    fetch: createActivityLayoutFetcher,
    loading: createActivityLayoutLoader,
  } = useCreateActivityLayout();
  const {
    fetch: addActivityLayoutFileFetcher,
    loading: addActivityLayoutFileLoader,
  } = useAddActivityLayoutFile();
  const {
    fetch: getActivityLayoutHistoryFetcher,
    loading: getActivityLayoutHistoryLoader,
    reponseData: getActivityLayoutHistoryData,
  } = useGetActivityLayoutHistory();
  const {
    fetch: getServiceCheckItemFetcher,
    loading: getServiceCheckItemLoader,
    // reponseData: getServiceCheckItemData,
  } = useListServiceCheckItemByService();
  const {
    // fetch: createActivityChecklistFetcher,
    loading: createActivityChecklistLoader,
  } = useCreateActivityChecklist();
  const {
    fetch: listActivityChecklistFetcher,
    // reponseData: listActivityChecklistData,
    loading: listActivityChecklistLoader,
  } = useListActivityChecklist();
  const {
    fetch: getActivityProductionHistoryFetcher,
    loading: getActivityProductionHistoryLoader,
    // reponseData: getActivityProductionHistoryData,
  } = useGetActivityProductionHistory();
  const { fetch: uploadFilesFetcher, loading: uploadFilesLoader } =
    useUploadMultipleFiles();
  const { days, hours, minutes, seconds } = useTimer({
    deadline: lastProduction?.clockIn ?? '',
  });

  const {
    // fetch: createActivityProductionFetcher,
    loading: createActivityProductionLoader,
  } = useCreateActivityProduction();

  const {
    // fetch: addActivityProductionFileFetcher,
    loading: addActivityProductionFileLoader,
  } = useAddActivityProductionFile();

  const formProduction = useForm({
    initialValues: {
      type: '',
      complexity: '',
      description: '',
    },
  });

  const formOrderLayout = useForm({
    initialValues: {
      files: [],
    },
  });

  // const formProductionFile = useForm({
  //   initialValues: {
  //     files: [],
  //   },
  // });

  const isAllowedToProduction =
    user?.role === UserRole.FINAL_ART &&
    activity?.status !== ActivityStatus.DONE;

  const currentActivityLayout = useMemo(
    () =>
      getActivityLayoutHistoryData?.some(
        (item) => item.activity.id === Number(id),
      ),
    [getActivityLayoutHistoryData?.length],
  );

  const disableSaveActivity = useMemo(() => {
    if (
      lastLayout &&
      [
        ActivityLayoutStatus.APPROVED,
        ActivityLayoutStatus.CLIENT_APPROVE,
        ActivityLayoutStatus.VENDOR_APPROVE,
      ].includes(lastLayout?.status)
    ) {
      return false;
    } else if (
      activity?.status !== ActivityStatus.APPROVED &&
      !currentActivityLayout
    ) {
      return true;
    }

    return true;
  }, [lastLayout, activity, currentActivityLayout]);

  const renderSendLayoutButton = useMemo(() => {
    if (
      activity?.status === ActivityStatus.DONE &&
      lastLayout?.status === ActivityLayoutStatus.APPROVED
    ) {
      return false;
    }

    return (
      !lastLayout ||
      (lastLayout.activity !== Number(id) &&
        activity?.status !== ActivityStatus.APPROVED) ||
      lastLayout?.status === ActivityLayoutStatus.REPROVED
    );
  }, [lastLayout, activity, id]);

  async function handleSubmit(values: typeof formProduction.values) {
    if (values.type === 'finish') {
      try {
        setPageLoading(true);
        await finishActivityRequest(Number(id), {
          complexity: values.complexity as ActivityComplexity,
        });
        getActivityData();
        setPageLoading(false);
      } catch (error) {
        setPageLoading(false);
        errorNotification({
          title: 'Erro ao finalizar atividade.',
          message: 'tente novamente',
        });
      }
    } else {
      try {
        setPageLoading(true);
        const response = await createArtActivityRequest(Number(id), {
          type: values.type as ArtProductionType,
          description: values.description,
        });
        setPageLoading(false);
        setisOnProgress(true);
        setLastProduction({ ...response, clockOut: null });
        setProductionList([...productionList, { ...response, clockOut: null }]);
        getActivityData();
      } catch (error: any) {
        setPageLoading(false);
        setisOnProgress(false);
        errorNotification({
          title: 'Erro ao atualizar atividade.',
          message: translateServerHttpErrors(error, 'tente novamente'),
        });
      }
    }
  }

  async function handleStartProduction() {
    productionForm.current?.requestSubmit();
  }

  async function handleStopProduction() {
    if (!isOnProgress || !lastProduction) return;

    try {
      setPageLoading(true);
      await setArtActivityClockoutRequest(lastProduction.id);
      setPageLoading(false);
      setisOnProgress(false);
      formProduction.reset();
      await getArtActivityData();
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao encerrar etapa.',
        message: 'tente novamente',
      });
    }
  }

  async function getActivityData() {
    try {
      setPageLoading(true);
      const response = await showActivityRequest(Number(id));
      const orderResponse = await getOrderRequest(response.order);

      setActivity(response);
      setOrder(orderResponse);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar informações.',
        message: 'tente novamente',
      });
    }
  }

  async function getArtActivityData() {
    try {
      setPageLoading(true);
      const lastArtActivityResponse = await getUserLasArtActivityRequest(
        Number(id),
      );
      const artActivityListResponse = await getArtActivityListRequest(
        Number(id),
      );

      if (
        lastArtActivityResponse &&
        lastArtActivityResponse.clockOut === null
      ) {
        setisOnProgress(true);
        formProduction.setFieldValue('type', lastArtActivityResponse.type);
      }
      setProductionList(artActivityListResponse);
      setLastProduction(lastArtActivityResponse);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar informações complementares.',
        message: 'tente novamente',
      });
    }
  }

  async function handleSubmitOrderLayout(
    values: typeof formOrderLayout.values,
  ) {
    if (values.files.length) {
      let fileIds: number[] = [];
      await uploadFilesFetcher({
        data: {
          files: values.files,
        },
        onSuccess: (res) => {
          fileIds = res;
        },
      });

      await createActivityLayoutFetcher({
        activityId: Number(id),
        onSuccess: async (res) => {
          await addActivityLayoutFileFetcher({
            id: res.id,
            data: {
              files: fileIds,
            },
            onSuccess: () => {
              getLayoutHistory();
              setPageModalVisible(false);
              formOrderLayout.reset();
              getActivityData();
              successNotification({
                title: 'Tudo certo!',
                message: 'arquivos enviados com sucesso ✅',
              });
            },
          });
        },
      });
    }
  }

  // async function handleSubmitAddActivityProduction(
  //   values: typeof formProductionFile.values,
  // ) {
  //   if (values.files.length) {
  //     let fileIds: number[] = [];
  //     await uploadFilesFetcher({
  //       data: {
  //         files: values.files,
  //       },
  //       onSuccess: (res) => {
  //         fileIds = res;
  //       },
  //     });

  //     await createActivityProductionFetcher({
  //       activityId: Number(id),

  //       onSuccess: async (res) => {
  //         await addActivityProductionFileFetcher({
  //           id: res.id,
  //           data: {
  //             files: fileIds,
  //           },
  //           onSuccess: () => {
  //             setPageModalVisible(false);
  //             formProductionFile.reset();
  //             getProductionHistory();
  //             successNotification({
  //               title: 'Tudo certo!',
  //               message: 'arquivos enviados com sucesso ✅',
  //             });
  //           },
  //         });
  //       },
  //     });
  //   }
  // }

  async function getStepsOptions() {
    await listStepsOptionsFetcher({
      query: {
        type: OptionTypes.ART_PRODUCTION,
        subtype: OptionSubTypes.STEPS,
      },
    });
  }

  async function getServiceChekItems() {
    order &&
      (await getServiceCheckItemFetcher({
        serviceId: order?.service.id,
      }));
  }

  // async function handleCreateActivityChecklist() {
  //   if (getServiceCheckItemData?.length) {
  //     const hasFalseValue = Object.values(activityChecklist).find(
  //       (item) => item.value !== true,
  //     );
  //     const notAllItemChecked =
  //       Object.keys(activityChecklist).length < getServiceCheckItemData?.length;

  //     if (hasFalseValue || notAllItemChecked) {
  //       errorNotification({
  //         title: 'Necessário revisar checklist!',
  //         message: 'marque todos os itens para prosseguir',
  //       });

  //       return;
  //     }

  //     await createActivityChecklistFetcher({
  //       activityId: Number(id),
  //       data: {
  //         items: Object.values(activityChecklist),
  //       },
  //       onSuccess: () => {
  //         setActivityChecklist({});
  //         getChecklist();
  //       },
  //     });
  //   }
  // }

  async function getChecklist() {
    await listActivityChecklistFetcher({
      query: {
        activity: Number(id),
      },
      onSuccess: (items) => {
        const baseStateChecklist: ActivityChecklistState = {};
        items.forEach((item) => {
          baseStateChecklist[item.serviceCheckItem] = {
            serviceCheckItem: item.serviceCheckItem,
            value: item.value,
          };
        });
        // setActivityChecklist({ ...baseStateChecklist });
      },
    });
  }

  async function getLayoutHistory() {
    if (order) {
      await getActivityLayoutHistoryFetcher({ orderId: Number(order.id) });
    }
  }

  async function getProductionHistory() {
    if (order) {
      await getActivityProductionHistoryFetcher({ orderId: Number(order.id) });
    }
  }

  useEffect(() => {
    getActivityData();
    getArtActivityData();
    getStepsOptions();
  }, [id]);

  useEffect(() => {
    getServiceChekItems();
    getChecklist();
  }, [order?.id]);

  useEffect(() => {
    if (getActivityLayoutHistoryData?.length) {
      const last =
        getActivityLayoutHistoryData[0].activity.layouts[
          getActivityLayoutHistoryData[0].activity.layouts.length - 1
        ];
      setLastLayout(last);
    }
  }, [getActivityLayoutHistoryData?.length]);

  // useEffect(() => {
  //   if (getActivityProductionHistoryData?.length) {
  //     const last = getActivityProductionHistoryData[0];
  //     setLastActivityProduction(last);
  //   }
  // }, [getActivityProductionHistoryData?.length]);

  useEffect(() => {
    getLayoutHistory();
    getProductionHistory();
  }, [order?.id]);

  return (
    <Page title="Andamento de atividade">
      <CustomLoader
        loading={
          pageLoading ||
          listStepsOptionsLoader ||
          createActivityLayoutLoader ||
          addActivityLayoutFileLoader ||
          getServiceCheckItemLoader ||
          listActivityChecklistLoader ||
          createActivityChecklistLoader ||
          createActivityProductionLoader ||
          addActivityProductionFileLoader ||
          getActivityLayoutHistoryLoader ||
          getActivityProductionHistoryLoader ||
          uploadFilesLoader
        }
      />
      <Grid gutter="xs" columns={5} w="82vw">
        <Grid.Col span={1}>
          <Flex align="center" justify="start">
            <Button
              onClick={() => rootNavigateGoBack()}
              color="dark.1"
              variant="subtle"
              w={40}
              p={0}
            >
              <IconArrowLeft />
            </Button>
          </Flex>
        </Grid.Col>
        <Grid.Col span={3}>
          <Flex align="center" justify="center">
            <Badge
              color={activity?.status && activityStatusColor[activity.status]}
            >
              {activity?.status && activityStatusHumanized[activity.status]}
            </Badge>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid gutter="xs" columns={6} w="82vw" style={{ position: 'relative' }}>
        <Grid.Col span={3}>
          <Paper p={8} mb={16} withBorder>
            <Grid m={8} gutter="xs" columns={2}>
              <Grid.Col mt={8} mb={8} span={2}>
                <Text color="gray">Info da atividade</Text>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex direction="column">
                  <Text fw="bold">Cliente</Text>
                  <Text color="gray">{order?.company.name ?? '--'}</Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Complexidade</Text>
                  <Badge
                    w={150}
                    color={
                      activity?.complexity &&
                      activityComplexityColor[activity?.complexity]
                    }
                  >
                    {activity?.complexity &&
                      activityComplexityHumanized[activity?.complexity]}
                  </Badge>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Prioridade</Text>
                  <Text color="gray">
                    {activity?.isPrioritary ? `sim` : `não`}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Criado por</Text>
                  <Text color="gray">
                    {activity?.createdBy && activity.createdBy}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={1}>
                <Flex direction="column">
                  <Text fw="bold">Criado em</Text>
                  <Text color="gray">
                    {activity?.createdAt &&
                      formatLocale(activity.createdAt, 'DD/MM/YY')}
                  </Text>
                </Flex>
              </Grid.Col>
              <Grid.Col span={2}>
                <Flex direction="column">
                  <Text fw="bold">Observações</Text>
                  <Text color="gray">{activity?.obs ?? '--'}</Text>
                </Flex>
              </Grid.Col>
            </Grid>
          </Paper>
          <Paper p={8} withBorder>
            {order && (
              <OrderDetails
                orderId={order.id}
                sections={['basic', 'order-files', 'service-details']}
              />
            )}
          </Paper>
        </Grid.Col>
        <Grid.Col span={3}>
          <Paper
            style={{ overflowY: 'scroll' }}
            pos="fixed"
            right="1vw"
            w={510}
            mah="75vh"
            p={16}
            bg="gray.3"
            shadow="md"
          >
            <Text fw="bold">Controle de Andamento</Text>
            <form
              ref={productionForm}
              onSubmit={formProduction.onSubmit((values) =>
                handleSubmit(values),
              )}
            >
              {isAllowedToProduction && (
                <>
                  <Select
                    w={170}
                    disabled={isOnProgress}
                    required
                    withAsterisk
                    name="type"
                    label="Etapa"
                    placeholder="selecione a etapa"
                    data={
                      listStepsOptionsData?.map((option) => ({
                        label: option.name,
                        value: option.value,
                      })) ?? []
                    }
                    mb={16}
                    mr={8}
                    {...formProduction.getInputProps('type')}
                  />
                  {formProduction.values.type === 'finish' && (
                    <>
                      <Select
                        w={190}
                        required
                        withAsterisk
                        name="complexity"
                        label="Complexidade"
                        placeholder="avalie a complexidade"
                        data={[
                          {
                            label: 'Baixa',
                            value: 'low',
                          },
                          {
                            label: 'Alta',
                            value: 'high',
                          },
                        ]}
                        mb={16}
                        mr={8}
                        {...formProduction.getInputProps('complexity')}
                      />
                      <Button type="submit" disabled={disableSaveActivity}>
                        Salvar
                      </Button>
                    </>
                  )}
                  {formProduction.values.type !== 'finish' && (
                    <>
                      {formProduction.values.type === 'pause' && (
                        <Textarea
                          maxLength={255}
                          disabled={isOnProgress}
                          w="100%"
                          h={110}
                          label="Motivo"
                          placeholder="descreva o mótivo da pausa"
                          name="obs"
                          required
                          withAsterisk
                          value={formProduction.values.description}
                          {...formProduction.getInputProps('description')}
                        />
                      )}
                      <Flex align="center">
                        {lastProduction?.clockOut === null ? (
                          <Title mr={24} color="dark.3">
                            {days > 0 && `${days} ${days > 1 ? 'dias' : 'dia'}`}
                            {` ${hours}:${minutes}:${seconds}`}
                          </Title>
                        ) : (
                          <Title mr={24} color="dark.3">
                            00:00:00
                          </Title>
                        )}
                        <Button
                          onClick={() => handleStartProduction()}
                          disabled={isOnProgress}
                          p={0}
                          w={36}
                          h={36}
                          type="button"
                          mb={-8}
                          radius={18}
                          mr={8}
                        >
                          <IconPlayerPlay color="white" size={26} />
                        </Button>
                        <Button
                          onClick={() => handleStopProduction()}
                          disabled={!isOnProgress}
                          color="red"
                          p={0}
                          w={36}
                          h={36}
                          type="button"
                          mb={-8}
                          radius={18}
                        >
                          <IconPlayerStop color="white" size={26} />
                        </Button>
                      </Flex>
                    </>
                  )}
                </>
              )}
            </form>
            {lastLayout && (
              <ActivityLayoutItem
                activityLayout={lastLayout}
                files={lastLayout.files}
              />
            )}
            {user?.role === UserRole.FINAL_ART && renderSendLayoutButton && (
              <Button
                mb={16}
                mt={16}
                color="dark"
                type="button"
                onClick={() => setPageModalVisible(true)}
              >
                Enviar Layout
              </Button>
            )}
            <Divider m={8} />
            <ArtProductionList productionList={productionList} />
            {/* <ActivityChecklist
              checkListItems={activityChecklist}
              serviceCheckItemData={
                listActivityChecklistData?.length
                  ? listActivityChecklistData.map((item) => ({
                      id: item.serviceCheckItem,
                      text: item.text,
                      createdAt: new Date(),
                      service: 1,
                    }))
                  : getServiceCheckItemData ?? []
              }
              activityChecklistData={listActivityChecklistData ?? []}
              onCheckboxChange={(checkItem) => {
                const list = activityChecklist ?? {};
                if (list[checkItem.id] && list[checkItem.id].value) {
                  list[checkItem.id].value = false;
                } else {
                  list[checkItem.id] = {
                    serviceCheckItem: checkItem.id,
                    value: true,
                  };
                }
                setActivityChecklist({ ...list });
              }}
              handleCreateActivityChecklist={handleCreateActivityChecklist}
            />
            {lastActivityProduction && (
              <ActivityProductionItem
                activityProduction={
                  lastActivityProduction.layout as unknown as ActivityProduction
                }
                files={
                  lastActivityProduction.layout
                    .files as unknown as ActivityProductionFile[]
                }
              />
            )}
            <form
              onSubmit={formProductionFile.onSubmit((values) =>
                handleSubmitAddActivityProduction(values),
              )}
            >
              <Card withBorder mt={16}>
                <CustomMultipleFileInput
                  disabled={!listActivityChecklistData?.length}
                  required
                  withAsterisk
                  name="files"
                  accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
                  label="Arquivos para gravação"
                  placeholder="selecione arquivo por vez"
                  formRef={formProductionFile}
                  mb={'xs'}
                />

                <Group position="right">
                  <Button
                    disabled={!listActivityChecklistData?.length}
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Group>
              </Card>
            </form> */}
            <Accordion
              mt={16}
              variant="default"
              style={{
                backgroundColor: '#ffffff',
                borderRadius: '5px',
              }}
              transitionDuration={200}
            >
              <Accordion.Item value="photos">
                <Accordion.Control
                  icon={<IconHistory size={30} color="orange" />}
                >
                  Histórico de Layouts
                </Accordion.Control>
                <Accordion.Panel>
                  {getActivityLayoutHistoryData?.map((item) => (
                    <Card
                      mt={16}
                      maw={700}
                      withBorder
                      key={item.activity.id}
                      style={{
                        backgroundColor: '#dddddd50',
                      }}
                    >
                      <Flex justify="space-between">
                        <Text fw="bold">Atividade</Text>
                        <Badge
                          maw={160}
                          color={activityStatusColor[item.activity.status]}
                        >
                          {activityStatusHumanized[item.activity.status]}
                        </Badge>
                      </Flex>
                      <Flex justify="space-between">
                        <Text>
                          {formatLocale(
                            item.activity.createdAt,
                            'DD/MM/YY HH:mm',
                          )}
                        </Text>
                      </Flex>
                      <ActivityLayoutHistoryItem
                        activityLayouts={item.activity.layouts}
                      />
                    </Card>
                  ))}
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Paper>
        </Grid.Col>
      </Grid>
      <Modal
        opened={pageModalVisible}
        onClose={() => setPageModalVisible(false)}
        title="Enviar Layout"
      >
        <form
          onSubmit={formOrderLayout.onSubmit((values) =>
            handleSubmitOrderLayout(values),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <CustomMultipleFileInput
                required
                withAsterisk
                name="files"
                accept="application/pdf,image/png,image/jpeg,image/jpg,.eps,.ai,.cdr,.svg,.txt,.ps,.bin"
                label="Arquivos"
                placeholder="selecione arquivo por vez"
                formRef={formOrderLayout}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Enviar
            </Button>
          </Flex>
        </form>
      </Modal>
    </Page>
  );
}
