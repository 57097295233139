import {
  Badge,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  NumberInput,
  Paper,
  Select,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconBuilding,
  IconCalendar,
  IconCheck,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconEye,
  IconPrinter,
  IconSelect,
  IconTrash,
  IconWriting,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { QRCodeCanvas } from 'qrcode.react';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { FormOIEType, PageModalType } from '..';

import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { MaterialStatus, OrderMaterial } from '../../../models/order-material';
import { configVars } from '../../../providers/config';
import {
  materialTransferStatusColor,
  materialTransferStatusHumanized,
  orderMaterialStatusColor,
  orderMaterialStatusHumanized,
} from '../../../utils/constants/order-material';
import { AppRoutePaths, PermissionAlias } from '../../../utils/enums';
import { formatDateTime } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import { GetFilterCompaniesListResponse } from '../../../utils/types/data/services/filters';
import usePermissionsContext from '../../../hooks/use-permissions-context';

interface ReceptionistOrderMaterialListProps {
  formFilter: any;
  handleFilter(): Promise<void>;
  handleClearFilter(): Promise<void>;
  companyList: GetFilterCompaniesListResponse[];
  loading: boolean;
  page: React.MutableRefObject<number>;
  pageMeta: PageMeta | undefined;
  handlePaginate(newPage: number): void;
  orderMaterialList: OrderMaterial[];
  handleSetOIE(orderMaterial: OrderMaterial): void;
  setSelectedOrderMaterial: React.Dispatch<
    React.SetStateAction<OrderMaterial | undefined>
  >;
  setPrintIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  pageModalVisible: PageModalType;
  selectedMaterial: OrderMaterial | undefined;
  formOIE: UseFormReturnType<FormOIEType, (values: FormOIEType) => FormOIEType>;
  printIsVisible: boolean;
  setPageModalVisible: React.Dispatch<React.SetStateAction<PageModalType>>;
  handleSubmiteValidate(values: FormOIEType): Promise<void>;
  selectedOrderMaterial: OrderMaterial | undefined;
  setOrderMaterialToDelete(orderMaterial: OrderMaterial): void;
  handleChangePageLimit(limit: number): void;
}

export function ReceptionistOrderMaterialList({
  companyList,
  formFilter,
  handleClearFilter,
  handleFilter,
  handlePaginate,
  handleSetOIE,
  loading,
  orderMaterialList,
  page,
  pageMeta,
  setPrintIsVisible,
  setSelectedOrderMaterial,
  formOIE,
  pageModalVisible,
  printIsVisible,
  selectedMaterial,
  handleSubmiteValidate,
  selectedOrderMaterial,
  setPageModalVisible,
  setOrderMaterialToDelete,
  handleChangePageLimit,
}: ReceptionistOrderMaterialListProps) {
  const navigation = useNavigate();
  const printerComponentRef = useRef<any>();

  const { list: listPermissions } = usePermissionsContext();

  const handlePrint = useReactToPrint({
    content: () => printerComponentRef.current,
  });

  function PrintableComponent({
    printerComponentRef,
  }: {
    printerComponentRef: React.MutableRefObject<any>;
  }) {
    if (!selectedOrderMaterial) return null;
    const elementList: React.ReactNode[] = [];

    for (
      let index = 0;
      index < selectedOrderMaterial?.quantityVolume;
      index++
    ) {
      elementList.push(
        <Center>
          <Flex direction="column">
            <Flex w={690} justify="space-between" align="center">
              <Title size={28} align="left">
                {selectedOrderMaterial?.company?.name.toUpperCase()}
              </Title>
              <Flex direction="row" justify="space-around" align="center">
                <Flex
                  mr={16}
                  direction="column"
                  justify="center"
                  align="center"
                >
                  <Title size={40}>{selectedOrderMaterial?.quantity}</Title>
                  <Text fw="bold">peças</Text>
                </Flex>
                <Flex direction="column" justify="center" align="center">
                  <Title size={40}>{`${index + 1}/${
                    selectedOrderMaterial?.quantityVolume
                  }`}</Title>
                  <Text fw="bold">volumes</Text>
                </Flex>
              </Flex>
            </Flex>
            <Flex direction="column">
              <Title align="left" size={30}>
                {selectedOrderMaterial?.code}
              </Title>
            </Flex>
            <Flex>
              <Flex h={300} mt={16} direction="column">
                <Text align="left" size={20}>
                  <IconSelect />{' '}
                  {`${selectedOrderMaterial?.product}, ${selectedOrderMaterial?.productColor}, ${selectedOrderMaterial?.productMaterial}`}
                </Text>
                <Text align="left" size={20}>
                  <IconCalendar /> Recebido em{' '}
                  {formatDateTime(selectedOrderMaterial?.receivedAt ?? '')}
                </Text>
                <Text w={530} align="left" size={20}>
                  <IconWriting /> {selectedOrderMaterial?.note}
                </Text>
              </Flex>
              <Flex>
                <QRCodeCanvas
                  value={`${configVars.host}/order-materials/details/${selectedOrderMaterial.id}`}
                />
              </Flex>
            </Flex>
          </Flex>
        </Center>,
      );
    }

    return (
      <Flex ref={printerComponentRef} direction="column">
        <style>
          {`@media print {
            @page {
              size: 920mm 660mm;
            }
          }`}
        </style>
        {elementList}
      </Flex>
    );
  }

  return (
    <>
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="dark"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.ORDER_MATERIALS_CREATE)}
          >
            Nova
          </Button>
        </Flex>
        <form onSubmit={formFilter.onSubmit(() => handleFilter())}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="Código"
                placeholder="código da AR"
                mb={16}
                mr={8}
                type="text"
                name="code"
                {...formFilter.getInputProps('code')}
              />
              <TextInput
                label="Criado por"
                placeholder="criador da AR"
                mb={16}
                mr={8}
                type="text"
                name="username"
                {...formFilter.getInputProps('username')}
              />
              <TextInput
                label="Produto"
                placeholder="nome do produto"
                mb={16}
                mr={8}
                type="text"
                name="product"
                {...formFilter.getInputProps('product')}
              />
              <TextInput
                label="Cor"
                placeholder="cor do produto"
                mb={16}
                mr={8}
                type="text"
                name="productColor"
                {...formFilter.getInputProps('productColor')}
              />
              <TextInput
                label="Material"
                placeholder="material do produto"
                mb={16}
                mr={8}
                type="text"
                name="productMaterial"
                {...formFilter.getInputProps('productMaterial')}
              />
              <NumberInput
                label="Quantidade"
                placeholder="Qtde"
                w={100}
                min={0}
                mb={16}
                mr={8}
                type="number"
                name="quantity"
                {...formFilter.getInputProps('quantity')}
              />
              <NumberInput
                label="Volumes"
                placeholder="Qtde"
                w={100}
                min={0}
                mb={16}
                mr={8}
                type="number"
                name="quantityVolume"
                {...formFilter.getInputProps('quantityVolume')}
              />
              <Select
                searchable
                name="company"
                label="Clientes"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('company')}
              />
              <CustomDatePicker
                placeholder="selecione uma data"
                label="Data de recebimento"
                lang="br"
                name="receivedAt"
                mb={16}
                mr={8}
                {...formFilter.getInputProps('receivedAt')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClearFilter}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
              <Checkbox
                mt={40}
                label="Mostrar associados"
                {...formFilter.getInputProps('showAssociates')}
              />
            </Group>
          </Paper>
        </form>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 300, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          fetching={loading}
          minHeight={500}
          noRecordsText="Sem Ar's"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={page.current}
          totalRecords={pageMeta?.totalItems}
          recordsPerPage={pageMeta?.itemsPerPage ?? 10}
          onPageChange={handlePaginate}
          records={orderMaterialList}
          columns={[
            {
              accessor: 'code',
              title: 'Código',
            },
            {
              accessor: 'username',
              title: 'Criado por',
            },
            {
              accessor: '',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Quantidade',
            },
            {
              accessor: 'quantityVolume',
              title: 'Volumes',
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
            },
            {
              accessor: 'receivedAt',
              title: 'Recebido em',
              render: ({ receivedAt }) => formatDateTime(receivedAt ?? ''),
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderMaterialStatusColor[status]}>
                  {orderMaterialStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'transferStatus',
              title: 'Transferência',
              render: ({ transferStatus }) =>
                transferStatus && (
                  <Badge color={materialTransferStatusColor[transferStatus]}>
                    {materialTransferStatusHumanized[transferStatus]}
                  </Badge>
                ),
            },
            {
              accessor: 'menu',
              title: '',
              render: (orderMaterialItem) =>
                listPermissions.some((p) =>
                  [PermissionAlias.HANDLE_MATERIAL_VOLUME_TRANSFER].includes(p),
                ) ? (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        onClick={() => {
                          setPageModalVisible('order-material-view');
                          setSelectedOrderMaterial(orderMaterialItem);
                        }}
                        icon={<IconEye size={14} />}
                      >
                        Ver
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ) : (
                  <Menu>
                    <Menu.Target>
                      <Button color="blue" variant="subtle" w={40} p={0}>
                        <IconDotsVertical />
                      </Button>
                    </Menu.Target>
                    <Menu.Dropdown style={{ position: 'absolute' }}>
                      <Menu.Item
                        onClick={() => {
                          setPageModalVisible('order-material-view');
                          setSelectedOrderMaterial(orderMaterialItem);
                        }}
                        icon={<IconEye size={14} />}
                      >
                        Ver
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setPageModalVisible('order-company-associate');
                          setSelectedOrderMaterial(orderMaterialItem);
                        }}
                        icon={<IconBuilding size={14} />}
                      >
                        Alterar cliente
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          navigation(`edit/${orderMaterialItem.id}`)
                        }
                        icon={<IconEdit size={14} />}
                      >
                        Editar
                      </Menu.Item>
                      <Menu.Item
                        disabled={
                          orderMaterialItem.status === MaterialStatus.APPROVED
                        }
                        onClick={() => handleSetOIE(orderMaterialItem)}
                        icon={<IconCheck size={14} />}
                      >
                        OIE
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          setSelectedOrderMaterial(orderMaterialItem);
                          setPrintIsVisible(true);
                        }}
                        icon={<IconPrinter size={14} />}
                      >
                        Imprimir Etiqueta
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          setOrderMaterialToDelete(orderMaterialItem)
                        }
                        icon={<IconTrash size={14} />}
                      >
                        Excluir
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ),
            },
          ]}
        />
      </Flex>
      <Modal
        opened={pageModalVisible === 'oie'}
        onClose={() => setPageModalVisible(null)}
        title="OIE"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col span={2}>
            <Text fw="bold">Código</Text>
            <Text>{selectedMaterial?.code}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold">Produto</Text>
            <Text>{`${selectedMaterial?.product}, ${selectedMaterial?.productColor}, ${selectedMaterial?.productMaterial}`}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold">Cliente</Text>
            <Text>{selectedMaterial?.company?.name}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold">Quantidade</Text>
            <Text>{selectedMaterial?.quantity}</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Text fw="bold">Volumes</Text>
            <Text>{selectedMaterial?.quantityVolume}</Text>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={formOIE.onSubmit((values) => handleSubmiteValidate(values))}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={1}>
              <Textarea
                maxLength={255}
                required
                withAsterisk
                mb={16}
                label="Descrição do produto"
                placeholder="ex: tudo certo"
                name="productCondition"
                {...formOIE.getInputProps('productCondition')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Textarea
                maxLength={255}
                required
                withAsterisk
                mb={16}
                label="Descrição da embalagem"
                placeholder="ex: 3 caixas danificadas"
                name="packageCondition"
                {...formOIE.getInputProps('packageCondition')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                withAsterisk
                name="status"
                label="Status"
                placeholder="selecione o status"
                data={[
                  {
                    label: 'Aprovado',
                    value: 'approved',
                  },
                  {
                    label: 'Reprovado',
                    value: 'reproved',
                  },
                ]}
                mb={16}
                {...formOIE.getInputProps('status')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                mb={16}
                label="Observações"
                placeholder="escreva aqui qualquer observação que queira fazer"
                name="note"
                {...formOIE.getInputProps('note')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Associar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size="70vw"
        opened={printIsVisible}
        onClose={() => setPrintIsVisible(false)}
      >
        <Group position="right">
          <Button
            color="ltpBlue.9"
            leftIcon={<IconPrinter />}
            onClick={() => handlePrint()}
          >
            Imprimir
          </Button>
        </Group>
        <PrintableComponent printerComponentRef={printerComponentRef} />
      </Modal>
    </>
  );
}
