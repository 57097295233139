import { Button, Flex, Group, Menu } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconCirclePlus, IconDotsVertical } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useNavigate } from 'react-router-dom';
import { FormFilterType, OrderMaterialEntriesPageModalVisible } from '..';
import { ProductEntryResponse } from '../../../models/product-entry';
import { tagTargetHumanized } from '../../../utils/constants';
import { formatDateTime } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import { DynamicTextInput } from '../sub-components/dynamic-text-input';
import ProductEntryListPageFilter from '../sub-components/page-filter';
import { TagMenu } from '../sub-components/tag-menu';
import { useLocalStorage } from '../../../hooks/use-local-storage';
import { AppRoutePaths, LocalStorageKeys } from '../../../utils/enums';

interface ReceptionistOrderMaterialEntriesProps {
  handleSubmitEditValue(entrieId: number, values: any): void;
  items?: PaginatedResponse<ProductEntryResponse>;
  setPageModalVisible: React.Dispatch<
    React.SetStateAction<OrderMaterialEntriesPageModalVisible>
  >;
  openModalCreateTag: (type: string) => void;
  loading: boolean;
  currentPage: React.MutableRefObject<number>;
  currentPageLimit: React.MutableRefObject<number>;
  handlePaginated(pageNumber: number): void;
  handleChangePageLimit(pageLimitNumber: number): void;
  formFilter: UseFormReturnType<any>;
  filtersCollapse: boolean;
  handleClear: () => void;
  handleSubmit(values: FormFilterType): void;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ReceptionistOrderMaterialEntries({
  handleSubmitEditValue,
  items,
  setPageModalVisible,
  openModalCreateTag,
  loading,
  currentPage,
  currentPageLimit,
  handlePaginated,
  handleChangePageLimit,
  formFilter,
  filtersCollapse,
  handleClear,
  handleSubmit,
  setFiltersCollapse,
}: ReceptionistOrderMaterialEntriesProps) {
  const navigation = useNavigate();
  const { setItem } = useLocalStorage();

  function sendProductEntryPayloadToCreateAR(
    productEntry: ProductEntryResponse,
  ) {
    setItem(
      LocalStorageKeys.PRODUCT_ENTRY_PAYLOAD,
      JSON.stringify(productEntry),
    );
    navigation(AppRoutePaths.ORDER_MATERIALS_CREATE);
  }

  return (
    <Flex direction="column" w="99%">
      <Group mb={16} position="right">
        <ProductEntryListPageFilter.Button
          isCollapsed={filtersCollapse}
          onClick={() => setFiltersCollapse(!filtersCollapse)}
        />

        <Button
          color="ltpBlue.9"
          leftIcon={<IconCirclePlus />}
          onClick={() => setPageModalVisible('create-entrie')}
        >
          Novo
        </Button>
      </Group>
      <ProductEntryListPageFilter
        formFilter={formFilter}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
        isCollapsed={filtersCollapse}
      />
      <DataTable
        height="75dvh"
        withBorder
        withColumnBorders
        loaderVariant="oval"
        loaderBackgroundBlur={2}
        records={items?.items}
        fetching={loading}
        totalRecords={items?.meta.totalItems}
        recordsPerPage={currentPageLimit.current}
        onPageChange={handlePaginated}
        page={currentPage.current}
        recordsPerPageOptions={[10, 50, 100, 200]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        noRecordsText="Sem entrada de produtos"
        columns={[
          {
            accessor: 'code',
            title: 'Cod.',
          },
          {
            accessor: 'client',
            title: 'Nome do Cliente',
            width: '220px',
            cellsStyle: (record) => (record ? { padding: '2px' } : {}),
            render: (record) => (
              <div
                style={{
                  cursor: 'pointer',
                }}
              >
                <DynamicTextInput
                  entrieId={record.id}
                  name="client"
                  value={record.client}
                  handleSubmitEditValue={handleSubmitEditValue}
                  canEdit
                />
              </div>
            ),
          },
          {
            accessor: 'team',
            title: 'Equipe',
            width: '120px',
            render: (record) => (
              <TagMenu
                entrieId={record.id}
                target={'team'}
                title={tagTargetHumanized['team']}
                item={{
                  color: record.team.color,
                  name: record.team.name,
                }}
                openModalCreateTag={openModalCreateTag}
                canEdit
              />
            ),
          },
          {
            accessor: 'quantity',
            title: 'Quantidade',
            width: '100px',
            cellsStyle: (record) => (record ? { padding: '2px' } : {}),
            render: (record) => (
              <DynamicTextInput
                entrieId={record.id}
                name="quantity"
                value={record.quantity}
                handleSubmitEditValue={handleSubmitEditValue}
                canEdit
              />
            ),
          },
          {
            accessor: 'product',
            title: 'Material',
            width: '220px',
            cellsStyle: (record) => (record ? { padding: '2px' } : {}),
            render: (record) => (
              <DynamicTextInput
                entrieId={record.id}
                name="product"
                value={record.product}
                handleSubmitEditValue={handleSubmitEditValue}
                canEdit
              />
            ),
          },
          {
            accessor: 'quantityVolume',
            title: 'Nº Volumes',
            width: '100px',
            cellsStyle: (record) => (record ? { padding: '2px' } : {}),
            render: (record) => (
              <DynamicTextInput
                entrieId={record.id}
                name="quantityVolume"
                value={record.quantityVolume}
                handleSubmitEditValue={handleSubmitEditValue}
                canEdit
              />
            ),
          },
          {
            accessor: 'volume',
            width: '120px',
            render: (record) => (
              <TagMenu
                entrieId={record.id}
                target={'volumeName'}
                title={tagTargetHumanized['volumeName']}
                item={{
                  color: record.volume.color,
                  name: record.volume.name,
                }}
                openModalCreateTag={openModalCreateTag}
                canEdit
              />
            ),
          },
          {
            accessor: 'createdAt',
            title: 'Data de Criação',
            width: '135px',
            render: (record) => formatDateTime(record.createdAt),
          },
          {
            accessor: 'deliveredBy',
            title: 'Responsável',
            width: '145px',
            cellsStyle: (record) => (record ? { padding: '2px' } : {}),
            render: (record) => (
              <DynamicTextInput
                entrieId={record.id}
                name="deliveredBy"
                value={record.deliveredBy}
                handleSubmitEditValue={handleSubmitEditValue}
                canEdit
              />
            ),
          },
          {
            accessor: 'document',
            title: 'Documento',
            width: '145px',
            cellsStyle: (record) => (record ? { padding: '2px' } : {}),
            render: (record) => (
              <DynamicTextInput
                entrieId={record.id}
                name="document"
                value={record.document}
                handleSubmitEditValue={handleSubmitEditValue}
                canEdit
              />
            ),
          },
          {
            accessor: 'type',
            title: 'Tipo',
            width: '120px',
            render: (record) => (
              <TagMenu
                entrieId={record.id}
                target={'type'}
                title={tagTargetHumanized['type']}
                item={{
                  color: record.type.color,
                  name: record.type.name,
                }}
                openModalCreateTag={openModalCreateTag}
                canEdit
              />
            ),
          },
          {
            accessor: 'receivedBy',
            title: 'Quem recebeu',
            width: '120px',
            render: (record) => (
              <TagMenu
                entrieId={record.id}
                target={'receivedBy'}
                title={tagTargetHumanized['receivedBy']}
                item={{
                  color: record.receivedBy.color,
                  name: record.receivedBy.name,
                }}
                openModalCreateTag={openModalCreateTag}
                canEdit
              />
            ),
          },
          {
            accessor: 'status',
            title: 'Andamento',
            width: '120px',
            render: (record) => (
              <TagMenu
                entrieId={record.id}
                target={'status'}
                title={tagTargetHumanized['status']}
                item={{
                  color: record.status.color,
                  name: record.status.name,
                }}
                openModalCreateTag={openModalCreateTag}
                canEdit
              />
            ),
          },
          {
            accessor: 'menu',
            title: '',
            render: (orderEntryItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() =>
                      sendProductEntryPayloadToCreateAR(orderEntryItem)
                    }
                    icon={<IconCirclePlus size={14} />}
                  >
                    Criar AR
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
