import {
  MaterialStatus,
  MaterialTransferStatus,
} from '../../models/order-material';

export const orderMaterialStatusHumanized: Record<MaterialStatus, string> = {
  approved: 'aprovado',
  associated: 'associado',
  initial: 'pendente',
  reproved: 'reprovado',
};

export const orderMaterialStatusColor: Record<MaterialStatus, string> = {
  approved: 'green',
  associated: 'blue',
  initial: 'orange',
  reproved: 'red',
};

export const materialTransferStatusHumanized: Record<
  MaterialTransferStatus,
  string
> = {
  waiting: 'em espera',
  in_transfer: 'em transferência',
  transfered: 'transferido',
};

export const materialTransferStatusColor: Record<
  MaterialTransferStatus,
  string
> = {
  waiting: 'orange',
  in_transfer: 'blue',
  transfered: 'green',
};

export const materialTransferStatusList = [
  { label: 'em espera', value: MaterialTransferStatus.WAITING },
  { label: 'em transferência', value: MaterialTransferStatus.IN_TRANSFER },
  { label: 'transferido', value: MaterialTransferStatus.TRANSFERED },
];
