import {
  Accordion,
  Alert,
  Badge,
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  Select,
  Text,
  Textarea,
  Timeline,
} from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';

import { useForm } from '@mantine/form';
import {
  IconAlertCircle,
  IconCheck,
  IconDotsVertical,
  IconGitBranch,
  IconHistory,
  IconTrash,
  IconTruckLoading,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import { FilePreview } from '../../components/FilePreview';
import { Page } from '../../components/Page';
import {
  useCreateOrderMaterialVolume,
  useFinishOrderMaterialVolumeTransfer,
  useGetOrderMaterialFiles,
  useGetOrderMaterialsVolumes,
  useShowOrderMaterials,
  useTransferOrderMaterialVolume,
} from '../../data/hooks/order-materials';
import { getFilterUsersListRequest } from '../../data/services/filters';
import { useRenderByPermission } from '../../hooks/use-render-by-permission';
import { OrderMaterial } from '../../models/order-material';
import { OrderMaterialVolume } from '../../models/order-material-volume';
import { UserRole } from '../../models/user';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { formatDateTime, transformNumberInArray } from '../../utils/helpers';
import { GetFilterUsersListResponse } from '../../utils/types/data/services/filters';
import { AddOrderMaterialVolume } from '../../utils/types/data/services/order-material';
import { PermissionAlias } from '../../utils/enums';
import { useListOptions } from '../../data/hooks/options';
import { OptionSubTypes, OptionTypes } from '../../models/option';

interface OrderMaterialDetailsProps {
  orderMaterialIdProps?: number;
}

type PageModalState =
  | 'add-transfer'
  | 'relocate-tranfer'
  | 'warning-transfer-advice'
  | 'finish-tranfer'
  | null;

interface RelocateTransferVolumeButtonProps {
  handleSubmit: () => void;
  materialVolume: OrderMaterialVolume;
  currentQuantity: number;
  loader: boolean;
}

interface CreateTransferVolumeButtonProps {
  handleSubmit: () => void;
  orderMaterial: OrderMaterial;
  currentQuantity: number;
  loader: boolean;
}

function RelocateTransferVolumeButton({
  handleSubmit,
  materialVolume,
  currentQuantity,
  loader,
}: RelocateTransferVolumeButtonProps) {
  return (
    <Group position="right">
      {currentQuantity > 0 && currentQuantity < materialVolume.quantity && (
        <Text color="red" size={'sm'} fw={'bold'}>
          Quantidade total de volumes para remanejamento não registrada*
        </Text>
      )}

      {currentQuantity === materialVolume.quantity && (
        <Button onClick={() => handleSubmit()} loading={loader}>
          Salvar
        </Button>
      )}
    </Group>
  );
}

function CreateTransferVolumeButton({
  handleSubmit,
  orderMaterial,
  currentQuantity,
  loader,
}: CreateTransferVolumeButtonProps) {
  return (
    <Group position="right">
      {currentQuantity > 0 &&
        currentQuantity < orderMaterial.quantityVolume && (
          <Text color="red" size={'sm'} fw={'bold'}>
            Quantidade total de volumes não registrada*
          </Text>
        )}

      {currentQuantity === orderMaterial.quantityVolume && (
        <Button onClick={() => handleSubmit()} loading={loader}>
          Salvar
        </Button>
      )}
    </Group>
  );
}

export function OrderMaterialDetails({
  orderMaterialIdProps,
}: OrderMaterialDetailsProps) {
  const { id } = useParams();
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalState>(null);
  const [orderMaterial, setOrderMaterial] = useState<OrderMaterial>();
  const [selectedTransferVolume, setSelectedTransferVolume] =
    useState<OrderMaterialVolume>();
  const [transferVolumes, setTransferVolumes] = useState<OrderMaterialVolume[]>(
    [],
  );
  const [relocateTransferVolumes, setRelocateTransferVolumes] = useState<
    AddOrderMaterialVolume[]
  >([]);
  const [createTransferVolumes, setCreateTransferVolumes] = useState<
    AddOrderMaterialVolume[]
  >([]);
  const [filterUserList, setFilterUserList] = useState<
    GetFilterUsersListResponse[]
  >([]);
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const transferVolumeForm = useForm({
    initialValues: {
      transferUser: '',
      quantity: 0,
      origin: '',
      destiny: '',
      note: '',
      createdAt: new Date(),
    },
  });

  const { renderByPermission } = useRenderByPermission();

  const materialId = orderMaterialIdProps
    ? Number(orderMaterialIdProps)
    : Number(id);

  const {
    fetch: getOrderMaterialFilesFetcher,
    loading: getOrderMaterialFilesLoader,
    response: getOrderMaterialFilesData,
  } = useGetOrderMaterialFiles();

  const {
    fetch: showOrderMaterialsFetcher,
    loading: showOrderMaterialsLoader,
  } = useShowOrderMaterials();

  const {
    fetch: getOrderMaterialVolumesFetcher,
    loading: getOrderMaterialVolumesLoader,
  } = useGetOrderMaterialsVolumes();

  const {
    fetch: createOrderMaterialVolumeFetcher,
    loading: createOrderMaterialVolumeLoader,
  } = useCreateOrderMaterialVolume();

  const {
    fetch: transferOrderMaterialVolumeFetcher,
    loading: transferOrderMaterialVolumeLoader,
  } = useTransferOrderMaterialVolume();

  const {
    fetch: finishOrderMaterialVolumeTransferFetcher,
    loading: finishOrderMaterialVolumeTransferLoader,
  } = useFinishOrderMaterialVolumeTransfer();

  const {
    fetch: listPaymentOptionsFetcher,
    reponseData: listPaymentOptionsData,
    loading: listPaymentOptionsLoader,
  } = useListOptions();

  async function getOrderMaterial() {
    showOrderMaterialsFetcher({
      id: materialId,
      onSuccess: (res) => {
        setOrderMaterial(res);
      },
    });
  }

  async function getOrderMaterialVolumes() {
    getOrderMaterialVolumesFetcher({
      id: materialId,
      onSuccess: (res) => {
        setTransferVolumes(res);
      },
    });
  }

  async function getFiles(id: number) {
    await getOrderMaterialFilesFetcher({
      id,
    });
  }

  async function handleSubmitCreateVolume() {
    createOrderMaterialVolumeFetcher({
      id: materialId,
      data: {
        items: createTransferVolumes,
      },
      onSuccess: () => {
        getOrderMaterialVolumes();
        setCreateTransferVolumes([]);
        handleCloseModal();
        successNotification({
          title: 'Transferência realizada!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  async function handleSubmitRelocateVolume() {
    transferOrderMaterialVolumeFetcher({
      id: selectedTransferVolume?.id ?? 0,
      data: {
        items: relocateTransferVolumes,
      },
      onSuccess: () => {
        getOrderMaterialVolumes();
        setRelocateTransferVolumes([]);
        handleCloseModal();
        successNotification({
          title: 'Remanejamento realizado!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  function handleSelectTransferVolume(transferVolume: AddOrderMaterialVolume) {
    setSelectedTransferVolume(transferVolume);
  }

  const currentCreateTranferVolumeQuantity = useMemo(() => {
    return handleSumTransferVolumeQuantity(createTransferVolumes);
  }, [createTransferVolumes]);

  const currentRelocateVolumeQuantity = useMemo(() => {
    return handleSumTransferVolumeQuantity(relocateTransferVolumes);
  }, [relocateTransferVolumes]);

  function addTranferVolumes(values: typeof transferVolumeForm.values) {
    if (!validateAddTransferVolume(values)) {
      if (
        orderMaterial &&
        currentCreateTranferVolumeQuantity >= orderMaterial.quantityVolume
      ) {
        errorNotification({
          title: 'Limite excedido!',
          message: 'Quantidade de volumes excede o limite',
        });
        return;
      }
      handleAddOfflineItems(transferVolumes, values, setTransferVolumes);

      handleAddOfflineItems(
        createTransferVolumes,
        values,
        setCreateTransferVolumes,
      );
      handleCloseModal();
    }
  }

  function handleRelocateTransferVolume(
    values: typeof transferVolumeForm.values,
  ) {
    if (!validateAddTransferVolume(values)) {
      if (
        orderMaterial &&
        currentRelocateVolumeQuantity >= orderMaterial.quantityVolume
      ) {
        errorNotification({
          title: 'Limite excedido!',
          message: 'Quantidade de volumes excede o limite',
        });
        return;
      }

      if (selectedTransferVolume) {
        selectedTransferVolume.isActive = false;
      }

      handleAddOfflineItems(transferVolumes, values, setTransferVolumes);

      handleAddOfflineItems(
        relocateTransferVolumes,
        values,
        setRelocateTransferVolumes,
      );

      const updatedRelocateVolumeQuantity =
        currentRelocateVolumeQuantity + values.quantity;

      if (
        orderMaterial &&
        updatedRelocateVolumeQuantity < orderMaterial.quantityVolume &&
        updatedRelocateVolumeQuantity < (selectedTransferVolume?.quantity ?? 0)
      ) {
        transferVolumeForm.reset();
        setPageModalVisible('warning-transfer-advice');
        return;
      }

      handleCloseModal();
    }
  }

  function handleAddOfflineItems(
    list: OrderMaterialVolume[],
    values: typeof transferVolumeForm.values,
    setListState: React.Dispatch<React.SetStateAction<OrderMaterialVolume[]>>,
  ) {
    const cloneList = list;
    const foundIndex = cloneList.findIndex(
      (item) =>
        item.destiny === values.destiny &&
        item.transferUser === values.transferUser &&
        item.isActive === null,
    );

    if (foundIndex !== -1) {
      cloneList[foundIndex] = {
        transferUser: cloneList[foundIndex].transferUser,
        destiny: cloneList[foundIndex].destiny,
        origin: cloneList[foundIndex].origin,
        note: cloneList[foundIndex].note,
        quantity: values.quantity + cloneList[foundIndex].quantity,
        isActive: cloneList[foundIndex].isActive,
        createdAt: cloneList[foundIndex].createdAt,
      };
    } else {
      cloneList.push({
        ...values,
        id: undefined,
        orderMaterialId: undefined,
        transferUser: values.transferUser,
        destiny: values.destiny,
        origin: values.origin,
        note: values.note,
        quantity: values.quantity,
        isActive: null,
        createdAt: values.createdAt,
      });
    }
    setListState([...cloneList]);
  }

  function validateAddTransferVolume(values: typeof transferVolumeForm.values) {
    if (!orderMaterial) return;
    if (!values.transferUser) {
      errorNotification({
        title: 'Transferente inválido!',
        message: 'Selecione um transferente válido',
      });
      return true;
    }
    if (values.quantity <= 0) {
      errorNotification({
        title: 'Quantidade de volumes inválida!',
        message: 'Selecione uma quantidade de volumes válida para o material',
      });
      return true;
    }
    if (!values.origin) {
      errorNotification({
        title: 'Origem inválida!',
        message: 'Selecione uma ORIGEM para o material válida',
      });
      return true;
    }
    if (!values.destiny) {
      errorNotification({
        title: 'Destino inválido!',
        message: 'Selecione um DESTINO para o material válido',
      });
      return true;
    }
    if (values.origin === values.destiny) {
      errorNotification({
        title: 'Origem e destino não podem ser iguais!',
        message: 'Seleciona origem e destino diferentes para o material',
      });
      return true;
    }
    if (selectedTransferVolume?.destiny === values.destiny) {
      errorNotification({
        title: 'O Destino não pode ser igual ao atual!',
        message: 'Selecione um destino diferente para o material',
      });
      return true;
    }
    return false;
  }

  function handleRemoveTransferVolume(transferVolume: AddOrderMaterialVolume) {
    handleRemoveOfflineItems(
      createTransferVolumes,
      transferVolume,
      setCreateTransferVolumes,
    );
    handleRemoveOfflineItems(
      relocateTransferVolumes,
      transferVolume,
      setRelocateTransferVolumes,
    );
    handleRemoveOfflineItems(
      transferVolumes,
      transferVolume,
      setTransferVolumes,
    );
  }

  function handleRemoveOfflineItems(
    list: AddOrderMaterialVolume[],
    transferVolume: AddOrderMaterialVolume,
    setListState: React.Dispatch<
      React.SetStateAction<AddOrderMaterialVolume[]>
    >,
  ) {
    const cloneList = list;
    const foundIndex = cloneList.findIndex(
      (item) =>
        item.destiny === transferVolume.destiny &&
        item.transferUser === transferVolume.transferUser,
    );

    if (foundIndex !== -1) {
      cloneList.splice(foundIndex, 1);
    }

    setListState([...cloneList]);
  }

  function handleSumTransferVolumeQuantity(volumes: AddOrderMaterialVolume[]) {
    return volumes.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.quantity),
      0.0,
    );
  }

  function handleCloseModal() {
    setPageModalVisible(null);
    transferVolumeForm.reset();
  }

  function handleFinishTransferVolume() {
    if (!selectedTransferVolume) return;
    finishOrderMaterialVolumeTransferFetcher({
      id: selectedTransferVolume.id,
      onSuccess: () => {
        getOrderMaterialVolumes();
        handleCloseModal();
        successNotification({
          title: 'Transferência finalizada!',
          message: 'tudo certo ✅',
        });
      },
    });
  }

  async function getReceptionistUser() {
    try {
      setPageLoading(true);
      const response = await getFilterUsersListRequest({
        role: UserRole.RECEPTIONIST,
      });
      setFilterUserList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function getPaymentOptions() {
    await listPaymentOptionsFetcher({
      query: {
        type: OptionTypes.ORDER_MATERIAL_VOLUME,
        subtype: OptionSubTypes.ORDER_MATERIAL_VOLUME_LOCATION,
      },
    });
  }

  useEffect(() => {
    getOrderMaterial();
    getFiles(materialId);
    getOrderMaterialVolumes();
    getReceptionistUser();
    getPaymentOptions();
  }, [materialId]);

  if (!orderMaterial) return null;

  return (
    <Page>
      <CustomLoader
        loading={
          pageLoading ||
          getOrderMaterialFilesLoader ||
          showOrderMaterialsLoader ||
          listPaymentOptionsLoader
        }
      />
      <Container>
        <Grid m={8} gutter="xl" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text size="lg" fw="bold">
              Info do material
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Código</Text>
              <Text color="gray">{orderMaterial?.code}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Cliente</Text>
              {orderMaterial?.company ? (
                <Text color="gray">{orderMaterial?.company.name}</Text>
              ) : (
                '--'
              )}
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Produto</Text>
              <Text color="gray">{orderMaterial?.product}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Material</Text>
              <Text color="gray">{orderMaterial?.productMaterial}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Cor</Text>
              <Text color="gray">{orderMaterial?.productColor}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Quantidade</Text>
              <Text color="gray">{orderMaterial?.quantity}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Volumes</Text>
              <Text color="gray">{orderMaterial?.quantityVolume}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Possui NF</Text>
              <Text color="gray">{orderMaterial?.hasNf ? 'Sim' : 'Não'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Número da NF</Text>
              <Text color="gray">{orderMaterial?.nfNumber}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Recebido em</Text>
              <Text color="gray">
                {formatDateTime(orderMaterial?.receivedAt ?? '')}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Descrição da Embalagem</Text>
              <Text color="gray">{orderMaterial?.packageCondition}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Descrição do Produto</Text>
              <Text color="gray">{orderMaterial?.productCondition}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Observação</Text>
              <Text color="gray">{orderMaterial?.note}</Text>
            </Flex>
          </Grid.Col>

          <Grid.Col span={2}>
            <Flex wrap="wrap">
              {getOrderMaterialFilesData?.map((item) => (
                <FilePreview
                  key={item.file}
                  file={{
                    id: item.file,
                    url: item.fileUrl,
                    type: '',
                    ext: item.fileName.split('.')[1],
                    name: item.fileName.split('.')[0],
                  }}
                />
              ))}
            </Flex>
          </Grid.Col>

          <Grid.Col span={2}>
            <Flex gap={'xs'} direction={'column'}>
              <Flex justify={'space-between'} align={'center'}>
                <Text fw="bold">Transferencias</Text>
                {renderByPermission(
                  [PermissionAlias.HANDLE_MATERIAL_VOLUME_TRANSFER],
                  () => (
                    <Button
                      onClick={() => setPageModalVisible('add-transfer')}
                      disabled={
                        handleSumTransferVolumeQuantity(transferVolumes) >=
                        orderMaterial.quantityVolume
                      }
                    >
                      Novo
                    </Button>
                  ),
                )}
              </Flex>

              <DataTable
                withBorder
                fetching={getOrderMaterialVolumesLoader}
                minHeight={200}
                noRecordsText="Sem tranferências"
                records={
                  transferVolumes.sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime(),
                  ) || []
                }
                fontSize={'sm'}
                rowStyle={(record) =>
                  record.isActive === false
                    ? { backgroundColor: 'rgba(255, 17, 0, 0.151)' }
                    : {}
                }
                columns={[
                  { accessor: 'transferUser', title: 'Transferente' },
                  { accessor: 'quantity', title: 'Volumes' },
                  { accessor: 'origin', title: 'Origem' },
                  { accessor: 'destiny', title: 'Destino' },
                  { accessor: 'note', title: 'Descrição' },
                  {
                    accessor: 'createdAt',
                    title: 'Criado em',
                    render: ({ createdAt }) => formatDateTime(createdAt),
                  },
                  {
                    accessor: 'transferedAt',
                    title: 'Transferido em',
                    render: ({ transferedAt }) =>
                      transferedAt && formatDateTime(transferedAt),
                  },
                  {
                    accessor: '',
                    title: '',
                    render: (volumeItem) =>
                      renderByPermission(
                        [PermissionAlias.HANDLE_MATERIAL_VOLUME_TRANSFER],
                        () => (
                          <Menu>
                            <Menu.Target>
                              <Button
                                color="blue"
                                variant="subtle"
                                w={40}
                                p={0}
                              >
                                <IconDotsVertical />
                              </Button>
                            </Menu.Target>
                            <Menu.Dropdown style={{ position: 'absolute' }}>
                              {volumeItem.isActive === null && (
                                <Menu.Item
                                  icon={<IconTrash size={14} />}
                                  onClick={() =>
                                    handleRemoveTransferVolume(volumeItem)
                                  }
                                >
                                  Remover
                                </Menu.Item>
                              )}

                              {volumeItem.isActive !== null && (
                                <Menu.Item
                                  icon={<IconTruckLoading size={14} />}
                                  onClick={() => {
                                    handleSelectTransferVolume(volumeItem);
                                    setPageModalVisible('relocate-tranfer');
                                  }}
                                  disabled={
                                    volumeItem.isActive === false ||
                                    currentRelocateVolumeQuantity >=
                                      orderMaterial.quantityVolume ||
                                    (selectedTransferVolume &&
                                      volumeItem.id !==
                                        selectedTransferVolume?.id)
                                  }
                                >
                                  Remanejar
                                </Menu.Item>
                              )}

                              {volumeItem.isActive && (
                                <Menu.Item
                                  icon={<IconCheck size={14} />}
                                  onClick={() => {
                                    handleSelectTransferVolume(volumeItem);
                                    setPageModalVisible('finish-tranfer');
                                  }}
                                  disabled={
                                    !volumeItem.isActive ||
                                    !!volumeItem.transferedAt
                                  }
                                >
                                  Finalizar
                                </Menu.Item>
                              )}
                            </Menu.Dropdown>
                          </Menu>
                        ),
                      ),
                  },
                ]}
              />

              {renderByPermission(
                [PermissionAlias.HANDLE_MATERIAL_VOLUME_TRANSFER],
                () =>
                  CreateTransferVolumeButton({
                    handleSubmit: handleSubmitCreateVolume,
                    orderMaterial,
                    currentQuantity: currentCreateTranferVolumeQuantity,
                    loader: createOrderMaterialVolumeLoader,
                  }),
              )}
              {renderByPermission(
                [PermissionAlias.HANDLE_MATERIAL_VOLUME_TRANSFER],
                () =>
                  selectedTransferVolume &&
                  RelocateTransferVolumeButton({
                    handleSubmit: handleSubmitRelocateVolume,
                    materialVolume: selectedTransferVolume,
                    currentQuantity: currentRelocateVolumeQuantity,
                    loader: transferOrderMaterialVolumeLoader,
                  }),
              )}
            </Flex>
          </Grid.Col>

          <Grid.Col span={2}>
            <Accordion variant="contained" mt={16}>
              <Accordion.Item value="photos">
                <Accordion.Control
                  icon={<IconHistory size={30} color="orange" />}
                >
                  Histórico
                </Accordion.Control>
                <Accordion.Panel>
                  <Timeline color="green" bulletSize={24} lineWidth={2}>
                    <Timeline.Item
                      bullet={<IconGitBranch size={12} />}
                      title={<Badge mb={6}>Entrega</Badge>}
                    >
                      <Text color="dimmed" size="sm">
                        Entregue por
                      </Text>
                      <Text size="xs" mt={4}>
                        {orderMaterial?.arrivedBy ?? '--'}
                      </Text>
                    </Timeline.Item>
                    <Timeline.Item bullet={<IconGitBranch size={12} />}>
                      <Text color="dimmed" size="sm">
                        Cliente encontrado por
                      </Text>
                      <Text size="xs" mt={4}>
                        {orderMaterial?.associatedBy ?? '--'} no dia{' '}
                        {orderMaterial?.associatedAt
                          ? formatDateTime(orderMaterial?.associatedAt)
                          : '--'}
                      </Text>
                    </Timeline.Item>
                  </Timeline>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          </Grid.Col>
        </Grid>
      </Container>

      <Modal
        title="Remanejar volume"
        opened={pageModalVisible === 'relocate-tranfer'}
        onClose={() => handleCloseModal()}
        closeOnClickOutside={false}
        size={500}
      >
        <Flex justify={'space-evenly'}>
          <Flex direction={'column'}>
            <Text fw="bold">Transferente</Text>
            <Text color="dimmed">{selectedTransferVolume?.transferUser}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fw="bold">Volumes</Text>
            <Text color="dimmed">{selectedTransferVolume?.quantity}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fw="bold">Origem</Text>
            <Text color="dimmed">{selectedTransferVolume?.origin}</Text>
          </Flex>
          <Flex direction={'column'}>
            <Text fw="bold">Destino</Text>
            <Text color="dimmed">{selectedTransferVolume?.destiny}</Text>
          </Flex>
        </Flex>

        <form
          onSubmit={transferVolumeForm.onSubmit((values) =>
            handleRelocateTransferVolume(values),
          )}
        >
          <Grid m={8} gutter="xl" columns={2}>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Transferente</Text>
                <Select
                  required
                  withAsterisk
                  data={filterUserList.map((item) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  {...transferVolumeForm.getInputProps('transferUser')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Volumes</Text>
                <Select
                  required
                  withAsterisk
                  data={
                    transformNumberInArray(
                      (selectedTransferVolume?.quantity ?? 0) -
                        currentRelocateVolumeQuantity,
                    )?.map((item) => ({
                      label: item,
                      value: item,
                    })) ?? []
                  }
                  {...transferVolumeForm.getInputProps('quantity')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Origem</Text>
                <Select
                  required
                  withAsterisk
                  data={
                    listPaymentOptionsData?.map((option) => ({
                      value: option.name,
                      label: option.value,
                    })) || []
                  }
                  {...transferVolumeForm.getInputProps('origin')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Destino</Text>
                <Select
                  required
                  withAsterisk
                  data={
                    listPaymentOptionsData?.map((option) => ({
                      value: option.name,
                      label: option.value,
                    })) || []
                  }
                  {...transferVolumeForm.getInputProps('destiny')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex direction="column">
                <Text fw="bold">Descrição</Text>
                <Textarea {...transferVolumeForm.getInputProps('note')} />
              </Flex>
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button type="submit">Novo</Button>
          </Group>
        </form>
      </Modal>
      <Modal
        title="Nova tranferência"
        opened={pageModalVisible === 'add-transfer'}
        onClose={() => handleCloseModal()}
        closeOnClickOutside={false}
        size={500}
      >
        <form
          onSubmit={transferVolumeForm.onSubmit((values) =>
            addTranferVolumes(values),
          )}
        >
          <Grid m={8} gutter="xl" columns={2}>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Transferente</Text>
                <Select
                  required
                  withAsterisk
                  data={filterUserList.map((item) => ({
                    value: item.name,
                    label: item.name,
                  }))}
                  {...transferVolumeForm.getInputProps('transferUser')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Volumes</Text>
                <Select
                  required
                  withAsterisk
                  data={
                    transformNumberInArray(
                      orderMaterial.quantityVolume -
                        currentCreateTranferVolumeQuantity,
                    )?.map((item) => ({
                      label: item,
                      value: item,
                    })) ?? []
                  }
                  {...transferVolumeForm.getInputProps('quantity')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Origem</Text>
                <Select
                  required
                  withAsterisk
                  data={
                    listPaymentOptionsData?.map((option) => ({
                      value: option.name,
                      label: option.value,
                    })) || []
                  }
                  {...transferVolumeForm.getInputProps('origin')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={1}>
              <Flex direction="column">
                <Text fw="bold">Destino</Text>
                <Select
                  required
                  withAsterisk
                  data={
                    listPaymentOptionsData?.map((option) => ({
                      value: option.name,
                      label: option.value,
                    })) || []
                  }
                  {...transferVolumeForm.getInputProps('destiny')}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex direction="column">
                <Text fw="bold">Descrição</Text>
                <Textarea {...transferVolumeForm.getInputProps('note')} />
              </Flex>
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button type="submit">Adicionar</Button>
          </Group>
        </form>
      </Modal>
      <Modal
        title=""
        opened={pageModalVisible === 'warning-transfer-advice'}
        onClose={() => handleCloseModal()}
        size={500}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
      >
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Atenção"
          color="red"
          mb={16}
        >
          Você precisa completar a quantidade de volumes necessária para
          prosseguir. Por favor, verifique e ajuste os volumes antes de
          continuar.
        </Alert>
        <Group position="right">
          <Button onClick={() => setPageModalVisible('relocate-tranfer')}>
            Fechar
          </Button>
        </Group>
      </Modal>
      <Modal
        title=""
        opened={pageModalVisible === 'finish-tranfer'}
        onClose={() => handleCloseModal()}
        size={500}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
      >
        <Text fw={'bold'} mb={16}>
          Deseja finalizar a transferência do volume?
        </Text>
        <Group position="right">
          <Button onClick={() => setPageModalVisible(null)} color="gray">
            Não
          </Button>
          <Button
            onClick={() => handleFinishTransferVolume()}
            loading={finishOrderMaterialVolumeTransferLoader}
            color="blue"
          >
            Sim
          </Button>
        </Group>
      </Modal>
    </Page>
  );
}
