import { Button, Card, Checkbox, Group, Text } from '@mantine/core';
import { ServiceCheckItem } from '../../models/service-check-item';
import { ListActivityChecklistResponse } from '../../utils/types/data/services/activity-checklist';

export type ActivityChecklistProps = {
  serviceCheckItemData: ServiceCheckItem[];
  activityChecklistData: ListActivityChecklistResponse[];
  handleCreateActivityChecklist?: () => void;
  onCheckboxChange?: (item: ServiceCheckItem) => void;
  checkListItems: Record<string, Record<string, number | string | boolean>>;
  readonly?: boolean;
};

export type ChecklistCheckboxProps = {
  checkItem: ServiceCheckItem;
  activityChecklistData: ListActivityChecklistResponse[];
  onCheckboxChange?: (item: ServiceCheckItem) => void;
  checkListItems: Record<string, Record<string, number | string | boolean>>;
  readonly?: boolean;
};

function ChecklistCheckbox({
  checkItem,
  activityChecklistData,
  onCheckboxChange,
  checkListItems,
  readonly,
}: ChecklistCheckboxProps) {
  return (
    <Checkbox
      disabled={!!activityChecklistData?.length}
      mt={8}
      label={checkItem.text}
      color="green"
      name="hasManipulation"
      mb={16}
      onChange={() => onCheckboxChange && onCheckboxChange(checkItem)}
      checked={!!checkListItems?.[checkItem.id]?.value || readonly}
    />
  );
}

export function ActivityChecklist({
  serviceCheckItemData,
  handleCreateActivityChecklist,
  activityChecklistData,
  checkListItems,
  onCheckboxChange,
  readonly,
}: ActivityChecklistProps) {
  return (
    <Card withBorder mb={8} mt={8}>
      <Text fw="bolder">Revise o arquivo antes de enviar para a produção</Text>
      {serviceCheckItemData?.map((checkItem) => (
        <ChecklistCheckbox
          key={checkItem.id}
          checkItem={checkItem}
          checkListItems={checkListItems}
          activityChecklistData={activityChecklistData}
          onCheckboxChange={onCheckboxChange}
          readonly={readonly}
        />
      ))}
      <Group position="right">
        <Button
          disabled={!!activityChecklistData?.length || readonly}
          color="dark"
          type="button"
          onClick={handleCreateActivityChecklist}
        >
          Salvar
        </Button>
      </Group>
    </Card>
  );
}
