import { AxiosResponse } from 'axios';
import {
  ActivityLayout,
  ActivityLayoutByOrder,
  ActivityLayoutHistoryResponse,
} from '../../models/activity-layout';
import { ActivityLayoutFile } from '../../models/activity-layout-file';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  ActivityLayoutClientApproverRequestData,
  ActivityLayoutsToApproveResponse,
  ActivityLayoutVendorApproverRequestData,
  AddActivityLayoutFilesRequestData,
  GetActivityLayoutFileRequestQuery,
  GetActivityLayoutToApproveRequestQuery,
} from '../../utils/types/data/services/activity-layouts';

export async function createActivityLayoutRequest(
  activityId: number,
): Promise<AxiosResponse<ActivityLayout>> {
  const response = await api.post<ActivityLayout>(
    `activity-layouts/${activityId}`,
  );

  return response;
}

export async function vendorApproveActivityLayoutRequest(
  id: number,
  data: ActivityLayoutVendorApproverRequestData,
): Promise<AxiosResponse<ActivityLayout>> {
  const response = await api.put<ActivityLayout>(
    `activity-layouts/vendor-approve/${id}`,
    data,
  );

  return response;
}

export async function requestClientApprovalActivityLayoutRequest(
  id: number,
): Promise<AxiosResponse<ActivityLayout>> {
  const response = await api.put<ActivityLayout>(
    `activity-layouts/request-client/${id}`,
  );

  return response;
}

export async function clientApproveActivityLayoutRequest(
  id: number,
  data: ActivityLayoutClientApproverRequestData,
): Promise<AxiosResponse<ActivityLayout>> {
  const response = await api.put<ActivityLayout>(
    `activity-layouts/client-approve/${id}`,
    data,
  );

  return response;
}

export async function getActivityLayoutsRequest(
  activityId: number,
): Promise<AxiosResponse<ActivityLayout[]>> {
  const response = await api.get<ActivityLayout[]>(
    `activity-layouts/${activityId}`,
  );

  return response;
}

export async function addActivityLayoutFilesRequest(
  id: number,
  data: AddActivityLayoutFilesRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`activity-layouts/files/${id}`, data);

  return response;
}

export async function getActivityLayoutFilesRequest(
  query: GetActivityLayoutFileRequestQuery,
): Promise<AxiosResponse<ActivityLayoutFile[]>> {
  const response = await api.get<ActivityLayoutFile[]>(
    `activity-layouts/files`,
    { params: query },
  );

  return response;
}

export async function getActivityLayoutToApproveRequest(
  query: GetActivityLayoutToApproveRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<ActivityLayoutsToApproveResponse>>> {
  const response = await api.get<
    PaginatedResponse<ActivityLayoutsToApproveResponse>
  >(`activity-layouts/to-approve`, { params: query });

  return response;
}

export async function getActivityLayoutsByOrderRequest(
  orderId: number,
): Promise<AxiosResponse<ActivityLayoutByOrder>> {
  const response = await api.get<ActivityLayoutByOrder>(
    `activity-layouts/order/${orderId}`,
  );

  return response;
}

export async function getActivityLayoutsFilesByOrderHistoryRequest(
  layoutId: number,
): Promise<AxiosResponse<ActivityLayoutFile[]>> {
  const response = await api.get<ActivityLayoutFile[]>(
    `activity-layouts/files/${layoutId}/layout`,
  );

  return response;
}

export async function getActivityLayoutsHistoryRequest(
  orderId: number,
): Promise<AxiosResponse<ActivityLayoutHistoryResponse[]>> {
  const response = await api.get<ActivityLayoutHistoryResponse[]>(
    `activity-layouts/historic/${orderId}`,
  );
  return response;
}

export async function getActivityPausedRequest(
  query: GetActivityLayoutToApproveRequestQuery,
): Promise<AxiosResponse<PaginatedResponse<ActivityLayoutsToApproveResponse>>> {
  const response = await api.get<
    PaginatedResponse<ActivityLayoutsToApproveResponse>
  >(`activity-layouts/activities/paused`, { params: query });

  return response;
}
