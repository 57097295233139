import { AxiosResponse } from 'axios';

import { api } from '../../providers/base-api';
import { PermissionAlias } from '../../utils/enums';

export async function getPermissionsByUserRequest(): Promise<
  AxiosResponse<PermissionAlias[]>
> {
  const response = await api.get<PermissionAlias[]>(`team-permissions`);

  return response;
}
