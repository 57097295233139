/* eslint-disable unused-imports/no-unused-vars */
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '@mantine/form';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../components/Page';
import { CorteLaserFormItem } from './corte-laser';
import { DecalqueFormItem } from './decalque';
import { EnvelopamentoFormItem } from './envelopamento';
import { EtiquetaResinadaFormItem } from './etiqueta-resinada';
import { GravacaoLaserFormItem } from './gravacao-laser';
import { HotStampingFormItem } from './hot-stamping';
import { SilkscreenFormItem } from './silkscreen';
import { SublimacaoFormItem } from './sublimacao';
import { TampoFormItem } from './tampo';
import { TransferFormItem } from './transfer';
import { DigitalUVFormItem } from './digital-uv';
import { TransferCilindricoFormItem } from './transfer-cilindrico';
import { createOrderDispatcher, ordersActions } from '../../store/orders';
import { RootState } from '../../providers/store';
import { getServicesDispatcher } from '../../store/services';
import { AdesivoFormItem } from './adesivo';
import { UserRole } from '../../models/user';
import { CompanyCreateOrderMobile } from './company';
import { VendorCreateOrderMobile } from './vendor';
import { CustomLoader } from '../../components/CustomLoader';
import { errorNotification } from '../../providers/mantine-notifications';
import { realToUSCash } from '../../utils/helpers';
import { useGetUserCompanies } from '../../data/hooks/companies';
import { DTFUVFormItem } from './dtf-uv';
import { DigitalCilindricoFormItem } from './digital-cilindrico';
import { useUploadMultipleFiles } from '../../data/hooks/files';

type CreateOrderFormType = {
  company: string;
  internalNumber: string;
  service: string;
  product: string;
  productColor: string;
  productMaterial: string;
  engravingText: string;
  quantity: string;
  quantityVolume?: string;
  carrierName: string;
  defectAmount?: string;
  fileLink: string;
  hasDefect?: boolean;
  needsPickUp?: boolean;
  note: string;
  price: string;
  samplePrice: string;
  clichePrice: string;
  pholitoPrice: string;
  manipulationPrice: string;
  vectorizationPrice: string;
  pickUpLocation: string;
  pickUpName: string;
  pickUpNumber: string;
  pickUpForecast: Date;
  dispatchForecast: Date;
  finishForecast: Date;
  finishVendorForecast: Date;
  receiptForecast: Date;
  orderAccess: string;
  needsPostal: string;
  files: File[];
  fileReceipts: File[];
  supplier: string;
  productCodeSupplier: string;
  paymentType: string;
  isSample?: boolean;
  needSample?: boolean;
  clichePriceSelector: string;
  changeColorPrice?: string;
  details: {
    colorsAmount?: string;
    personalizationArea: string;
    personalizationSides: string[];
    sidesAmount?: string;
    thickness?: string;
    width?: string;
    height?: string;
    has360Engraving?: boolean;
    hasCliche?: boolean;
    hasCut?: boolean;
    hasDepilate?: boolean;
    hasManipulation: boolean;
    hasManyData?: boolean;
    hasPhotolito?: boolean;
    hasResin?: boolean;
    hasSample: boolean;
    hasVarnish?: boolean;
    isRotated?: boolean;
    isSandblasted?: boolean;
    isTransparent?: boolean;
    needWhiteColor?: boolean;
    needChangeColor?: boolean;
    isChromia?: boolean;
  };
};

export function CreateOrder() {
  const navigation = useNavigate();
  const dispatch = useDispatch<any>();
  const [selectedService, setSelectedService] = useState('');
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.orders);
  const { loading: servicesLoading, items: servicesItems } = useSelector(
    (state: RootState) => state.services,
  );
  const {
    fetch: getUserCompaniesFetcher,
    loading: getUserCompaniesLoading,
    response: getUserCompaniesData,
  } = useGetUserCompanies();
  const { fetch: uploadFilesFetcher, loading: uploadFilesLoader } =
    useUploadMultipleFiles();

  const form = useForm<CreateOrderFormType>({
    initialValues: {
      company: '',
      internalNumber: '',
      service: '',
      product: '',
      productColor: '',
      productMaterial: '',
      engravingText: '',
      quantity: '',
      quantityVolume: undefined,
      carrierName: '',
      defectAmount: undefined,
      fileLink: '',
      hasDefect: undefined,
      needsPickUp: undefined,
      note: '',
      price: '',
      samplePrice: '',
      clichePrice: '',
      pholitoPrice: '',
      manipulationPrice: '',
      vectorizationPrice: '',
      pickUpLocation: '',
      pickUpName: '',
      pickUpNumber: '',
      pickUpForecast: new Date(),
      dispatchForecast: new Date(),
      finishForecast: new Date(),
      finishVendorForecast: new Date(),
      receiptForecast: new Date(),
      orderAccess: 'delivered',
      needsPostal: 'none',
      files: [],
      supplier: '',
      productCodeSupplier: '',
      fileReceipts: [],
      paymentType: '',
      isSample: false,
      needSample: false,
      clichePriceSelector: '',
      changeColorPrice: '',
      details: {
        colorsAmount: undefined,
        personalizationArea: '',
        personalizationSides: [],
        sidesAmount: undefined,
        thickness: undefined,
        width: undefined,
        height: undefined,
        has360Engraving: undefined,
        hasCliche: undefined,
        hasCut: undefined,
        hasDepilate: undefined,
        hasManipulation: false,
        hasManyData: undefined,
        hasPhotolito: undefined,
        hasResin: undefined,
        hasSample: false,
        hasVarnish: undefined,
        isRotated: undefined,
        isSandblasted: undefined,
        isTransparent: undefined,
        needWhiteColor: undefined,
        needChangeColor: undefined,
        isChromia: undefined,
      },
    },
  });

  const servicerFormRender: any = {
    adesivo: () => <AdesivoFormItem form={form} />,
    corte_a_laser: () => <CorteLaserFormItem form={form} />,
    decalque: () => <DecalqueFormItem form={form} />,
    digital_uv: () => <DigitalUVFormItem form={form} />,
    envelopamento: () => <EnvelopamentoFormItem form={form} />,
    etiqueta_resinada: () => <EtiquetaResinadaFormItem form={form} />,
    gravacao_a_laser: () => <GravacaoLaserFormItem form={form} />,
    hot_stamping: () => <HotStampingFormItem form={form} />,
    silkscreen: () => <SilkscreenFormItem form={form} />,
    sublimacao: () => <SublimacaoFormItem form={form} />,
    tampografia: () => <TampoFormItem form={form} />,
    transfer_dtf: () => <TransferFormItem form={form} />,
    transfer_cilindrico: () => <TransferCilindricoFormItem form={form} />,
    dtf_uv: () => <DTFUVFormItem form={form} />,
    digital_cilindrico: () => <DigitalCilindricoFormItem form={form} />,
  };

  const services = useMemo(() => {
    const items = servicesItems.map((item) => ({
      label: item.name,
      value: String(item.id),
      disabled: item.alias === 'envelopamento',
    }));

    return items;
  }, [servicesItems]);

  function handleSelectService(id: string) {
    form.setValues({ service: id });
    setSelectedService(
      servicesItems.find((item) => item.id === Number(id))?.alias || '',
    );
  }

  async function handleSubmit(values: typeof form.values) {
    const { details, files, fileReceipts, ...restValues } = values;
    let fileIds: number[] = [];
    let fileReceiptIds: number[] = [];

    if (user?.role === UserRole.VENDOR && restValues.needsPostal === 'none') {
      errorNotification({
        title: 'Preencha o campo de correio.',
        message: 'campo de correio obrigatório',
      });

      return;
    }

    if (
      user?.role === UserRole.VENDOR &&
      restValues.service === '7' &&
      restValues.clichePriceSelector === ''
    ) {
      errorNotification({
        title: 'Preencha o campo de clichê.',
        message: 'campo obrigatório',
      });

      return;
    }

    if (
      user?.role === UserRole.VENDOR &&
      details.hasManipulation &&
      restValues.manipulationPrice === ''
    ) {
      errorNotification({
        title: 'Preencha o campo de Preço Manuseio.',
        message: 'campo obrigatório',
      });

      return;
    }

    if (files.length) {
      try {
        dispatch(ordersActions.initLoading());
        fileIds = await handleUpdateFiles(files);
        dispatch(ordersActions.endLoading());
      } catch (error) {
        dispatch(ordersActions.endLoading());
      }
    }

    if (fileReceipts.length) {
      try {
        dispatch(ordersActions.initLoading());
        fileReceiptIds = await handleUpdateFiles(fileReceipts);
        dispatch(ordersActions.endLoading());
      } catch (error) {
        dispatch(ordersActions.endLoading());
      }
    }

    dispatch(
      createOrderDispatcher({
        ...restValues,
        defectAmount: Number(restValues.defectAmount),
        quantity: Number(restValues.quantity),
        quantityVolume: Number(restValues.quantityVolume),
        service: { id: Number(restValues.service) },
        company: { id: Number(values.company) },
        os: '--',
        needsPickUp: restValues.orderAccess === 'collected',
        needsPostal: restValues.needsPostal === 'true' ? true : false,
        hasDefect: false,
        files: fileIds.map((fileId) => ({ id: fileId })),
        fileReceipts: fileReceiptIds.map((fileId) => ({ id: fileId })),
        price: restValues.price ? realToUSCash(restValues.price) : undefined,
        samplePrice: restValues.samplePrice
          ? realToUSCash(restValues.samplePrice)
          : undefined,
        pholitoPrice: restValues.pholitoPrice
          ? realToUSCash(restValues.pholitoPrice)
          : undefined,
        vectorizationPrice: restValues.vectorizationPrice
          ? realToUSCash(restValues.vectorizationPrice)
          : undefined,
        clichePrice: restValues.clichePrice
          ? realToUSCash(restValues.clichePrice)
          : undefined,
        manipulationPrice: restValues.manipulationPrice
          ? realToUSCash(restValues.manipulationPrice)
          : undefined,
        changeColorPrice: restValues.changeColorPrice
          ? realToUSCash(restValues.changeColorPrice)
          : undefined,
        changeColorTotalPrice: restValues.changeColorPrice
          ? (
              Number(realToUSCash(restValues.changeColorPrice)) *
              Number(details.colorsAmount)
            ).toFixed(2)
          : undefined,
        orderDetails: {
          ...details,
          personalizationSides:
            details.personalizationSides &&
            details.personalizationSides.join(','),
          height: Number(details.height),
          colorsAmount: Number(details.colorsAmount),
          sidesAmount: Number(details.sidesAmount),
          width: Number(details.width),
          thickness: Number(details.thickness),
        },
      }),
    );
  }

  async function handleUpdateFiles(files: File[]): Promise<number[]> {
    let fileIds: number[] = [];
    await uploadFilesFetcher({
      data: {
        files: files,
      },
      onSuccess: (res) => {
        fileIds = res;
      },
    });

    return fileIds;
  }

  async function getUserCompanies() {
    await getUserCompaniesFetcher({
      userId: Number(user?.id),
    });
  }

  useEffect(() => {
    dispatch(getServicesDispatcher());

    if (user?.role === UserRole.COMPANY) {
      getUserCompanies();
    }
  }, []);

  return (
    <Page>
      <CustomLoader
        loading={
          servicesLoading ||
          getUserCompaniesLoading ||
          loading ||
          uploadFilesLoader
        }
      />
      {user?.role === UserRole.COMPANY && (
        <CompanyCreateOrderMobile
          form={form}
          handleSelectService={handleSelectService}
          handleSubmit={handleSubmit}
          navigation={navigation}
          selectedService={selectedService}
          servicerFormRender={servicerFormRender}
          services={services}
          getUserCompaniesData={getUserCompaniesData}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorCreateOrderMobile
          form={form}
          handleSelectService={handleSelectService}
          handleSubmit={handleSubmit}
          navigation={navigation}
          selectedService={selectedService}
          servicerFormRender={servicerFormRender}
          services={services}
          setSelectedService={setSelectedService}
        />
      )}
    </Page>
  );
}
