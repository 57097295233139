import { ActivityProductionStatus } from '../../models/activity-production';

export const activityProductionStatusHumanized = {
  [ActivityProductionStatus.APPROVED]: 'aprovado',
  [ActivityProductionStatus.REPROVED]: 'reprovado',
  [ActivityProductionStatus.TECHNICAL_APPROVE]: 'aguard. técnico',
};

export const activityProductionStatusColor = {
  [ActivityProductionStatus.APPROVED]: 'green',
  [ActivityProductionStatus.REPROVED]: 'red',
  [ActivityProductionStatus.TECHNICAL_APPROVE]: 'orange',
};

export const activityProductionStatusList = [
  { label: 'aprovado', value: ActivityProductionStatus.APPROVED },
  {
    label: 'aguard. técnico',
    value: ActivityProductionStatus.TECHNICAL_APPROVE,
  },
  { label: 'reprovado', value: ActivityProductionStatus.REPROVED },
];
