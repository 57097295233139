import {
  Badge,
  Button,
  Flex,
  Grid,
  Group,
  Indicator,
  Menu,
  Modal,
  MultiSelect,
  Select,
  Switch,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import {
  IconCheck,
  IconCirclePlus,
  IconCopy,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconEye,
  IconFile,
  IconRefreshDot,
  IconStatusChange,
  IconTableExport,
  IconTestPipe2,
  IconWriting,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction } from 'react-router-dom';
import { FormFilterType, OrderListPageModalVisible } from '..';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';

import { createActivityRequest } from '../../../data/services/activities';
import {
  getFilterCompaniesListRequest,
  getFilterServicesListRequest,
  getFilterUsersListRequest,
} from '../../../data/services/filters';
import {
  FinancialStatus,
  Order,
  Order as OrderModel,
  OrderStatus,
} from '../../../models/order';
import { LayoutStatus } from '../../../models/order-layout';
import { TeamConfigAlias } from '../../../models/team-config';
import { UserRole } from '../../../models/user';
import { dayjsPlugins, formatLocale } from '../../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../../providers/mantine-notifications';
import { RootState } from '../../../providers/store';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusList,
} from '../../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderFinancialStatusList,
  orderPaymentTypeHumanized,
  orderPaymentTypeList,
  orderSortList,
} from '../../../utils/constants/order';
import { AppRoutePaths } from '../../../utils/enums';
import { formatBRL, getOrderPrices } from '../../../utils/helpers';
import { PageMeta } from '../../../utils/types';
import OrderListPageFilter from '../sub-components/page-filter';
import {
  GetFilterCompaniesListResponse,
  GetFilterServicesListResponse,
  GetFilterUsersListResponse,
} from '../../../utils/types/data/services/filters';
import { setOrderTableRowBackgroundColor } from '../functions/set-values';
import { renderOrderTableRowIcon } from '../functions/render-items';

interface VendorHomeWebProps {
  items: OrderModel[];
  navigation: NavigateFunction;
  handlePaginate(page: number): void;
  handleChangePageLimit(limit: number): void;
  handleSubmit(values: FormFilterType): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  filtersCollapse: boolean;
  handleClear: () => void;
  handleRequestLayout(id: number): Promise<void>;
  setChangeStatusModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedOrder: React.Dispatch<React.SetStateAction<Order | undefined>>;
  setOrderFilesModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderViewModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setChangeDateModalVisible: React.Dispatch<
    React.SetStateAction<
      'receipt' | 'dispatch' | 'receipt-vendor' | 'dispatch-vendor' | null
    >
  >;
  setFiltersCollapse: React.Dispatch<React.SetStateAction<boolean>>;
  getOrderMaterial(companyId: string): Promise<void>;
  setPageModalVisible: React.Dispatch<
    React.SetStateAction<OrderListPageModalVisible>
  >;
  requestOrderListExportable(): void;
  onInvoiceOrderListExport?: () => void;
  requestSample: (order: Order) => void;
  getAssociatedOrderMaterial(): void;
  setOrderToClone: (order: Order) => void;
}

export function VendorHomeWeb({
  items,
  meta,
  handlePaginate,
  handleChangePageLimit,
  currentPage,
  navigation,
  formFilter,
  handleSubmit,
  handleClear,
  setChangeStatusModalVisible,
  setSelectedOrder,
  setOrderFilesModalVisible,
  setOrderViewModalVisible,
  getOrderMaterial,
  setPageModalVisible,
  requestOrderListExportable,
  filtersCollapse,
  setFiltersCollapse,
  onInvoiceOrderListExport,
  requestSample,
  getAssociatedOrderMaterial,
  setOrderToClone,
}: VendorHomeWebProps) {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [filterUserList, setFilterUserList] = useState<
    GetFilterUsersListResponse[]
  >([]);
  const [serviceList, setServiceList] = useState<
    GetFilterServicesListResponse[]
  >([]);
  const [pageModal, setPageModal] = useState(false);

  const { user, userTeam } = useSelector((state: RootState) => state.auth);

  const teamConfilterFilter = useMemo(() => {
    return userTeam?.configs.find(
      (item) => item.alias === TeamConfigAlias.TEAM_FILTER,
    );
  }, []);

  const formLayoutActivity = useForm({
    initialValues: {
      orderId: '',
      target: '',
      isPrioritary: false,
      obs: '',
    },
  });

  async function getCompanies() {
    const response = await getFilterCompaniesListRequest({
      team:
        teamConfilterFilter?.value === 'false' ? undefined : Number(user?.team),
    });
    setCompanyList(response);
  }

  function handleChangeOrderStatus(order: Order) {
    setSelectedOrder(order);
    getOrderMaterial(`${order.company.id}`);
    getAssociatedOrderMaterial();
    setChangeStatusModalVisible(true);
  }

  function handleViewOrderFiles(order: Order) {
    setSelectedOrder(order);
    setOrderFilesModalVisible(true);
  }

  function handleViewOrder(order?: Order) {
    setSelectedOrder(order);
    setOrderViewModalVisible(true);
  }

  function handleSelectToRework(order: Order) {
    setSelectedOrder(order);
    setPageModalVisible('create-rework');
  }

  function requestLayout(orderId: number) {
    formLayoutActivity.setFieldValue('orderId', String(orderId));
    setPageModal(true);
  }

  async function formLayoutActivitySubmit(
    values: typeof formLayoutActivity.values,
  ) {
    try {
      await createActivityRequest(Number(values.orderId), {
        isPrioritary: values.isPrioritary,
        obs: values.obs,
        target: Number(values.target),
      });
      formLayoutActivity.reset();
      setPageModal(false);
      successNotification({
        title: 'Layout solicitado!',
        message: 'tudo certo',
      });
    } catch (error) {
      errorNotification({
        title: 'Erro ao solicitar layout.',
        message: 'tente novamente',
      });
    }
  }

  async function getFilterUser() {
    try {
      const response = await getFilterUsersListRequest({
        role: UserRole.FINAL_ART,
      });
      setFilterUserList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar usuários.',
        message: 'tente novamente',
      });
    }
  }

  async function getServices() {
    try {
      const response = await getFilterServicesListRequest();
      setServiceList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar serviços.',
        message: 'tente novamente',
      });
    }
  }

  useEffect(() => {
    getServices();
    getCompanies();
    getFilterUser();
  }, []);

  return (
    <Flex direction="column" w="99%">
      <Group mb={16} position="right">
        <OrderListPageFilter.Button
          isCollapsed={filtersCollapse}
          onClick={() => setFiltersCollapse(!filtersCollapse)}
        />
        <Menu
          disabled={formFilter.values.createdAt === undefined}
          shadow="md"
          width={200}
        >
          <Menu.Target>
            <Button
              variant="subtle"
              color="orange"
              mr={8}
              disabled={formFilter.values.createdAt === undefined}
              rightIcon={<IconTableExport size={18} />}
            >
              Exportaveis
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Tipos</Menu.Label>
            <Menu.Item
              onClick={onInvoiceOrderListExport}
              icon={<IconDownload size={14} />}
            >
              Exportavel Financeiro
            </Menu.Item>
            <Menu.Item
              onClick={requestOrderListExportable}
              icon={<IconDownload size={14} />}
            >
              Exportavel de Pedidos
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Button
          color="ltpBlue.9"
          leftIcon={<IconCirclePlus />}
          onClick={() => navigation(AppRoutePaths.CREATE_ORDER)}
        >
          Novo
        </Button>
      </Group>
      <OrderListPageFilter
        companyList={companyList}
        formFilter={formFilter}
        handleClear={handleClear}
        handleSubmit={handleSubmit}
        isCollapsed={filtersCollapse}
        serviceList={serviceList}
        showReworkFilter
        customInputs={() => (
          <>
            <TextInput
              label="Identificador"
              placeholder="código interno do pedido"
              mb={16}
              mr={8}
              type="text"
              name="internalNumber"
              {...formFilter.getInputProps('internalNumber')}
            />
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <TextInput
              label="Logo"
              placeholder="logo do produto"
              mb={16}
              mr={8}
              type="text"
              name="engravingText"
              {...formFilter.getInputProps('engravingText')}
            />
            <TextInput
              label="Produto"
              placeholder="nome do produto"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('product')}
            />
            <Select
              required
              name="paymentType"
              withAsterisk
              label="Forma de pagamento"
              placeholder="selecione uma forma de pagamento"
              data={orderPaymentTypeList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('paymentType')}
            />
            {companyList.length && (
              <MultiSelect
                clearable
                searchable
                name="company"
                label="Clientes"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: String(item.id),
                }))}
                mb={16}
                mr={8}
                defaultValue={
                  formFilter.values.company &&
                  formFilter.values.company.split(',')
                }
                onChange={(values) => {
                  formFilter.setFieldValue('company', values.toString());
                }}
              />
            )}
            <Select
              searchable
              clearable
              name="service"
              label="Serviço"
              placeholder="selecione um serviço"
              defaultValue={serviceList[formFilter.values.service]?.id}
              data={serviceList?.map((item) => ({
                label: item.name,
                value: String(item.id),
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('service')}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Data de Retirada"
              lang="br"
              name="receiptForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.receiptForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.receiptForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'receiptForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('receiptForecast', '');
              }}
            />
            <CustomDatePicker
              placeholder="selecione uma data"
              label="Prev. de Finalização"
              name="finishForecast"
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.finishForecast &&
                new Date(
                  dayjsPlugins(formFilter.values.finishForecast).toDate(),
                )
              }
              onChange={(value) => {
                value
                  ? formFilter.setFieldValue(
                      'finishForecast',
                      dayjsPlugins(value).format('YYYY-MM-DD'),
                    )
                  : formFilter.setFieldValue('finishForecast', '');
              }}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Criado em"
              placeholder="selecione um intervalo"
              mr={8}
              defaultValue={
                formFilter.values.createdAt && [
                  new Date(
                    dayjsPlugins(
                      formFilter.values.createdAt.split(',')[0],
                    ).toDate(),
                  ),
                  new Date(
                    dayjsPlugins(
                      formFilter.values.createdAt.split(',')[1],
                    ).toDate(),
                  ),
                ]
              }
              onChange={(value) => {
                value[0] && value[1]
                  ? formFilter.setFieldValue(
                      'createdAt',
                      `${dayjsPlugins(value[0]).format(
                        'YYYY-MM-DD',
                      )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('createdAt', undefined);
              }}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Faturado em"
              placeholder="selecione um intervalo"
              mr={8}
              defaultValue={
                formFilter.values.issueDate && [
                  new Date(
                    dayjsPlugins(
                      formFilter.values.issueDate.split(',')[0],
                    ).toDate(),
                  ),
                  new Date(
                    dayjsPlugins(
                      formFilter.values.issueDate.split(',')[1],
                    ).toDate(),
                  ),
                ]
              }
              onChange={(value) => {
                value[0] && value[1]
                  ? formFilter.setFieldValue(
                      'issueDate',
                      `${dayjsPlugins(value[0]).format(
                        'YYYY-MM-DD',
                      )},${dayjsPlugins(value[1]).format('YYYY-MM-DD')}`,
                    )
                  : formFilter.setFieldValue('issueDate', undefined);
              }}
            />
            <MultiSelect
              maw={240}
              clearable
              searchable
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.status && formFilter.values.status.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('status', values.toString());
              }}
            />
            <MultiSelect
              clearable
              name="financialStatus"
              label="Status Fiscal"
              placeholder="selecione o status de fiscal"
              data={orderFinancialStatusList}
              mb={16}
              mr={8}
              defaultValue={
                formFilter.values.financialStatus &&
                formFilter.values.financialStatus.split(',')
              }
              onChange={(values) => {
                formFilter.setFieldValue('financialStatus', values.toString());
              }}
            />
            <Select
              name="sortBy"
              label="Ordenar por"
              placeholder="selecione uma ordenação"
              data={orderSortList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('sortBy')}
            />
          </>
        )}
      />
      <DataTable
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 300]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        height="75vh"
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={meta?.totalItems}
        recordsPerPage={meta?.itemsPerPage ?? 10}
        onPageChange={handlePaginate}
        records={items}
        rowStyle={(record) => setOrderTableRowBackgroundColor(record)}
        columns={[
          {
            accessor: 'types',
            title: '',
            render: (order) => renderOrderTableRowIcon(order),
          },
          {
            accessor: 'company.name',
            title: 'Cliente',
          },
          {
            accessor: 'internalNumber',
            title: 'Identificador',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'status',
            title: 'Status',
            render: ({ status }) => (
              <Badge color={orderStatusColor[status]} mb={6}>
                {orderStatusHumanized[status]}
              </Badge>
            ),
          },
          {
            accessor: 'financialStatus',
            title: 'Status Fiscal',
            render: ({ financialStatus }) =>
              financialStatus && (
                <Badge color={financialStatusColor[financialStatus]} mb={6}>
                  {financialStatusTypeHumanized[financialStatus]}
                </Badge>
              ),
          },
          {
            accessor: 'issueDate',
            title: 'Faturado em',
            render: ({ issueDate }) =>
              issueDate && formatLocale(issueDate, 'DD/MM/YY HH:mm'),
          },
          {
            accessor: 'service.name',
            title: 'Serviço',
          },
          {
            accessor: 'engravingText',
            title: 'Logotipo',
          },
          {
            accessor: 'quantity',
            title: 'Qtde',
            width: 60,
          },
          {
            accessor: 'product',
            title: 'Produto',
          },
          {
            accessor: 'needsPostal',
            title: 'Correio',
            render: ({ needsPostal }) => (
              <Badge color={needsPostal ? 'green' : 'red'} mb={6}>
                {needsPostal ? 'sim' : 'não'}
              </Badge>
            ),
          },
          {
            accessor: 'receiptedAt',
            title: 'Recebido em',
            render: ({ receiptedAt }) =>
              receiptedAt && formatLocale(receiptedAt, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'finishForecast',
            title: 'Prev. Finalização',
            render: ({ finishForecast }) =>
              finishForecast && formatLocale(finishForecast, 'DD/MM/YY HH:mm'),
            width: 110,
          },
          {
            accessor: 'createdAt',
            title: 'Criado em',
            render: ({ createdAt }) =>
              createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'fileLink',
            title: 'Arquivos',
            render: (order) => (
              <Indicator
                disabled={order.layout?.status !== LayoutStatus.PENDING_VENDOR}
                inline
                color="orange"
              >
                <Button
                  color="blue"
                  variant="subtle"
                  onClick={() => handleViewOrderFiles(order)}
                  w={40}
                  p={0}
                >
                  <IconFile />
                </Button>
              </Indicator>
            ),
            width: 110,
          },
          {
            accessor: 'supplier',
            title: 'Fornecedor',
          },
          {
            accessor: 'vendor-porter',
            title: 'Portador',
            render: (orderItem) =>
              orderItem.status === OrderStatus.FINISHED
                ? orderItem.dispatchLocation
                : orderItem.carrierName,
          },
          {
            accessor: 'paymentType',
            title: 'Forma de Pagamento',
            render: ({ paymentType }) =>
              paymentType && orderPaymentTypeHumanized[paymentType],
            width: 110,
          },
          {
            accessor: 'price',
            title: 'Preço (Gravação)',
            render: ({ price }) => formatBRL(price),
          },
          {
            accessor: 'totalprice',
            title: 'Preço total',
            render: (orderItem) => `${getOrderPrices(orderItem).totalPrice}`,
            width: 110,
          },
          {
            accessor: 'vendor-porter-menu',
            title: '',
            render: (orderItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    onClick={() => handleChangeOrderStatus(orderItem)}
                    icon={<IconStatusChange size={14} />}
                  >
                    Alterar status
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => navigation(`/order/edit/${orderItem.id}`)}
                    icon={<IconEdit size={14} />}
                    disabled={
                      orderItem.financialStatus === FinancialStatus.INVOICED
                    }
                  >
                    Editar
                  </Menu.Item>
                  <Menu.Item
                    disabled={
                      !!orderItem.origin ||
                      orderItem.isSample ||
                      orderItem.rework
                    }
                    onClick={() => setOrderToClone(orderItem)}
                    icon={<IconCopy size={14} />}
                  >
                    Criar repetição
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => handleViewOrder(orderItem)}
                    icon={<IconEye size={14} />}
                  >
                    Ver
                  </Menu.Item>
                  <Menu.Item
                    disabled={!!orderItem.layout}
                    onClick={() => requestLayout(orderItem.id)}
                    icon={<IconWriting size={14} />}
                  >
                    Solicitar layout
                  </Menu.Item>
                  <Menu.Item
                    disabled={orderItem.rework}
                    onClick={() => handleSelectToRework(orderItem)}
                    icon={<IconRefreshDot size={14} />}
                  >
                    Criar retrabalho
                  </Menu.Item>
                  <Menu.Item
                    disabled={orderItem.isSample || orderItem.rework}
                    onClick={() => requestSample(orderItem)}
                    icon={<IconTestPipe2 size={14} />}
                  >
                    Solicitar amostra
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
      <Group position="right">
        <Text>
          total: {formatBRL(Number(meta?.custom?.totalPrice).toFixed(2))}
        </Text>
      </Group>
      <Modal
        size={500}
        opened={pageModal}
        onClose={() => setPageModal(false)}
        title="Solicitar Layout"
      >
        <form onSubmit={formLayoutActivity.onSubmit(formLayoutActivitySubmit)}>
          <Grid gutter="xs" columns={2}>
            <Grid.Col mt={8} mb={8} span={1}>
              <Select
                required
                withAsterisk
                name="target"
                label="Atribuir para"
                placeholder="selecione um usuário"
                data={filterUserList.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                {...formLayoutActivity.getInputProps('target')}
              />
            </Grid.Col>
            <Grid.Col mt={8} mb={8} span={1}>
              <Switch
                mt={16}
                checked={formLayoutActivity.values.isPrioritary}
                color="teal"
                size="sm"
                label="é Prioritário?"
                thumbIcon={
                  formLayoutActivity.values.isPrioritary ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...formLayoutActivity.getInputProps('isPrioritary')}
              />
            </Grid.Col>
            <Grid.Col mt={8} mb={8} span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Observações"
                placeholder="observações sobre o layout"
                name="obs"
                required
                withAsterisk
                value={formLayoutActivity.values.obs}
                onChange={(e) =>
                  formLayoutActivity.setFieldValue('obs', e.target.value)
                }
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="ltpBlue.9" type="submit">
              Solicitar
            </Button>
          </Group>
        </form>
      </Modal>
    </Flex>
  );
}
