import { appConfig } from '../config';

export enum ServicesEnum {
  adesivo = 'Adesivo',
  aluguel = 'Aluguel de máquinas',
  Corte = 'Corte a laser',
  Gravação = 'Gravação a laser',
  Decalque = 'Decalque',
  Etiqueta = 'Etiqueta resinada',
  Envelopamento = 'Envelopamento',
  Hot = 'Hot stamping',
  Silkscreen = 'Silkscreen',
  Sublimação = 'Sublimação',
  Tampo = 'Tampo',
  Transfer = 'Transfer',
}

export enum AppRoutePaths {
  ACTIVITIES = '/activities',
  ART_ACTIVITY = '/art-activities/:id',
  ACTIVITY_LAYOUTS = '/activity-layouts',
  LOGIN = '/',
  BUDGET = '/budget',
  CONFIRM_BUDGET = '/confirm-budget',
  HOME = '/home',
  CREATE_ORDER = '/create-order',
  NOT_FOUND = '*',
  ORDER_DETAILS = '/order/:id',
  ORDERS = '/orders',
  ORDERS_LATE = '/orders/late',
  ORDERS_FIRST_CONTACT = '/orders/first-contact',
  ORDER_HISTORIC = '/order-historic',
  ORDER_PRODUCTION = '/order-production/:id',
  ORDER_HANDLING = '/order-handling/:id',
  ORDER_PACKING = '/order-packing/:id',
  EDIT_ORDER = '/order/edit/:id',
  USERS = '/users',
  USERS_CREATE = '/users-create',
  USERS_EDIT = '/users/edit/:id',
  COMPANY = '/companies',
  COMPANY_CREATE = '/companies-create',
  COMPANY_EDIT = '/companies/edit/:id',
  RESET_PASSWORD = '/reset-password',
  TEAMS = '/teams',
  TEAMS_CREATE = '/teams-create',
  TEAMS_EDIT = '/teams/edit/:id',
  ORDER_DISPATCH = '/order-dispatch/:hash',
  ORDER_MATERIALS = '/order-materials',
  ORDER_MATERIALS_PENDING = '/order-materials/pending',
  ORDER_MATERIALS_CREATE = '/order-materials-create',
  ORDER_MATERIALS_EDIT = '/order-materials/edit/:id',
  ORDER_MATERIALS_DETAILS = '/order-materials/details/:id',
  ORDER_MATERIALS_ENTRIES = '/order-materials/entries',
  FINANCIAL_INVOICE = '/finances/invoices',
  FINANCIAL_PAYMENT = '/finances/payments',
  REPORTS_PRODUCTION = '/reports-production',
  REPORTS_HANDLING = '/reports-handling',
  REPORTS_PAYMENTS = '/reports-payments',
  REPORTS_ART = '/reports-art',
  PURCHASES = '/purchases',
  PURCHASES_CREATE = '/purchases-create',
  PURCHASES_EDIT = '/purchases/edit/:id',
  BILLS = '/bills',
  BILLS_CREATE = '/bills-create',
  BILLS_EDIT = '/bills/edit/:id',
  POSTAL_LIST = '/postals',
  POSTAL_CREATE = '/postals-create',
  POSTAL_EDIT = '/postals-edit/:id',
  RECEIPT_LIST = '/receipts',
  ORDER_PCP = '/order-pcp',
  SAC_LIST = '/sac',
  SERVICE_ITEM_LIST = '/service-items',
  OPTION_LIST = '/options',
  OPTION_CREATE = '/options/create',
  OPTION_EDIT = '/options/edit/:id',
  ORDER_DEFECT = '/orders/defect/:id',
  ORDER_FILE_RECEIPTS_APPROVAL = '/file-receipts-approval',
  PAYMENT_SLIP_LIST = '/payment-slip',
}

export const LocalStorageKeys = {
  AUTH_TOKEN: `${appConfig.devName}:auth:user`,
  NOTIFICATIONS: `${appConfig.devName}:notifications`,
  PRODUCT_ENTRY_PAYLOAD: 'product_entry_payload',
};

export enum DefectType {
  PRODUCT_DEFECT = 'product_defect',
  INCORRECT_QUANTITY = 'incorrect_quantity',
}

export enum ApprovalType {
  SEND = 'send',
  NOT_SEND = 'not_send',
}

export enum PermissionAlias {
  VIEW_MATERIAL_VOLUME_TRANSFER = 'view_material_volume_transfer',
  HANDLE_MATERIAL_VOLUME_TRANSFER = 'handle_material_transfer',
  VIEW_ORDER_MATERIAL = 'view_order_material',
  CREATE_ORDER_MATERIAL = 'create_order_material',
  EDIT_ORDER_MATERIAL = 'edit_order_material',
  DELETE_ORDER_MATERIAL = 'delete_order_material',
  VIEW_PENDING_ORDER_MATERIAL = 'view_pending_order_material',
}
