import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import { normalizeText } from '../../utils/helpers';
import { UploadFileRequestData } from '../../utils/types/data/services/files';

export async function uploadMultipleFilesRequest(
  data: UploadFileRequestData,
): Promise<AxiosResponse<number[]>> {
  const formData = new FormData();

  data.files.forEach((item) => {
    formData.append(`files`, item, normalizeText(item.name));
  });

  const response = await api.post<number[]>('files/multiple', formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });

  return response;
}
