/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Stepper,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useState } from 'react';
import { CustomDatePicker } from '../../components/CustomDatePicker';
import { CustomFileInput } from '../../components/CustomFileInput';
import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigate } from '../../components/CustomRouter';

import { Page } from '../../components/Page';
import { useUploadMultipleFiles } from '../../data/hooks/files';
import { createPurchaseRequest } from '../../data/services/purchase';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums';
import { formatBRL, realToUSCash } from '../../utils/helpers';

type FormType = {
  name: string;
  description?: string;
  price: string;
  receipt: File | null;
  boughtAt: Date;
};

export function PurchaseCreate() {
  const [loading, setLoading] = useState(false);
  const { fetch: uploadFilesFetcher, loading: uploadFilesLoader } =
    useUploadMultipleFiles();
  const form = useForm<FormType>({
    initialValues: {
      name: '',
      description: '',
      boughtAt: new Date(),
      receipt: null,
      price: '',
    },
  });

  async function handleSubmit(values: typeof form.values) {
    let fileId: number[] = [];

    try {
      if (values.receipt) {
        await uploadFilesFetcher({
          data: {
            files: [values.receipt],
          },
          onSuccess: (res) => {
            fileId = res;
          },
        });
      }

      setLoading(true);
      await createPurchaseRequest({
        ...values,
        receipt: fileId[0] ?? undefined,
        price: realToUSCash(values.price),
      });
      setLoading(false);
      successNotification({
        title: 'Compra adicionada!',
        message: 'tudo certo.',
      });
      form.reset();
      rootNavigate(AppRoutePaths.PURCHASES);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao adicionar compra!',
        message: 'ops! tente novamente',
      });
    }
  }

  return (
    <Page>
      <Container miw={320}>
        <CustomLoader loading={loading || uploadFilesLoader} />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigate(AppRoutePaths.PURCHASES)}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Nova Compra
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Compra" description="dados da compra">
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="ex: 4 caixas de folha A4 "
                mb={16}
                type="text"
                name="name"
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                withAsterisk
                label="Valor"
                placeholder="ex: R$80,40"
                mb={16}
                type="text"
                name="price"
                value={formatBRL(form.values.price ?? '')}
                onChange={(e) =>
                  form.setFieldValue('price', formatBRL(e.target.value))
                }
              />
              <CustomDatePicker
                label="Data da compra"
                value={form.values.boughtAt}
                {...form.getInputProps('boughtAt')}
              />
              <Textarea
                maxLength={255}
                mt={16}
                label="Observações"
                placeholder="escreva aqui qualquer observação que queira fazer sobre a compra"
                name="description"
                {...form.getInputProps('description')}
              />
              <CustomFileInput
                mt={16}
                withAsterisk
                required
                name="receipt"
                value={form.values.receipt}
                label="Comprovante"
                placeholder="Anexe aqui o comprovante da compra"
                inputName="receipt"
                formRef={form}
              />
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
