import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import CustomRouter from './components/CustomRouter';
import NotificationProvider from './context/notification-context';
import { injectStore } from './providers/base-api';
import { persistor, store } from './providers/store';
import PermissionProvider from './context/permission-context';

injectStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <NotificationProvider>
        <PermissionProvider>
          <PersistGate persistor={persistor}>
            <CustomRouter>
              <App />
            </CustomRouter>
          </PersistGate>
        </PermissionProvider>
      </NotificationProvider>
    </Provider>
  </React.StrictMode>,
);
