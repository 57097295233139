import {
  Accordion,
  Badge,
  Button,
  Card,
  Flex,
  Image,
  Text,
} from '@mantine/core';
import { IconFile } from '@tabler/icons';
import axios from 'axios';
import {
  ActivityProduction,
  ActivityProductionHistoryResponse,
} from '../../models/activity-production';
import { ActivityProductionFile } from '../../models/activity-production-file';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  activityProductionStatusColor,
  activityProductionStatusHumanized,
} from '../../utils/constants/activity-production';

interface ActivityProductionProps {
  activityProduction: ActivityProduction;
  files: ActivityProductionFile[] | undefined;
}

interface ActivityProductionHistoryProps {
  activityProduction: ActivityProductionHistoryResponse;
}

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function ActivityProductionItem({
  activityProduction,
  files,
}: ActivityProductionProps) {
  return (
    <Card mt={16} withBorder>
      <Flex justify="space-between">
        <Text fw="bold">Arquivo Produção</Text>
        <Badge
          maw={160}
          color={activityProductionStatusColor[activityProduction?.status]}
        >
          {activityProductionStatusHumanized[activityProduction?.status]}
        </Badge>
      </Flex>
      <Flex justify="space-between">
        <Text>Avaliado por: {activityProduction?.technicalName}</Text>
        <Text>
          {activityProduction.approval &&
            formatLocale(activityProduction.approval, 'DD/MM/YY HH:mm')}
        </Text>
      </Flex>
      <Text>Obs: {activityProduction?.technicalNote}</Text>
      <Text fw="bold" mt={16}>
        Arquivos
      </Text>
      <Flex wrap="wrap" gap={'xs'}>
        {files?.map((item) => (
          <Card key={item.file} bg="gray.1" withBorder w={210} h={250}>
            <Image
              height={100}
              src={item.fileUrl}
              fit="cover"
              withPlaceholder
            />
            <Flex mt={10} align="center">
              <IconFile size={14} style={{ marginRight: 5 }} />
              <Button
                variant="subtle"
                onClick={() => downloadFile(item.fileUrl, item.fileName)}
              >
                <Text
                  variant="link"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: 100,
                    display: 'inline-block',
                  }}
                >
                  {item.fileName}
                </Text>
              </Button>
            </Flex>
            <Text>por: {item.ownerName}</Text>
            <Text>{formatLocale(item.createdAt, 'DD/MM/YY HH:mm')}</Text>
          </Card>
        ))}
      </Flex>
    </Card>
  );
}

export function ActivityProductionHistory({
  activityProduction,
}: ActivityProductionHistoryProps) {
  return (
    <>
      <Flex justify="space-between">
        <Text fw="bold">Arquivo Produção</Text>
        <Badge
          maw={160}
          color={
            activityProductionStatusColor[activityProduction.layout.status]
          }
        >
          {activityProductionStatusHumanized[activityProduction.layout.status]}
        </Badge>
      </Flex>
      <Flex justify="space-between">
        <Text>Avaliado por: {activityProduction?.layout.technicalName}</Text>
        <Text>
          {activityProduction.layout.approval &&
            formatLocale(activityProduction.layout.approval, 'DD/MM/YY HH:mm')}
        </Text>
      </Flex>
      <Text>Obs: {activityProduction.layout.technicalNote}</Text>

      <Accordion variant="default" transitionDuration={200}>
        <Accordion.Item value="files">
          <Accordion.Control icon={<IconFile />}>Arquivos</Accordion.Control>
          <Accordion.Panel>
            <Flex wrap="wrap" align={'center'} justify={'center'} gap={'xs'}>
              {activityProduction.layout.files?.map((item) => (
                <Card
                  key={item.file}
                  bg="gray.1"
                  withBorder
                  w="100%"
                  h={250}
                  mb={'xs'}
                >
                  <Image
                    height={100}
                    src={item.fileUrl}
                    fit="cover"
                    withPlaceholder
                  />
                  <Flex mt={10} align="center">
                    <IconFile size={14} style={{ marginRight: 5 }} />
                    <Button
                      variant="subtle"
                      onClick={() => downloadFile(item.fileUrl, item.fileName)}
                    >
                      <Text
                        variant="link"
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'inline-block',
                        }}
                      >
                        {item.fileName}
                      </Text>
                    </Button>
                  </Flex>
                  <Text>por: {item.ownerName}</Text>
                  <Text>{formatLocale(item.createdAt, 'DD/MM/YY HH:mm')}</Text>
                </Card>
              ))}
            </Flex>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
