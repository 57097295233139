import {
  Accordion,
  Badge,
  Button,
  Card,
  Flex,
  Image,
  Text,
} from '@mantine/core';
import { IconFile } from '@tabler/icons';
import axios from 'axios';
import {
  ActivityLayout,
  ActivityLayoutHistory,
} from '../../models/activity-layout';
import { ActivityLayoutFile } from '../../models/activity-layout-file';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  activityLayoutStatusColor,
  activityLayoutStatusHumanized,
} from '../../utils/constants/activity-layout';

interface ActivityLayoutProps {
  activityLayout: ActivityLayout;
  files: ActivityLayoutFile[] | undefined;
}

interface ActivityLayoutHistoryProps {
  activityLayouts: ActivityLayoutHistory[];
}

async function downloadFile(fileUrl: string, fileName: string) {
  const { data: blob } = await axios.get(fileUrl, {
    responseType: 'blob',
    headers: {},
  });
  const url = URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

export function ActivityLayoutItem({
  activityLayout,
  files,
}: ActivityLayoutProps) {
  return (
    <Card mt={16} maw={700} withBorder>
      <Flex justify="space-between">
        <Text fw="bold">Layout</Text>
        <Badge
          maw={160}
          color={activityLayoutStatusColor[activityLayout?.status]}
        >
          {activityLayoutStatusHumanized[activityLayout?.status]}
        </Badge>
      </Flex>
      <Flex justify="space-between">
        <Text>
          Avaliado por:{' '}
          {activityLayout?.clientName || activityLayout?.vendorName}
        </Text>
        <Text>
          {activityLayout.approval &&
            formatLocale(activityLayout.approval, 'DD/MM/YY HH:mm')}
        </Text>
      </Flex>
      <Text>
        Obs: {activityLayout?.clientNote || activityLayout?.vendorNote}
      </Text>
      <Text fw="bold" mt={16}>
        Arquivos
      </Text>
      <Flex wrap="wrap">
        {files?.map((item) => (
          <Card key={item.file} bg="gray.1" withBorder w={210} h={250}>
            <Image
              height={100}
              src={item.fileUrl}
              fit="cover"
              withPlaceholder
            />
            <Flex mt={10} align="center">
              <IconFile size={14} style={{ marginRight: 5 }} />
              <Button
                variant="subtle"
                onClick={() => downloadFile(item.fileUrl, item.fileName)}
              >
                <Text
                  variant="link"
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: 100,
                    display: 'inline-block',
                  }}
                >
                  {item.fileName}
                </Text>
              </Button>
            </Flex>
            <Text>por: {item.ownerName}</Text>
            <Text>{formatLocale(item.createdAt, 'DD/MM/YY HH:mm')}</Text>
          </Card>
        ))}
      </Flex>
    </Card>
  );
}

export function ActivityLayoutHistoryItem({
  activityLayouts,
}: ActivityLayoutHistoryProps) {
  return (
    <>
      {activityLayouts.map((item) => (
        <Card mt={16} maw={700} withBorder key={item.id}>
          <Flex justify="space-between">
            <Text fw="bold">Layout</Text>
            <Badge maw={160} color={activityLayoutStatusColor[item.status]}>
              {activityLayoutStatusHumanized[item.status]}
            </Badge>
          </Flex>
          <Flex justify="space-between">
            <Text>Avaliado por: {item?.clientName || item?.vendorName}</Text>
            <Text>
              {item.approval && formatLocale(item.approval, 'DD/MM/YY HH:mm')}
            </Text>
          </Flex>
          <Text>Obs: {item?.clientNote || item?.vendorNote}</Text>

          <Accordion variant="default" transitionDuration={200}>
            <Accordion.Item value="files">
              <Accordion.Control icon={<IconFile />}>
                Arquivos
              </Accordion.Control>
              <Accordion.Panel>
                <Flex wrap="wrap" align={'center'} justify={'center'}>
                  {item.files?.map((item) => (
                    <Card
                      key={item.file}
                      bg="gray.1"
                      withBorder
                      w="100%"
                      h={250}
                      mb={'xs'}
                    >
                      <Image
                        height={100}
                        src={item.fileUrl}
                        fit="cover"
                        withPlaceholder
                      />
                      <Flex mt={10} align="center">
                        <IconFile size={14} style={{ marginRight: 5 }} />
                        <Button
                          variant="subtle"
                          onClick={() =>
                            downloadFile(item.fileUrl, item.fileName)
                          }
                        >
                          <Text
                            variant="link"
                            style={{
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: 'inline-block',
                            }}
                          >
                            {item.fileName}
                          </Text>
                        </Button>
                      </Flex>
                      <Text>por: {item.ownerName}</Text>
                      <Text>
                        {formatLocale(item.createdAt, 'DD/MM/YY HH:mm')}
                      </Text>
                    </Card>
                  ))}
                </Flex>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </Card>
      ))}
    </>
  );
}
