import { AxiosResponse } from 'axios';
import { api } from '../../providers/base-api';
import {
  CreateActivityChecklistRequestData,
  ListActivityChecklistRequestQuery,
  ListActivityChecklistResponse,
} from '../../utils/types/data/services/activity-checklist';

export async function createActivityChecklistRequest(
  activityId: number,
  data: CreateActivityChecklistRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `activity-checklists/${activityId}`,
    data,
  );

  return response;
}

export async function listActivityChecklistRequest(
  query: ListActivityChecklistRequestQuery,
): Promise<AxiosResponse<ListActivityChecklistResponse[]>> {
  const response = await api.get<ListActivityChecklistResponse[]>(
    `activity-checklists`,
    {
      params: query,
    },
  );

  return response;
}
