import { AxiosError } from 'axios';
import { Order } from '../models/order';
import { formatLocale } from '../providers/dayjs-plugins';
import { errorsTranslate } from './constants';

export type OrderPricesData = {
  samplePrice: string | null;
  photolitoPrice: string | null;
  manipulationPrice: string | null;
  vectorizationPrice: string | null;
  clichePrice: string | null;
  totalPrice: string;
  unit: string;
  price: string | null;
  changeColorPriceUnit: string | null;
  changeColorTotalPrice: string | null;
};

export function formatDateTime(date: Date | string) {
  return formatLocale(date, 'DD/MM/YY HH:mm');
}

export function booleanToStringConverter(
  value: boolean,
  converter: [string, string] = ['sim', 'não'],
) {
  return value ? converter[0] : converter[1];
}

export function formatBRL(value: string | null | undefined) {
  if (!value) {
    return '';
  }

  value = value.replace(/\D/g, '');
  value = (Number(value) / 100).toFixed(2) + '';
  value = value.replace('.', ',');
  value = value.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  value = value.replace(/(\d)(\d{3}),/g, '$1.$2,');

  return `R$${value}`;
}

export function realToUSCash(value: string) {
  if (!value) {
    return '';
  }

  return value.replace('R$', '').replace('.', '').replace(',', '.');
}

export function textCapitalize(text: string) {
  return text.replace(
    /\b([a-zÁ-ú]{3,})/g,
    (w) => w.charAt(0).toUpperCase() + w.slice(1),
  );
}

export function secondsToTime(timeSeconds: number) {
  let seconds = timeSeconds;
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60) % 60;

  seconds = seconds % 60;
  minutes = minutes % 60;

  return {
    seconds,
    minutes,
    hours,
  };
}

export function getOrderPrices(
  order: Pick<
    Order,
    | 'price'
    | 'samplePrice'
    | 'pholitoPrice'
    | 'manipulationPrice'
    | 'vectorizationPrice'
    | 'clichePrice'
    | 'quantity'
    | 'changeColorPrice'
    | 'changeColorTotalPrice'
  >,
): OrderPricesData {
  const price = Number(realToUSCash(String(order.price))) || 0;
  const samplePrice = Number(realToUSCash(String(order.samplePrice))) || 0;
  const photolitoPrice = Number(realToUSCash(String(order.pholitoPrice))) || 0;
  const manipulationPrice =
    Number(realToUSCash(String(order.manipulationPrice))) || 0;
  const vectorizationPrice =
    Number(realToUSCash(String(order.vectorizationPrice))) || 0;
  const clichePrice = Number(realToUSCash(String(order.clichePrice))) || 0;
  const quantity = Number(order.quantity);
  const unitPrice = Math.fround(price / quantity).toFixed(0);
  const changeColorTotalPrice =
    Number(realToUSCash(String(order.changeColorTotalPrice))) || 0;
  const changeColorPriceUnit =
    Number(realToUSCash(String(order.changeColorPrice))) || 0;

  const orderUnitPrice = formatBRL(unitPrice);
  const orderTotalPrice = formatBRL(
    String(
      price +
        samplePrice +
        photolitoPrice +
        manipulationPrice +
        vectorizationPrice +
        clichePrice +
        changeColorTotalPrice,
    ),
  );

  return {
    samplePrice: samplePrice > 0 ? formatBRL(String(samplePrice)) : null,
    photolitoPrice:
      photolitoPrice > 0 ? formatBRL(String(photolitoPrice)) : null,
    manipulationPrice:
      manipulationPrice > 0 ? formatBRL(String(manipulationPrice)) : null,
    vectorizationPrice:
      vectorizationPrice > 0 ? formatBRL(String(vectorizationPrice)) : null,
    clichePrice: clichePrice > 0 ? formatBRL(String(clichePrice)) : null,
    totalPrice: orderTotalPrice,
    unit: orderUnitPrice,
    price: price > 0 ? formatBRL(String(price)) : null,
    changeColorTotalPrice:
      changeColorTotalPrice > 0
        ? formatBRL(String(changeColorTotalPrice))
        : null,
    changeColorPriceUnit:
      changeColorPriceUnit > 0 ? formatBRL(String(changeColorPriceUnit)) : null,
  };
}

export function getNFSEDiscrimination(
  order: Order,
  orderPrices: OrderPricesData,
) {
  const discriminationArray = ['Prestação de serviço em:\n'];

  discriminationArray.push(
    `${order.quantity} Personalização ${order.service.name} em ${
      order.product
    } ${order.productColor} un ${orderPrices.unit} (logotipo: ${
      order.engravingText ?? ''
    })`,
  );

  if (orderPrices.clichePrice !== null) {
    discriminationArray.push(
      `1 Clichê para ${order.service.name} ${order.product} ${order.productColor} un ${orderPrices.clichePrice}`,
    );
  }

  if (orderPrices.photolitoPrice !== null) {
    discriminationArray.push(
      `1 Fotolito para ${order.service.name} ${order.product} ${order.productColor} un ${orderPrices.photolitoPrice}`,
    );
  }

  if (orderPrices.vectorizationPrice !== null) {
    discriminationArray.push(
      `Vetorização de arte para ${order.product} ${order.productColor} un ${orderPrices.vectorizationPrice}`,
    );
  }

  if (orderPrices.manipulationPrice !== null) {
    discriminationArray.push(
      `Manuseio de produto ${order.product} ${order.productColor} un ${orderPrices.manipulationPrice}`,
    );
  }

  if (orderPrices.changeColorPriceUnit !== null) {
    discriminationArray.push(
      `Troca de cor ${order.product} ${order.productColor} un ${orderPrices.changeColorPriceUnit}`,
    );
  }

  discriminationArray.push(
    `\n CARGA TRIBUTÁRIA CONFORME LEI 12.471/2012 ${orderPrices.totalPrice}`,
  );

  return discriminationArray;
}

export function translateServerHttpErrors(
  err: AxiosError<{ message: string }>,
  defaultMessage: string,
) {
  if (err.response?.data.message) {
    return errorsTranslate?.[err.response?.data.message];
  } else {
    return defaultMessage;
  }
}

export function normalizeText(text: string) {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .split(' ')
    .join('-')
    .toLowerCase();
}

export function booleanToStringConverterOrderProbem(
  value: boolean,
  converter: [string, string] = [
    'Continuar com a produção',
    'Não continuar com a produção',
  ],
) {
  return value ? converter[0] : converter[1];
}

export function transformNumberInArray(n: number) {
  return Array.from({ length: n }, (_, i) => i + 1);
}
