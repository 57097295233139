import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import {
  useGetActivityLayoutFile,
  useGetActivityLayoutToApprove,
  useVendorApproveActivityLayout,
  useRequestClientApprovalActivityLayout,
  useClientApproveActivityLayout,
  useGetActivityPaused,
} from '../../data/hooks/activity-layouts';
import { useUploadMultipleFiles } from '../../data/hooks/files';
import { UserRole } from '../../models/user';
import { RootState } from '../../providers/store';

import { ActivityLayoutsToApproveResponse } from '../../utils/types/data/services/activity-layouts';
import { CompanyLayoutList } from './company';
import { VendorLayoutList } from './vendor';

export type ActivityLayoutApproveFormType = {
  isApproved: boolean;
  vendorNote?: string;
  clientNote?: string;
  files: File[];
};

export type FilterLayoutsFormType = {
  os?: string;
  company?: string;
  status?: string;
};

export function LayoutList() {
  const [pageModal, setPageModal] = useState(false);
  const { user } = useSelector((state: RootState) => state.auth);
  const [selectedLayout, setSelectedLayout] =
    useState<ActivityLayoutsToApproveResponse>();
  const [activeTab, setActiveTab] = useState<string | null>('analyze');

  const page = useRef(1);
  const limit = useRef(10);
  const {
    fetch: getAtivityLayoutToApproveFetcher,
    reponseData: getAtivityLayoutToApproveData,
    loading: getAtivityLayoutToApproveLoader,
  } = useGetActivityLayoutToApprove();
  const {
    fetch: getActivityLayoutFilesFetcher,
    loading: getActivityLayoutFilesLoader,
    reponseData: getActivityLayoutFilesData,
  } = useGetActivityLayoutFile();
  const {
    fetch: vendorApproveLayoutFetcher,
    loading: vendorApproveLayoutLoader,
  } = useVendorApproveActivityLayout();
  const {
    fetch: clientApproveLayoutFetcher,
    loading: clientApproveLayoutLoader,
  } = useClientApproveActivityLayout();
  const {
    fetch: requestClientApprovalFetcher,
    loading: requestClientApprovalLoader,
  } = useRequestClientApprovalActivityLayout();
  const {
    fetch: getAtivityPausedFetcher,
    reponseData: getAtivityPausedData,
    loading: getAtivityPausedLoader,
  } = useGetActivityPaused();
  const { fetch: uploadFilesFetcher, loading: uploadFilesLoader } =
    useUploadMultipleFiles();

  const approveActivityForm = useForm<ActivityLayoutApproveFormType>({
    initialValues: {
      isApproved: false,
      vendorNote: '',
      clientNote: '',
      files: [],
    },
  });

  const filterLayoutForm = useForm<FilterLayoutsFormType>({
    initialValues: {
      os: '',
      company: '',
      status: '',
    },
  });

  function handlePaginate(newPage: number) {
    page.current = newPage;

    if (activeTab === 'analyze') {
      getLayouts();
    } else if (activeTab == 'paused') {
      getActivitiesPaused();
    }
  }

  function handleFilter() {
    page.current = 1;
    limit.current = 10;
    if (activeTab === 'analyze') {
      getLayouts();
    } else if (activeTab == 'paused') {
      getActivitiesPaused();
    }
  }

  function handleClear() {
    page.current = 1;
    limit.current = 10;
    filterLayoutForm.reset();
    if (activeTab === 'analyze') {
      getLayouts(true);
    } else if (activeTab == 'paused') {
      getActivitiesPaused(true);
    }
  }

  function handleSelectLayout(item: ActivityLayoutsToApproveResponse) {
    setSelectedLayout(item);
    getLastLayoutFiles(item.id);
    setPageModal(true);
  }

  async function getLayouts(isReset = false) {
    let validatedFilter: any = {};

    Object.entries(filterLayoutForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      validatedFilter = {};
    }

    await getAtivityLayoutToApproveFetcher({
      query: {
        page: page.current,
        limit: limit.current,
        ...validatedFilter,
      },
    });
  }

  async function getActivitiesPaused(isReset = false) {
    let validatedFilter: any = {};

    Object.entries(filterLayoutForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      validatedFilter = {};
    }

    await getAtivityPausedFetcher({
      query: {
        page: page.current,
        limit: limit.current,
        ...validatedFilter,
      },
    });
  }

  async function getLastLayoutFiles(id: number) {
    await getActivityLayoutFilesFetcher({ query: { activityLayout: id } });
  }

  async function handleVendorApproveLayout(
    values: typeof approveActivityForm.values,
  ) {
    if (selectedLayout) {
      let fileIds: number[] = [];
      if (approveActivityForm.values.files.length) {
        await uploadFilesFetcher({
          data: {
            files: values.files,
          },
          onSuccess: (res) => {
            fileIds = res;
          },
        });
      }

      await vendorApproveLayoutFetcher({
        id: selectedLayout?.id,
        data: {
          ...values,
          files: fileIds,
        },
        onSuccess: () => {
          setPageModal(false);
          getLayouts();
          approveActivityForm.reset();
          setSelectedLayout(undefined);
        },
      });
    }
  }

  async function handleCompanyApproveLayout(
    values: typeof approveActivityForm.values,
  ) {
    if (selectedLayout) {
      let fileIds: number[] = [];
      if (approveActivityForm.values.files.length) {
        await uploadFilesFetcher({
          data: {
            files: values.files,
          },
          onSuccess: (res) => {
            fileIds = res;
          },
        });
      }

      await clientApproveLayoutFetcher({
        id: selectedLayout?.id,
        data: {
          ...values,
          files: fileIds,
        },
        onSuccess: () => {
          setPageModal(false);
          getLayouts();
          approveActivityForm.reset();
          setSelectedLayout(undefined);
        },
      });
    }
  }

  async function handleRequestClientApproval() {
    if (selectedLayout) {
      await requestClientApprovalFetcher({
        id: selectedLayout.id,
        onSuccess: () => {
          setPageModal(false);
          getLayouts();
          setSelectedLayout(undefined);
        },
      });
    }
  }

  useEffect(() => {
    if (activeTab === 'analyze') {
      page.current = 1;
      getLayouts();
    } else if (activeTab == 'paused') {
      page.current = 1;
      getActivitiesPaused();
    }
  }, [activeTab]);

  return (
    <Page title="Layouts">
      <CustomLoader
        loading={
          getActivityLayoutFilesLoader ||
          getAtivityLayoutToApproveLoader ||
          vendorApproveLayoutLoader ||
          requestClientApprovalLoader ||
          clientApproveLayoutLoader ||
          getAtivityPausedLoader ||
          uploadFilesLoader
        }
      />
      {user?.role === UserRole.VENDOR && (
        <VendorLayoutList
          loading={vendorApproveLayoutLoader}
          approveActivityForm={approveActivityForm}
          getActivityLayoutFilesData={getActivityLayoutFilesData}
          getAtivityLayoutToApproveData={getAtivityLayoutToApproveData}
          handleApproveLayout={handleVendorApproveLayout}
          handlePaginate={handlePaginate}
          handleRequestClientApproval={handleRequestClientApproval}
          handleSelectLayout={handleSelectLayout}
          page={page}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          pageModal={pageModal}
          setPageModal={setPageModal}
          filterLayoutForm={filterLayoutForm}
          handleFilter={handleFilter}
          handleClear={handleClear}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          getAtivityPausedData={getAtivityPausedData}
        />
      )}
      {user?.role === UserRole.COMPANY && (
        <CompanyLayoutList
          loading={clientApproveLayoutLoader}
          approveActivityForm={approveActivityForm}
          getActivityLayoutFilesData={getActivityLayoutFilesData}
          getAtivityLayoutToApproveData={getAtivityLayoutToApproveData}
          handleApproveLayout={handleCompanyApproveLayout}
          handlePaginate={handlePaginate}
          handleSelectLayout={handleSelectLayout}
          page={page}
          selectedLayout={selectedLayout}
          pageModal={pageModal}
          setPageModal={setPageModal}
          filterLayoutForm={filterLayoutForm}
          handleFilter={handleFilter}
          handleClear={handleClear}
        />
      )}
    </Page>
  );
}
