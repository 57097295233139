import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { UseUploadMultipleFilesProps } from '../../../utils/types/data/hooks/files';
import { uploadMultipleFilesRequest } from '../../services/files';

export function useUploadMultipleFiles() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<number[]>();

  const fetch = ({ data, onSuccess }: UseUploadMultipleFilesProps) =>
    fetchData({
      fetcher: () => uploadMultipleFilesRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao enviar arquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
