import { createContext, ReactNode } from 'react';
import { usePermissions } from '../hooks/use-permissions';
import { PermissionAlias } from '../utils/enums';

interface PermissionContext {
  list: PermissionAlias[];
  getPermissions: () => void;
}

export const PermissionContext = createContext<PermissionContext>({
  list: [],
  getPermissions: () => {},
});

const PermissionProvider = ({ children }: { children: ReactNode }) => {
  const { getPermissions, permissions } = usePermissions();

  return (
    <PermissionContext.Provider
      value={{
        list: permissions,
        getPermissions: getPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
