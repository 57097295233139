import {
  Accordion,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Popover,
  Select,
  Skeleton,
  Table,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { IconDownload, IconTruckOff } from '@tabler/icons';
import { useEffect, useRef, useState } from 'react';

import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { CustomDatePicker } from '../../components/CustomDatePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { ExportableModal } from '../../components/ExportableModal';
import { NfOrderItem } from '../../components/NfOrderItem';
import { NfPostalItem } from '../../components/NfPostalItem';
import { NfReceiptItem } from '../../components/NfReceiptItem';
import { Page } from '../../components/Page';
import { useGetCompanyDocuments } from '../../data/hooks/company-documents';
import { useInvoiceOrderListExportable } from '../../data/hooks/exportables';
import {
  useCancelPayment,
  useGenerateNfToPayment,
  useGetNfProviders,
  useGetOrdersByPayment,
  useSetOrderPaymentPaid,
} from '../../data/hooks/financial';
import { useGetReceiptsByOrder } from '../../data/hooks/order-receipts';
import { useGetPostalsByOrder } from '../../data/hooks/postals';
import {
  getFilterCompaniesListRequest,
  getFilterTeamsListRequest,
} from '../../data/services/filters';
import {
  cancelNFSeRequest,
  getCompanyToFinance,
  getOrderPaymentsRequest,
  setOrderPaymentRequest,
  showNFSeRequest,
  ShowServiceNfResponse,
} from '../../data/services/financial';
import { useFileDownload } from '../../hooks/use-file-download';
import { Company } from '../../models/company';
import { Order, OrderPaymentStatus } from '../../models/order';
import { OrderPaymentCustomResponse } from '../../models/order-payment';
import { TeamType } from '../../models/team';
import { UserRole, UserType } from '../../models/user';
import { dayjsPlugins, formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import {
  orderPaymentStatusList,
  orderTransactionTypeList,
  priceTypeHumanized,
} from '../../utils/constants/order';
import {
  formatBRL,
  formatDateTime,
  getNFSEDiscrimination,
  getOrderPrices,
  OrderPricesData,
  realToUSCash,
  translateServerHttpErrors,
} from '../../utils/helpers';
import { PaginatedResponse } from '../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
} from '../../utils/types/data/services/filters';
import { FinancialPaymentAdmin } from './admin';
import { FinancialPaymentPage } from './financial';

export type PageModalState =
  | 'payment'
  | 'confirm-payment'
  | 'view-nfse'
  | 'cancel-payment'
  | 'generate-nfse'
  | 'exportable-invoice'
  | null;

export type FormFilterType = {
  status?: string | null;
  company?: string | null;
  os?: string | null;
  limit: number;
  page: number;
  paymentStatus?: string;
  paymentDeadline?: string;
  financialStatus?: string;
  paymentNote?: string;
  billetNumber?: string;
  team?: string;
  paidAt?: string;
};

function AlternativePriceList({
  prices,
  postalsTotalPrice,
}: {
  prices: OrderPricesData;
  postalsTotalPrice?: number;
}) {
  return (
    <Flex direction="column">
      <Text fw="bold">Valores do Pedido</Text>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          {prices &&
            Object.entries(prices).map(
              ([key, value]) =>
                priceTypeHumanized[key] && (
                  <tr>
                    <td>{priceTypeHumanized[key]}</td>
                    <td>{value ?? '--'}</td>
                  </tr>
                ),
            )}
          <tr>
            <td>Correio:</td>
            <td>
              {postalsTotalPrice
                ? formatBRL(postalsTotalPrice?.toFixed(2))
                : '--'}
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{prices.totalPrice ?? '--'}</td>
          </tr>
        </tbody>
      </Table>
    </Flex>
  );
}

export function FinancialPayment() {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [selectedOrderPayment, setSelectedOrderPayment] =
    useState<OrderPaymentCustomResponse>();
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [orderPricesData, setOrderPricesData] = useState<OrderPricesData>();
  const [paymentList, setPaymentList] =
    useState<PaginatedResponse<OrderPaymentCustomResponse>>();
  const [confirmCancelNFSeVisible, setConfirmCancelNFSeVisible] =
    useState(false);
  const [companyFinancialFields, setCompanyFinancialFields] =
    useState<Company>();
  const currentPage = useRef(1);
  const [nfseData, setNFSeData] = useState<ShowServiceNfResponse>();
  const { user } = useSelector((state: RootState) => state.auth);
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const { loading: cancelPaymentLoader, fetch: cancelPaymentFetcher } =
    useCancelPayment();
  const {
    loading: generateNfToPaymentLoader,
    fetch: generateNfToPaymentFetcher,
  } = useGenerateNfToPayment();
  const {
    fetch: getCompanyDocumentsFetcher,
    loading: getCompanyDocumentsLoader,
    reponseData: getCompanyDocumentsData,
  } = useGetCompanyDocuments();
  const {
    fetch: getOrdersByPaymentFetcher,
    loading: getOrdersByPaymentLoader,
    reponse: getOrdersByPaymentData,
  } = useGetOrdersByPayment();
  const { fetch: getPostalsByOrderFetcher, response: getPostalsByOrderData } =
    useGetPostalsByOrder();
  const { fetch: getNfProvidersFetcher, reponse: getNfProvidersResponse } =
    useGetNfProviders();
  const { fetch: setAsPaidFetcher, loading: setAsPaidLoader } =
    useSetOrderPaymentPaid();
  const {
    fetch: getReceiptByOrderFetcher,
    loading: getReceiptByOrderLoader,
    response: getReceiptByOrderResponse,
  } = useGetReceiptsByOrder();
  const {
    fetch: invoiceOrderListExportFetcher,
    loading: invoiceOrderListExportLoader,
  } = useInvoiceOrderListExportable();
  const { download } = useFileDownload();

  const formEmitNFSe = useForm({
    initialValues: {
      discrimination: '',
      taxesPercentage: '',
      serviceCode: '06912',
      orderPrice: '',
      financialNote: '',
      paymentDeadline: new Date(),
      companyDocumentId: '',
      emiterCNPJ: '',
    },
  });

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      company: null,
      os: '',
      status: null,
      paymentDeadline: undefined,
      paidAt: undefined,
      paymentStatus: undefined,
      financialStatus: '',
      paymentNote: '',
      team: '',
      billetNumber: '',
    },
  });

  const formOrderPayment = useForm({
    initialValues: {
      paymentValue: '',
      paymentNote: '',
      paymentStatus: '',
      transactionType: '',
      taxValue: '',
      billetNumber: '',
      paidAt: new Date(),
      paymentDeadline: new Date(),
    },
  });

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getVendorTeams() {
    try {
      setPageLoading(true);
      const response = await getFilterTeamsListRequest({
        type: TeamType.VENDOR,
      });
      setTeamList(response);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(true);
      errorNotification({
        title: 'Erro ao buscar equipes.',
        message: 'tente novamente',
      });
    }
  }

  function getPostals() {
    if (getOrdersByPaymentData) {
      getPostalsByOrderFetcher(getOrdersByPaymentData.map((item) => item.id));
    }
  }

  async function handleCancelNFSe() {
    if (selectedOrderPayment) {
      try {
        setConfirmCancelNFSeVisible(false);
        setPageLoading(true);
        await cancelNFSeRequest(selectedOrderPayment.id);
        setPageLoading(false);
        setPageModal(null);
        successNotification({
          title: 'Cancelamento solicitado.',
          message: '',
        });
      } catch (error) {
        setPageLoading(false);
        successNotification({
          title: 'Erro ao solicitar cancelamento.',
          message: '',
        });
      }
    }
  }

  async function showNFSeDetails(orderPayment: OrderPaymentCustomResponse) {
    try {
      getPaymentOrders(orderPayment.id);
      setPageLoading(true);
      const response = await showNFSeRequest(orderPayment.order_id);
      setNFSeData(response);
      setSelectedOrderPayment(orderPayment);
      setPageModal('view-nfse');
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      errorNotification({
        title: 'Erro ao buscar dados da nota fiscal',
        message: 'talvez este faturamento não possua nota.',
      });
    }
  }

  async function handleCancelPayment(id: number) {
    await cancelPaymentFetcher({
      paymentId: id,
      onSuccess: () => {
        setPageModal(null);
        setSelectedOrderPayment(undefined);
        getOrderPaymentsPaginated();
      },
    });
  }

  async function getOrderPaymentsPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setPageLoading(true);
      if (isReset) {
        const result = await getOrderPaymentsRequest({
          page: 1,
          limit: 10,
        });
        setPaymentList(result);
      } else {
        const result = await getOrderPaymentsRequest({
          ...validatedFilter,
          paymentDeadline: Date.parse(validatedFilter.paymentDeadline?.[0])
            ? `${dayjsPlugins(validatedFilter.paymentDeadline[0]).format(
                'YYYY-MM-DD',
              )},${dayjsPlugins(validatedFilter.paymentDeadline[1]).format(
                'YYYY-MM-DD',
              )}`
            : undefined,
          page: currentPage.current,
        });
        setPaymentList(result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  async function handleSetOrderPayment(values: typeof formOrderPayment.values) {
    if (selectedOrderPayment) {
      try {
        setPageLoading(true);
        await setOrderPaymentRequest(selectedOrderPayment.id, {
          ...values,
          paymentStatus: values.paymentStatus as OrderPaymentStatus,
          paymentValue: realToUSCash(values.paymentValue),
          taxValue: realToUSCash(values.taxValue),
        });
        setPageLoading(false);
        successNotification({
          title: 'Tudo certo!',
          message: 'Pedido atualizado.',
        });
        setPageModal(null);
        getOrderPaymentsPaginated();
      } catch (error: any) {
        setPageLoading(false);
        errorNotification({
          title: 'Erro ao atualizar pedido.',
          message: translateServerHttpErrors(error, 'Tente novamente'),
        });
      }
    }
  }

  async function getCompanyDocs(companyId: number) {
    await getCompanyDocumentsFetcher({ companyId });
  }

  async function getPaymentOrders(id: number) {
    await getOrdersByPaymentFetcher({
      paymentId: id,
    });
  }

  async function handleGenerateServiceNftoPayment(
    values: typeof formEmitNFSe.values,
    paymentId: number,
  ) {
    if (!getOrdersByPaymentData) return;

    await generateNfToPaymentFetcher({
      paymentId,
      data: {
        companyDocumentId: Number(values.companyDocumentId),
        discrimination: values.discrimination,
        orderPrice: Number(realToUSCash(values.orderPrice)),
        serviceCode: values.serviceCode,
        taxesPercentage: Number(values.taxesPercentage),
        orders: getOrdersByPaymentData?.map((item) => item.id),
        emiterCNPJ: values.emiterCNPJ,
      },
      onSuccess: () => {
        setSelectedOrderPayment(undefined);
        formEmitNFSe.reset();
        setPageModal(null);
        getOrderPaymentsPaginated();
      },
    });
  }

  async function handleSetAsPaid(paymentId: number) {
    await setAsPaidFetcher({
      id: paymentId,
      onSuccess: () => {
        getOrderPaymentsPaginated();
      },
    });
  }

  function showNFSeModal(paymentItem: OrderPaymentCustomResponse) {
    getCompanyDocs(paymentItem.company_id);
    getOrdersByPaymentFetcher({ paymentId: paymentItem.id });
    getPostals();
    getReceipts();
    setSelectedOrderPayment(paymentItem);
    setPageModal('generate-nfse');
  }

  function confirmPaymentToCancel(paymentItem: OrderPaymentCustomResponse) {
    setSelectedOrderPayment(paymentItem);
    setPageModal('cancel-payment');
  }

  function handleOrderPayment(order: OrderPaymentCustomResponse) {
    setSelectedOrderPayment(order);
    getOrdersByPaymentFetcher({ paymentId: order.id });
    setPageModal('payment');
  }

  function handleOrderConfirmPayment(order: OrderPaymentCustomResponse) {
    setSelectedOrderPayment(order);
    getOrdersByPaymentFetcher({ paymentId: order.id });
    setPageModal('confirm-payment');
  }

  function handleSubmit() {
    currentPage.current = 1;
    getOrderPaymentsPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderPaymentsPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getOrderPaymentsPaginated(true);
  }

  function handleCalculatePostalTotalPrice() {
    if (getPostalsByOrderData) {
      return getPostalsByOrderData.reduce((accumulator, currentValue) => {
        accumulator += Number(currentValue.postal.totalPrice);
        return accumulator;
      }, 0.0);
    }
  }

  function renderPostalItem() {
    return (
      <>
        {!getPostalsByOrderData ? (
          <Skeleton height={55} radius="md" mt={16} />
        ) : getPostalsByOrderData?.length ? (
          <Accordion
            variant="separated"
            mt={16}
            styles={{
              item: {
                background: '#f2cc0c36',
                '&[data-active]': {
                  background: '#f2cc0c16',
                },
              },
            }}
          >
            <NfPostalItem postals={getPostalsByOrderData} />
          </Accordion>
        ) : (
          <Text fw="bold" ta="center" align="center" mt={16}>
            <Flex justify="center" align="center">
              <IconTruckOff /> Sem correios encontrados
            </Flex>
          </Text>
        )}
      </>
    );
  }

  function renderOrderItem() {
    return (
      <Accordion
        variant="separated"
        styles={{
          item: {
            '&[data-active]': {
              background: '#f7f7f7',
            },
          },
        }}
      >
        {getOrdersByPaymentData && (
          <NfOrderItem
            order={getOrdersByPaymentData.map(
              (item) =>
                ({
                  ...item,
                  service: { name: item.serviceName },
                } as unknown as Order),
            )}
          />
        )}
      </Accordion>
    );
  }

  async function getReceipts() {
    if (getOrdersByPaymentData) {
      await getReceiptByOrderFetcher({
        query: {
          orderIds: getOrdersByPaymentData.map((item) => item.id).join(','),
        },
      });
    }
  }

  function handleCloseModal() {
    setSelectedOrderPayment(undefined);
    setPageModal(null);
  }

  async function handleRequestInvoiceOrderListExport() {
    if (getOrdersByPaymentData && getOrdersByPaymentData.length) {
      invoiceOrderListExportFetcher({
        query: {
          os: getOrdersByPaymentData.map((item) => item.os).join(','),
        },
        onSuccess: (data) => {
          setPageModal('exportable-invoice');
          download(data, 'pedidos-faturamento.xlsx');
        },
      });
    }
  }

  useEffect(() => {
    getPostals();
    getReceipts();
  }, [getOrdersByPaymentData?.length]);

  useEffect(() => {
    if (selectedOrderPayment) {
      formOrderPayment.setValues({
        paidAt: dayjsPlugins(
          selectedOrderPayment.paidAt ?? new Date(),
        ).toDate(),
        paymentDeadline: dayjsPlugins(
          selectedOrderPayment.paymentDeadline ?? new Date(),
        ).toDate(),
        paymentNote: selectedOrderPayment.paymentNote,
        paymentStatus: selectedOrderPayment.paymentStatus,
        paymentValue: formatBRL(selectedOrderPayment.paymentValue.toFixed(2)),
        taxValue: formatBRL(selectedOrderPayment.taxValue.toFixed(2)),
        billetNumber: selectedOrderPayment.billetNumber,
        transactionType: selectedOrderPayment.transactionType,
      });

      const orderPrice = getOrderPrices({
        clichePrice: selectedOrderPayment.cliche_price.toFixed(2),
        manipulationPrice: selectedOrderPayment.manipulation_price.toFixed(2),
        pholitoPrice: selectedOrderPayment.pholito_price.toFixed(2),
        price: selectedOrderPayment.price.toFixed(2),
        samplePrice: selectedOrderPayment.sample_price.toFixed(2),
        vectorizationPrice: selectedOrderPayment.vectorization_price.toFixed(2),
        changeColorPrice: Number(
          selectedOrderPayment.change_color_price,
        ).toFixed(2),
        changeColorTotalPrice:
          selectedOrderPayment.change_color_total_price.toFixed(2),
        quantity: 1,
      });

      const postalTotalPrice = handleCalculatePostalTotalPrice() ?? 0;

      orderPrice.totalPrice = formatBRL(
        (
          Number(realToUSCash(orderPrice.totalPrice)) + postalTotalPrice
        ).toFixed(2),
      );

      setOrderPricesData(orderPrice);

      if (selectedOrderPayment.company_id !== companyFinancialFields?.id) {
        getCompanyToFinance(selectedOrderPayment.company_id).then((company) =>
          setCompanyFinancialFields(company),
        );
      }
    }
  }, [selectedOrderPayment, getPostalsByOrderData]);

  useEffect(() => {
    let totalPrice = 0;
    let discriminationArr: string[] = [];

    if (getOrdersByPaymentData) {
      const orders = getOrdersByPaymentData.map(
        (item) =>
          ({
            ...item,
            service: { name: item.serviceName },
          } as unknown as Order),
      );

      for (const iterator of orders) {
        const orderPrice = getOrderPrices(iterator);
        const discriminationGenArr = getNFSEDiscrimination(
          iterator,
          orderPrice,
        );
        discriminationGenArr.splice(discriminationGenArr.length - 1, 1);

        if (!discriminationArr.length) {
          discriminationArr = discriminationGenArr;
        } else {
          discriminationGenArr.splice(0, 1);
          discriminationArr = [...discriminationArr, ...discriminationGenArr];
        }

        totalPrice += Number(realToUSCash(orderPrice.totalPrice));

        formEmitNFSe.setValues({
          discrimination: getNFSEDiscrimination(iterator, orderPrice).join(
            '\n',
          ),
          financialNote: iterator.financialNote,
        });
      }
    }
    if (getPostalsByOrderData?.length) {
      const postalTotalPrice = handleCalculatePostalTotalPrice() ?? 0;
      totalPrice += postalTotalPrice;
      discriminationArr.push(
        `\n${getPostalsByOrderData.length} Correios ${formatBRL(
          postalTotalPrice.toFixed(2),
        )}`,
      );
    }
    discriminationArr.push(
      `\nCARGA TRIBUTÁRIA CONFORME LEI 12.471/2012 ${formatBRL(
        totalPrice.toFixed(2),
      )}`,
    );
    formEmitNFSe.setFieldValue('orderPrice', formatBRL(totalPrice.toFixed(2)));
    formEmitNFSe.setFieldValue('discrimination', discriminationArr.join('\n'));
  }, [getOrdersByPaymentData, getPostalsByOrderData]);

  useEffect(() => {
    getCompanies();
    getOrderPaymentsPaginated();
    getVendorTeams();
    getNfProvidersFetcher();
  }, []);

  return (
    <Page title="Pagamento">
      <CustomLoader
        loading={
          pageLoading ||
          cancelPaymentLoader ||
          getCompanyDocumentsLoader ||
          generateNfToPaymentLoader ||
          getOrdersByPaymentLoader ||
          setAsPaidLoader ||
          getReceiptByOrderLoader
        }
      />

      {user?.type === UserType.MASTER && (
        <FinancialPaymentAdmin
          items={paymentList?.items}
          companyList={companyList}
          teamList={teamList}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          currentPage={currentPage.current}
          meta={paymentList?.meta}
          formFilter={formFilter}
          handleOrderPayment={handleOrderPayment}
          handleClearFilter={handleClearFilter}
          handleClear={handleClearFilter}
        />
      )}

      {user?.role === UserRole.FINANCIAL && (
        <FinancialPaymentPage
          items={paymentList?.items}
          companyList={companyList}
          teamList={teamList}
          handlePaginate={handlePaginate}
          handleSubmit={handleSubmit}
          currentPage={currentPage.current}
          meta={paymentList?.meta}
          formFilter={formFilter}
          handleOrderPayment={handleOrderPayment}
          handleClearFilter={handleClearFilter}
          handleClear={handleClearFilter}
          user={user}
          handleOrderConfirmPayment={handleOrderConfirmPayment}
          handleSetAsPaid={handleSetAsPaid}
          showNFSeDetails={showNFSeDetails}
          showNFSeModal={showNFSeModal}
          confirmPaymentToCancel={confirmPaymentToCancel}
        />
      )}
      <Modal
        size={700}
        opened={pageModal === 'payment'}
        onClose={() => handleCloseModal()}
        title="Pagamento"
      >
        <Group mb={16} position="right">
          <Button
            disabled={!getOrdersByPaymentData}
            variant="subtle"
            color="orange"
            leftIcon={<IconDownload />}
            onClick={handleRequestInvoiceOrderListExport}
          >
            Exportar
          </Button>
        </Group>
        {renderOrderItem()}
        {renderPostalItem()}
        <NfReceiptItem data={getReceiptByOrderResponse ?? []} />
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info do pedido
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrderPayment?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Prazo de Pagamento</Text>
              <Text color="gray">
                {selectedOrderPayment?.paymentDeadline &&
                  formatLocale(
                    selectedOrderPayment?.paymentDeadline,
                    'DD/MM/YYYY',
                  )}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Obs. Fiscal</Text>
              <Text color="gray">{selectedOrderPayment?.financial_note}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            {orderPricesData && (
              <AlternativePriceList
                prices={orderPricesData}
                postalsTotalPrice={handleCalculatePostalTotalPrice()}
              />
            )}
          </Grid.Col>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info do Tomador
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Razão Social</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_razao_social ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Nome Fantasia</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_nome_fantasia ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">CNPJ</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_document ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Inscrição Estadual</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_inscricao_estadual ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Inscrição Municipal</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_inscricao_municipal ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form onSubmit={formOrderPayment.onSubmit(handleSetOrderPayment)}>
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <Text fw="bold" color="gray">
                Alterar Status de Pagamento
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                name="paymentStatus"
                withAsterisk
                label="Status de Pagamento"
                placeholder="selecione um status"
                data={orderPaymentStatusList.filter(
                  (item) => item.value !== 'paid',
                )}
                mt={16}
                {...formOrderPayment.getInputProps('paymentStatus')}
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Prazo de Pagamento"
                name="paymentDeadline"
                mt={16}
                mb={0}
                {...formOrderPayment.getInputProps('paymentDeadline')}
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Pago em"
                name="paidAt"
                mb={0}
                {...formOrderPayment.getInputProps('paidAt')}
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor Pago"
                placeholder="digite o valor"
                type="text"
                name="paymentValue"
                value={formOrderPayment.values.paymentValue}
                onChange={(e) =>
                  formOrderPayment.setValues({
                    paymentValue: formatBRL(e.target.value),
                  })
                }
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor Taxa"
                placeholder="digite o valor da taxa"
                type="text"
                name="taxValue"
                value={formOrderPayment.values.taxValue}
                onChange={(e) =>
                  formOrderPayment.setValues({
                    taxValue: formatBRL(e.target.value),
                  })
                }
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Número do Boleto"
                placeholder="digite o numero"
                type="text"
                name="billetNumber"
                {...formOrderPayment.getInputProps('billetNumber')}
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                name="transactionType"
                withAsterisk
                label="Tipo de Transação"
                placeholder="selecione o tipo"
                data={orderTransactionTypeList}
                {...formOrderPayment.getInputProps('transactionType')}
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Observações de Pagamento"
                placeholder="adicione informações do pagamento"
                name="paymentNote"
                {...formOrderPayment.getInputProps('paymentNote')}
                disabled={user?.type === UserType.MASTER}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button
              color="dark"
              type="submit"
              disabled={user?.type === UserType.MASTER}
            >
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={700}
        opened={pageModal === 'confirm-payment'}
        onClose={() => handleCloseModal()}
        title="Confirmar Pagamento"
      >
        {renderOrderItem()}
        {renderPostalItem()}
        <NfReceiptItem data={getReceiptByOrderResponse ?? []} />
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info do pedido
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">OS</Text>
              <Text color="gray">{selectedOrderPayment?.os ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Limite de Pagamento</Text>
              <Text color="gray">
                {selectedOrderPayment?.paymentDeadline &&
                  formatLocale(
                    selectedOrderPayment?.paymentDeadline,
                    'DD/MM/YYYY',
                  )}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Obs. Fiscal</Text>
              <Text color="gray">{selectedOrderPayment?.financial_note}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            {orderPricesData && (
              <AlternativePriceList
                prices={orderPricesData}
                postalsTotalPrice={handleCalculatePostalTotalPrice()}
              />
            )}
          </Grid.Col>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info do Tomador
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Razão Social</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_razao_social ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Nome Fantasia</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_nome_fantasia ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">CNPJ</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_document ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Inscrição Estadual</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_inscricao_estadual ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Inscrição Municipal</Text>
              <Text color="gray">{`${
                selectedOrderPayment?.doc_inscricao_municipal ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form onSubmit={formOrderPayment.onSubmit(handleSetOrderPayment)}>
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <Text fw="bold" color="gray">
                Alterar Status de Pagamento
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                name="paymentStatus"
                withAsterisk
                label="Status de Pagamento"
                placeholder="selecione um status"
                data={orderPaymentStatusList}
                mt={16}
                {...formOrderPayment.getInputProps('paymentStatus')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Prazo de Pagamento"
                name="paymentDeadline"
                mt={16}
                mb={0}
                {...formOrderPayment.getInputProps('paymentDeadline')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <CustomDatePicker
                required
                withAsterisk
                placeholder="selecione uma data"
                label="Pago em"
                name="paidAt"
                mb={0}
                {...formOrderPayment.getInputProps('paidAt')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor Pago"
                placeholder="digite o valor"
                type="text"
                name="paymentValue"
                value={formOrderPayment.values.paymentValue}
                onChange={(e) =>
                  formOrderPayment.setValues({
                    paymentValue: formatBRL(e.target.value),
                  })
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor Taxa"
                placeholder="digite o valor da taxa"
                type="text"
                name="taxValue"
                value={formOrderPayment.values.taxValue}
                onChange={(e) =>
                  formOrderPayment.setValues({
                    taxValue: formatBRL(e.target.value),
                  })
                }
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                label="Número do Boleto"
                placeholder="digite o numero"
                type="text"
                name="billetNumber"
                {...formOrderPayment.getInputProps('billetNumber')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <Select
                required
                name="transactionType"
                withAsterisk
                label="Tipo de Transação"
                placeholder="selecione o tipo"
                data={orderTransactionTypeList}
                {...formOrderPayment.getInputProps('transactionType')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Observações de Pagamento"
                placeholder="adicione informações do pagamento"
                name="paymentNote"
                {...formOrderPayment.getInputProps('paymentNote')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={700}
        opened={pageModal === 'view-nfse'}
        onClose={() => handleCloseModal()}
        title="Detalhes da NFS-e"
      >
        <Text fw="bold" color="gray">
          Pedidos nesse faturamento
        </Text>
        <Accordion
          variant="separated"
          styles={{
            item: {
              '&[data-active]': {
                background: '#f7f7f7',
              },
            },
          }}
        >
          {getOrdersByPaymentData && (
            <NfOrderItem
              order={getOrdersByPaymentData.map(
                (item) =>
                  ({
                    ...item,
                    service: { name: item.serviceName },
                  } as unknown as Order),
              )}
            />
          )}
        </Accordion>

        {!getPostalsByOrderData ? (
          <Skeleton height={55} radius="md" mt={16} />
        ) : getPostalsByOrderData?.length ? (
          <Accordion
            variant="separated"
            mt={16}
            styles={{
              item: {
                background: '#f2cc0c36',
                '&[data-active]': {
                  background: '#f2cc0c16',
                },
              },
            }}
          >
            <NfPostalItem postals={getPostalsByOrderData} />
          </Accordion>
        ) : (
          <Text fw="bold" ta="center" align="center" mt={16}>
            <Flex justify="center" align="center">
              <IconTruckOff /> Sem correios encontrados
            </Flex>
          </Text>
        )}

        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info do Tomador
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Razão Social</Text>
              <Text color="gray">{`${
                companyFinancialFields?.razaoSocial ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Nome Fantasia</Text>
              <Text color="gray">{`${
                companyFinancialFields?.nomeFantasia ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">CNPJ</Text>
              <Text color="gray">{`${
                companyFinancialFields?.document ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Inscrição Estadual</Text>
              <Text color="gray">{`${
                companyFinancialFields?.inscricaoEstadual ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Inscrição Municipal</Text>
              <Text color="gray">{`${
                companyFinancialFields?.inscricaoMunicipal ?? '--'
              }`}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text fw="bold" color="gray">
              Info da NFSe
            </Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Número da nota fiscal</Text>
              <Text color="gray">{nfseData?.numero ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Status</Text>
              <Badge maw={150}>{nfseData?.status}</Badge>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Data da Emissão</Text>
              <Text color="gray">
                {formatDateTime(nfseData?.data_emissao ?? '')}
              </Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Arquivo PDF</Text>
              <a href={nfseData?.url_danfse} target="_blank">
                <Button h={25}>Ver</Button>
              </a>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Arquivo XML</Text>
              <a
                href={`https://api.focusnfe.com.br${nfseData?.caminho_xml_nota_fiscal}`}
                target="_blank"
              >
                <Button h={25}>Ver</Button>
              </a>
            </Flex>
          </Grid.Col>
        </Grid>
        <Flex justify="flex-end" mt={16}>
          <Popover
            opened={confirmCancelNFSeVisible}
            onChange={setConfirmCancelNFSeVisible}
            width={200}
            position="bottom"
            withArrow
            shadow="md"
          >
            <Popover.Target>
              <Button
                onClick={() => setConfirmCancelNFSeVisible(true)}
                color="red"
                type="submit"
              >
                Cancelar NFSe
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Text size="sm">Deseja realmente cancelar essa NFSe ?</Text>
              <Group>
                <Button onClick={handleCancelNFSe} color="green" type="submit">
                  Sim
                </Button>
                <Button
                  onClick={() => setConfirmCancelNFSeVisible(false)}
                  color="blue"
                  type="submit"
                >
                  Não
                </Button>
              </Group>
            </Popover.Dropdown>
          </Popover>
        </Flex>
      </Modal>
      <Modal
        size={700}
        opened={pageModal === 'cancel-payment'}
        onClose={() => handleCloseModal()}
        title={<Text fw="bold">Cancelar faturamento</Text>}
      >
        <Text>
          Deseja cancelar o faturamento no valor de{' '}
          {formatBRL(selectedOrderPayment?.totalPrice.toFixed(2))} ?
        </Text>
        <Group mt={16}>
          <Button
            onClick={() =>
              selectedOrderPayment &&
              handleCancelPayment(selectedOrderPayment?.id)
            }
            color="green"
            type="submit"
          >
            Sim
          </Button>
          <Button
            onClick={() => {
              setSelectedOrderPayment(undefined);
              setPageModal(null);
            }}
            color="red"
            type="submit"
          >
            Não
          </Button>
        </Group>
      </Modal>
      <Modal
        size={700}
        opened={pageModal === 'generate-nfse'}
        onClose={() => handleCloseModal()}
        title="Faturamento"
      >
        {renderOrderItem()}
        {renderPostalItem()}
        <NfReceiptItem data={getReceiptByOrderResponse ?? []} />
        <Text mt={16} color="dark">
          imprimir documentos ?
        </Text>
        <form
          onSubmit={formEmitNFSe.onSubmit(
            (values) =>
              selectedOrderPayment &&
              handleGenerateServiceNftoPayment(
                values,
                selectedOrderPayment?.id,
              ),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                clearable
                searchable
                name="emiterCNPJ"
                label="Empresa Prestadora"
                placeholder="selecione o CNPJ do prestador"
                data={getNfProvidersResponse?.map((item) => ({
                  label: `${item.name}`,
                  value: item.value,
                }))}
                mb={16}
                mr={8}
                {...formEmitNFSe.getInputProps('emiterCNPJ')}
              />
              <Divider />
            </Grid.Col>
            <Grid.Col span={2}>
              <Select
                withAsterisk
                required
                clearable
                searchable
                name="company"
                label="CNPJ do Tomador"
                placeholder="selecione o CNPJ para faturar"
                data={
                  getCompanyDocumentsData?.map((item) => ({
                    label: `${item.nomeFantasia} (${item.document})`,
                    value: item.id,
                  })) ?? []
                }
                mb={16}
                mr={8}
                {...formEmitNFSe.getInputProps('companyDocumentId')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Text fw="bold" color="gray">
                Campos Fiscais
              </Text>
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Percentual Carga Tributária"
                placeholder="ex: 7.50"
                mb={16}
                type="number"
                name="taxesPercentage"
                {...formEmitNFSe.getInputProps('taxesPercentage')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Código do Serviço"
                placeholder="ex: 01565"
                mb={16}
                type="text"
                name="serviceCode"
                {...formEmitNFSe.getInputProps('serviceCode')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                required
                withAsterisk
                label="Valor Total do Serviço"
                placeholder="ex: R$152,00"
                mb={16}
                type="text"
                name="orderPrice"
                value={formEmitNFSe.values.orderPrice}
                onChange={(e) =>
                  formEmitNFSe.setFieldValue(
                    'orderPrice',
                    formatBRL(e.target.value),
                  )
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                required
                withAsterisk
                autosize
                label="Discriminação"
                placeholder="discriminação do serviço"
                name="discrimination"
                value={formEmitNFSe.values.discrimination}
                onChange={(e) =>
                  formEmitNFSe.setFieldValue('discrimination', e.target.value)
                }
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Textarea
                maxLength={255}
                autosize
                label="Obs Fiscais"
                placeholder="observações fiscais"
                name="financialNote"
                {...formEmitNFSe.getInputProps('financialNote')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <ExportableModal
        title="Exportável de Faturamento"
        loading={invoiceOrderListExportLoader}
        opened={pageModal === 'exportable-invoice'}
        onClose={() => setPageModal(null)}
      />
    </Page>
  );
}
