import { ReactNode } from 'react';
import usePermissionsContext from './use-permissions-context';

export function useRenderByPermission() {
  const { list: listPermissions } = usePermissionsContext();

  const renderByPermission = (
    permission: string[],
    component: () => ReactNode,
  ) => listPermissions.some((p) => permission.includes(p)) && component();

  return {
    renderByPermission,
  };
}
