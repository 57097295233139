import { useFetch } from '../../../hooks/use-fetch';
import {
  ServiceCheckItem,
  ServiceCheckItemPopulated,
} from '../../../models/service-check-item';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateServiceCheckItemProps,
  UseListServiceCheckItemByServiceProps,
  UseListServiceCheckItemProps,
  UseRemoveServiceCheckItemProps,
  UseRestoreServiceCheckItemProps,
} from '../../../utils/types/data/hooks/service-check-items';
import {
  createServiceCheckItemRequest,
  listServiceCheckItemByServiceRequest,
  listServiceCheckItemRequest,
  removeServiceCheckItemRequest,
  restoreServiceCheckItemRequest,
} from '../../services/service-check-items';

export function useCreateServiceCheckItem() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({
    serviceId,
    data,
    onSuccess,
  }: UseCreateServiceCheckItemProps) =>
    fetchData({
      fetcher: () => createServiceCheckItemRequest(serviceId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar item de serviço.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListServiceCheckItemByService() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ServiceCheckItem[]>();

  const fetch = ({
    serviceId,
    onSuccess,
  }: UseListServiceCheckItemByServiceProps) =>
    fetchData({
      fetcher: () => listServiceCheckItemByServiceRequest(serviceId),
      onError: () => {
        errorNotification({
          title: 'Erro ao listar itens de serviço.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListServiceCheckItem() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ServiceCheckItemPopulated[]>();

  const fetch = ({ onSuccess }: UseListServiceCheckItemProps) =>
    fetchData({
      fetcher: () => listServiceCheckItemRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao listar itens de serviço.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRemoveServiceCheckItem() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ serviceId, onSuccess }: UseRemoveServiceCheckItemProps) =>
    fetchData({
      fetcher: () => removeServiceCheckItemRequest(serviceId),
      onError: () => {
        errorNotification({
          title: 'Erro ao remover item de serviço.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRestoreServiceCheckItem() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ serviceId, onSuccess }: UseRestoreServiceCheckItemProps) =>
    fetchData({
      fetcher: () => restoreServiceCheckItemRequest(serviceId),
      onError: () => {
        errorNotification({
          title: 'Erro ao restaurar item de serviço.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
