import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PermissionAlias } from '../../../utils/enums';
import { UseGetPermissionsByUserProps } from '../../../utils/types/data/hooks/permissions';
import { getPermissionsByUserRequest } from '../../services/permissions';

export function useGetPermissionsByUser() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<PermissionAlias[]>();

  const fetch = ({ onSuccess }: UseGetPermissionsByUserProps) =>
    fetchData({
      fetcher: () => getPermissionsByUserRequest(),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar permissões.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}
