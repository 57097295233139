import { Order } from '../../../models/order';

export function setOrderTableRowBackgroundColor(record: Order) {
  if (record.rework) {
    return { backgroundColor: 'rgba(255, 17, 0, 0.027)' };
  } else if (record.isSample) {
    return { backgroundColor: 'rgba(0, 255, 0, 0.027)' };
  } else if (record.origin) {
    return { backgroundColor: 'rgba(0, 42, 255, 0.05)' };
  }
}
