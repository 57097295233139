import { Button, Flex, Group, Modal, ScrollArea, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';

import { Page } from '../../components/Page';
import { useGetOrderMaterialFiles } from '../../data/hooks/order-materials';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import {
  associateOrderMaterialToComapanyRequest,
  paginatedOrderMaterialsPendingRequest,
} from '../../data/services/order-material';
import { OrderMaterial } from '../../models/order-material';
import { UserRole, UserType } from '../../models/user';
import { errorNotification } from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';

import { FilePreview } from '../../components/FilePreview';
import { PageMeta } from '../../utils/types';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import { OrderMaterialDetails } from '../OrderMaterialDetails';
import { ReceptionistOrderMaterialPendingList } from './receptionist';
import { VendorOrderMaterialPendingList } from './vendor';
import { translateServerHttpErrors } from '../../utils/helpers';
import { OrderMaterialsPaginatedParams } from '../../utils/types/data/services/order-material';

export type FormFilterType = OrderMaterialsPaginatedParams;

export type PageModalType =
  | 'order-company-associate'
  | 'order-material-view'
  | null;

export type FormAssociateCompany = {
  companyId: number | '';
};

export function OrderMaterialListPending() {
  const { user } = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [pageModalVisible, setPageModalVisible] = useState<PageModalType>(null);
  const [orderMaterialList, setOrderMaterialList] = useState<OrderMaterial[]>(
    [],
  );
  const [selectedOrderMaterial, setSelectedOrderMaterial] =
    useState<OrderMaterial>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const page = useRef(1);
  const pageLimit = useRef(10);
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);

  const formAssociateCompany = useForm<FormAssociateCompany>({
    initialValues: {
      companyId: '',
    },
  });

  const {
    fetch: getOrderMaterialFilesFetcher,
    loading: getOrderMaterialFilesLoader,
    response: getOrderMaterialFilesData,
  } = useGetOrderMaterialFiles();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: page.current,
      code: '',
      product: '',
      productColor: '',
      productMaterial: '',
      username: '',
      quantity: undefined,
      quantityVolume: undefined,
      receivedAt: null as any,
      showAssociates: false,
    },
  });

  function handlePaginate(newPage: number) {
    page.current = newPage;
    getOrderMaterial(false);
  }

  function handleChangePageLimit(limit: number) {
    pageLimit.current = limit;
    getOrderMaterial();
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getOrderMaterial(isReset = true) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setLoading(true);
      const response = await paginatedOrderMaterialsPendingRequest({
        ...(isReset ? {} : validatedFilter),
        limit: pageLimit.current ?? 10,
        page: page.current ?? 1,
      });
      setOrderMaterialList(response.items);
      setPageMeta(response.meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscar ARs!',
        message: 'tente novamente.',
      });
    }
  }

  async function handleFilter() {
    getOrderMaterial(false);
  }

  async function handleClearFilter() {
    page.current = 1;
    pageLimit.current = 10;
    formFilter.reset();
    getOrderMaterial();
  }

  async function getFiles(id: number) {
    await getOrderMaterialFilesFetcher({
      id,
    });
  }

  async function handleAssociateCompany(
    values: typeof formAssociateCompany.values,
  ) {
    if (!selectedOrderMaterial) {
      return;
    }
    try {
      setLoading(true);
      await associateOrderMaterialToComapanyRequest(
        Number(selectedOrderMaterial.id),
        {
          companyId: Number(values.companyId),
        },
      );
      setLoading(false);
      getOrderMaterial(false);
      formAssociateCompany.reset();
      setSelectedOrderMaterial(undefined);
      setPageModalVisible(null);
    } catch (error: any) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao atualizar AR.',
        message: translateServerHttpErrors(error, 'tente novamente'),
      });
    }
  }

  useEffect(() => {
    getOrderMaterial();
    getCompanies();
  }, []);

  useEffect(() => {
    if (selectedOrderMaterial?.id) {
      getFiles(selectedOrderMaterial.id);
    }
  }, [selectedOrderMaterial?.id]);

  return (
    <Page title="ARs pendentes">
      <CustomLoader loading={getOrderMaterialFilesLoader} />
      {user?.role === UserRole.RECEPTIONIST && (
        <ReceptionistOrderMaterialPendingList
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          loading={loading}
          orderMaterialList={orderMaterialList}
          page={page}
          pageMeta={pageMeta}
          setPageModalVisible={setPageModalVisible}
          setSelectedOrderMaterial={setSelectedOrderMaterial}
          handleChangePageLimit={handleChangePageLimit}
        />
      )}
      {user?.role === UserRole.VENDOR && (
        <VendorOrderMaterialPendingList
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          loading={loading}
          orderMaterialList={orderMaterialList}
          page={page}
          pageMeta={pageMeta}
          handleChangePageLimit={handleChangePageLimit}
          setSelectedOrderMaterial={setSelectedOrderMaterial}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      {user?.type === UserType.MASTER && (
        <VendorOrderMaterialPendingList
          formFilter={formFilter}
          handleClearFilter={handleClearFilter}
          handleFilter={handleFilter}
          handlePaginate={handlePaginate}
          loading={loading}
          orderMaterialList={orderMaterialList}
          page={page}
          pageMeta={pageMeta}
          handleChangePageLimit={handleChangePageLimit}
          setSelectedOrderMaterial={setSelectedOrderMaterial}
          setPageModalVisible={setPageModalVisible}
        />
      )}
      <Modal
        opened={pageModalVisible === 'order-material-view'}
        onClose={() => setPageModalVisible(null)}
        title="Detalhes da AR"
        size="lg"
      >
        <ScrollArea h={600}>
          <OrderMaterialDetails
            orderMaterialIdProps={selectedOrderMaterial?.id}
          />
          <Flex wrap="wrap">
            {getOrderMaterialFilesData?.map((item) => (
              <FilePreview
                key={item.file}
                file={{
                  id: item.file,
                  url: item.fileUrl,
                  type: '',
                  ext: item.fileName.split('.')[1],
                  name: item.fileName.split('.')[0],
                }}
              />
            ))}
          </Flex>
        </ScrollArea>
      </Modal>
      <Modal
        opened={pageModalVisible === 'order-company-associate'}
        onClose={() => {
          setSelectedOrderMaterial(undefined);
          formAssociateCompany.reset();
          setPageModalVisible(null);
        }}
        title="Associar cliente"
        size="xs"
      >
        <Flex direction={'column'}>
          <form
            onSubmit={formAssociateCompany.onSubmit((values) =>
              handleAssociateCompany(values),
            )}
          >
            <Select
              required
              withAsterisk
              searchable
              name="companyId"
              label="Cliente"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formAssociateCompany.getInputProps('companyId')}
            />
            <Group position="right">
              <Button type="submit">Salvar</Button>
            </Group>
          </form>
        </Flex>
      </Modal>
    </Page>
  );
}
