import { Menu, Button, Flex, Modal, Grid, Text, Table } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCash,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconHistory,
  IconTrash,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomFileInput } from '../../components/CustomFileInput';
import { Page } from '../../components/Page';
import { useUploadMultipleFiles } from '../../data/hooks/files';
import {
  getBillsRequest,
  payBillRequest,
  removeBillRequest,
} from '../../data/services/bill';
import { Bill } from '../../models/bill';
import { BillAttachment } from '../../models/bill-attachment';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { AppRoutePaths } from '../../utils/enums';
import { formatBRL } from '../../utils/helpers';

type FormType = {
  file: File | null;
};

type PageModal = 'pay-bill' | 'bill-attachments' | null;

function AttachmentList({ attachments }: { attachments: BillAttachment[] }) {
  return (
    <Flex direction="column">
      <Text fw="bold">Histórico de Comprovantes</Text>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Pago em</th>
            <th>Usuário</th>
            <th>Link do Comprovante</th>
          </tr>
        </thead>
        <tbody>
          {attachments.map((item) => (
            <tr>
              <td>{formatLocale(item.createdAt, 'DD/MM/YYYY')}</td>
              <td>{item.username}</td>
              <td>
                <a href={item.file.url} target="_blank">
                  link
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Flex>
  );
}

export function BillList() {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageModal, setPageModal] = useState<PageModal>(null);
  const [billList, setBillList] = useState<Bill[]>([]);
  const [selectedBill, setSelectedBill] = useState<Bill>();
  const { fetch: uploadFilesFetcher } = useUploadMultipleFiles();

  const form = useForm<FormType>({
    initialValues: {
      file: null,
    },
  });

  async function getBills() {
    try {
      setLoading(true);
      const response = await getBillsRequest();
      setBillList(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscar contas!',
        message: 'tente novamente.',
      });
    }
  }

  async function handleRemove(id: number) {
    try {
      setLoading(true);
      await removeBillRequest(id);
      const cloneBillList = billList;
      const foundedBill = cloneBillList.findIndex((item) => item.id === id);
      if (foundedBill !== -1) {
        cloneBillList.splice(foundedBill, 1);
        setBillList(cloneBillList);
      }
      setLoading(false);
      successNotification({
        title: 'Compra removida!',
        message: 'tudo certo.',
      });
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao remover conta!',
        message: 'tente novamente.',
      });
    }
  }

  function selectBillToPay(bill: Bill) {
    setSelectedBill(bill);
    setPageModal('pay-bill');
  }

  function selectBillAttachments(bill: Bill) {
    setSelectedBill(bill);
    setPageModal('bill-attachments');
  }

  async function handleSubmit(id: number, values: typeof form.values) {
    try {
      setLoading(true);
      let fileId: number[] = [];
      await uploadFilesFetcher({
        data: {
          files: [values.file as File],
        },
        onSuccess: (res) => {
          fileId = res;
        },
      });
      await payBillRequest(id, {
        ...values,
        file: { id: fileId[0] },
      });
      setLoading(false);
      form.reset();
      setSelectedBill(undefined);
      setPageModal(null);
      successNotification({
        title: 'Muito bem!',
        message: 'conta paga.',
      });
      getBills();
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao pagar conta!',
        message: 'tente novamente.',
      });
    }
  }

  useEffect(() => {
    getBills();
  }, []);

  return (
    <Page title="Contas">
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="dark"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.BILLS_CREATE)}
          >
            Nova
          </Button>
        </Flex>
        <DataTable
          fetching={loading}
          minHeight={500}
          noRecordsText="Sem contas"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={billList}
          columns={[
            {
              accessor: 'name',
              title: 'Nome',
            },
            {
              accessor: 'price',
              title: 'Valor',
              render: ({ price }) => formatBRL(price ?? ''),
            },
            {
              accessor: 'monthRecurrence',
              title: 'Recorrencia mensal',
            },
            {
              accessor: 'payDay',
              title: 'Dia de vencimento',
            },
            {
              accessor: 'paidedAt',
              title: 'Pago em',
              render: ({ paidedAt }) =>
                paidedAt ? formatLocale(paidedAt, 'DD/MM/YYYY') : 'pendente',
            },
            {
              accessor: '',
              title: '',
              render: (billItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => navigation(`edit/${billItem.id}`)}
                      icon={<IconEdit size={14} />}
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => handleRemove(billItem.id)}
                      icon={<IconTrash size={14} />}
                    >
                      Apagar
                    </Menu.Item>
                    <Menu.Item
                      disabled={billItem.isPaid}
                      onClick={() => selectBillToPay(billItem)}
                      icon={<IconCash size={14} />}
                    >
                      Pagamento
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => selectBillAttachments(billItem)}
                      icon={<IconHistory size={14} />}
                    >
                      Comprovantes
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        opened={pageModal === 'pay-bill'}
        onClose={() => setPageModal(null)}
        title="Pagamento de conta"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info da conta</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Nome</Text>
              <Text color="gray">{selectedBill?.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Valor</Text>
              <Text color="gray">{formatBRL(selectedBill?.price) ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Vencimento</Text>
              <Text color="gray">{'dia ' + selectedBill?.payDay ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Descrição</Text>
              <Text color="gray">{selectedBill?.description ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            {/* <Flex direction="column">
              <Text fw="bold">Descrição</Text>
              <Text color="gray">{selectedBill?.description ?? '--'}</Text>
            </Flex> */}
            <AttachmentList attachments={selectedBill?.attachments ?? []} />
          </Grid.Col>
        </Grid>
        <form
          onSubmit={form.onSubmit((values) =>
            handleSubmit(Number(selectedBill?.id), values),
          )}
        >
          <Grid gutter="xs" columns={2} mt={8}>
            <Grid.Col span={2}>
              <CustomFileInput
                withAsterisk
                required
                name="file"
                value={form.values.file}
                label="Comprovante de pagamento"
                placeholder="Anexe aqui o comprovante"
                formRef={form}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end" mt={16}>
            <Button color="dark" type="submit">
              Enviar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        opened={pageModal === 'bill-attachments'}
        onClose={() => setPageModal(null)}
        title="Pagamento de conta"
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col mt={8} mb={8} span={2}>
            <Text color="gray">Info da conta</Text>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Nome</Text>
              <Text color="gray">{selectedBill?.name ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Valor</Text>
              <Text color="gray">{formatBRL(selectedBill?.price) ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex direction="column">
              <Text fw="bold">Vencimento</Text>
              <Text color="gray">{'dia ' + selectedBill?.payDay ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold">Descrição</Text>
              <Text color="gray">{selectedBill?.description ?? '--'}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <AttachmentList attachments={selectedBill?.attachments ?? []} />
          </Grid.Col>
        </Grid>
      </Modal>
    </Page>
  );
}
