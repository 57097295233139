import { useFetch } from '../../../hooks/use-fetch';
import {
  ActivityProduction,
  ActivityProductionByOrder,
  ActivityProductionHistoryResponse,
} from '../../../models/activity-production';
import { ActivityProductionFile } from '../../../models/activity-production-file';
import { errorNotification } from '../../../providers/mantine-notifications';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseAddActivityProductionFileProps,
  UseCreateActivityProductionProps,
  UseGetActivityProductionByOrderProps,
  UseGetActivityProductionFileProps,
  UseGetActivityProductionHistoryProps,
  UseGetActivityProductionProps,
  UseGetActivityProductionToApproveProps,
  UseRequestTechnicalApprovalActivityProductionProps,
  UseTechnicalApproveActivityProductionProps,
} from '../../../utils/types/data/hooks/activity-productions';
import { ActivityProductionToApproveResponse } from '../../../utils/types/data/services/activity-productions';
import {
  addActivityProductionsFilesRequest,
  createActivityProductionRequest,
  getActivityProductionByOrderRequest,
  getActivityProductionFilesRequest,
  getActivityProductionHistoryRequest,
  getActivityProductionsRequest,
  getActivityProductionToApproveRequest,
  requestTechnicalApprovalActivityProductionRequest,
  technicalApproveActivityProductionRequest,
} from '../../services/activity-productions';

export function useCreateActivityProduction() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProduction>();

  const fetch = ({ activityId, onSuccess }: UseCreateActivityProductionProps) =>
    fetchData({
      fetcher: () => createActivityProductionRequest(activityId),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar layout de produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useAddActivityProductionFile() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseAddActivityProductionFileProps) =>
    fetchData({
      fetcher: () => addActivityProductionsFilesRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar aquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useTechinicalApproveActivityProduction() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProduction>();

  const fetch = ({
    data,
    id,
    onSuccess,
  }: UseTechnicalApproveActivityProductionProps) =>
    fetchData({
      fetcher: () => technicalApproveActivityProductionRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao aprovar layout de Produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRequestTechnicalApprovalActivityProduction() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProduction>();

  const fetch = ({
    id,
    onSuccess,
  }: UseRequestTechnicalApprovalActivityProductionProps) =>
    fetchData({
      fetcher: () => requestTechnicalApprovalActivityProductionRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao solicitar aprovação do layout de Produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityProduction() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProduction[]>();

  const fetch = ({ activityId }: UseGetActivityProductionProps) =>
    fetchData({
      fetcher: () => getActivityProductionsRequest(activityId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts de Produção.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityProductionFile() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProductionFile[]>();

  const fetch = ({ query }: UseGetActivityProductionFileProps) =>
    fetchData({
      fetcher: () => getActivityProductionFilesRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos do layout de Produção.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityProductionToApprove() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<ActivityProductionToApproveResponse>>();

  const fetch = ({ query }: UseGetActivityProductionToApproveProps) =>
    fetchData({
      fetcher: () => getActivityProductionToApproveRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layout de Produção.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityProductionByOrder() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProductionByOrder>();

  const fetch = ({ orderId }: UseGetActivityProductionByOrderProps) =>
    fetchData({
      fetcher: () => getActivityProductionByOrderRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts de Produção.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityProductionHistory() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityProductionHistoryResponse[]>();

  const fetch = ({ orderId }: UseGetActivityProductionHistoryProps) =>
    fetchData({
      fetcher: () => getActivityProductionHistoryRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar histórico layouts da produção.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
