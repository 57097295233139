import {
  FinancialStatus,
  OrderPaymentStatus,
  OrderProblem,
} from '../../models/order';

export const orderPaymentTypeList = [
  { label: 'A Vista', value: 'cash_payment' },
  { label: 'Boleto', value: 'bank_slip' },
  { label: 'Virtual', value: 'virtual' },
  { label: 'Amostra', value: 'service_sample' },
  {
    label: 'Fechamento Quinzenal - Boleto',
    value: 'fechamento_fortnightly_slip',
  },
  {
    label: 'Fechamento Quinzenal - Transferência ',
    value: 'fechamento_fortnightly_cash',
  },
  { label: 'Fechamento Semanal - Boleto', value: 'fechamento_weekly_slip' },
  {
    label: 'Fechamento Semanal - Transferência ',
    value: 'fechamento_weekly_cash',
  },
  { label: 'Fechamento Mensal - Boleto ', value: 'fechamento_monthly_slip' },
  {
    label: 'Fechamento Mensal - Transferência',
    value: 'fechamento_monthly_cash',
  },
];

export const orderPaymentStatusList = [
  { label: 'Pendente', value: 'pending' },
  { label: 'Pago', value: 'paid' },
  { label: 'Não Pago', value: 'not_paid' },
  { label: 'Revisado', value: 'reviewed' },
];

export const orderFinancialStatusList = [
  { label: 'Indefinido', value: FinancialStatus.INITIAL },
  { label: 'Faturado', value: FinancialStatus.INVOICED },
  { label: 'Pendente', value: FinancialStatus.PENDING },
];

export const orderTransactionTypeList = [
  { label: 'Boleto', value: 'Boleto' },
  { label: 'Cheque', value: 'Cheque' },
  { label: 'Crédito', value: 'Crédito' },
  { label: 'Dinheiro', value: 'Dinheiro' },
  { label: 'Débito', value: 'Débito' },
  { label: 'PagSeguro', value: 'PagSeguro' },
  { label: 'Transf. Bradesco', value: 'Transf. Bradesco' },
  { label: 'Transf. Caixa', value: 'Transf. Caixa' },
  { label: 'Transf. Itaú', value: 'Transf. Itaú' },
  { label: 'Transf. Santander', value: 'Transf. Santander' },
];

export const priceTypeHumanized: Record<string, string> = {
  samplePrice: 'Amostra:',
  photolitoPrice: 'Fotolito:',
  manipulationPrice: 'Manuseio:',
  vectorizationPrice: 'Vetorização:',
  clichePrice: 'Clichê:',
  price: 'Serviço:',
  changeColorTotalPrice: 'Troca de cor',
};

export const financialStatusColor = {
  [FinancialStatus.INITIAL]: 'blue',
  [FinancialStatus.INVOICED]: 'green',
  [FinancialStatus.PENDING]: 'orange',
};

export const financialStatusTypeHumanized = {
  [FinancialStatus.INITIAL]: 'indefinido',
  [FinancialStatus.INVOICED]: 'faturado',
  [FinancialStatus.PENDING]: 'pendente',
};

export const orderPaymentTypeHumanized: Record<string, string> = {
  cash_payment: 'A Vista',
  bank_slip: 'Boleto',
  virtual: 'Virtual',
  service_sample: 'Amostra',
  fechamento_fortnightly_slip: 'Fechamento Quinzenal - Boleto',
  fechamento_fortnightly_cash: 'Fechamento Quinzenal - Transferência',
  fechamento_weekly_slip: 'Fechamento Semanal - Boleto',
  fechamento_weekly_cash: 'Fechamento Semanal - Transferência ',
  fechamento_monthly_slip: 'Fechamento Mensal - Boleto',
  fechamento_monthly_cash: 'Fechamento Mensal - Transferência',
};

export const paymentStatusColor = {
  [OrderPaymentStatus.PENDING]: 'gray',
  [OrderPaymentStatus.PAID]: 'green',
  [OrderPaymentStatus.NOT_PAID]: 'red',
  [OrderPaymentStatus.REVIEWED]: 'orange',
};

export const paymentStatusTypeHumanized = {
  [OrderPaymentStatus.PENDING]: 'pendente',
  [OrderPaymentStatus.PAID]: 'pago',
  [OrderPaymentStatus.NOT_PAID]: 'não pago',
  [OrderPaymentStatus.REVIEWED]: 'revisado',
};

export const orderProbemTypeHumanized = {
  [OrderProblem.INCORRECT_QUANTITY]: 'Quantidade Incorreta',
  [OrderProblem.PRODUCT_DEFECT]: 'Produto com Defeito',
};

export const orderDefectOptions = [
  {
    label: 'Produto com defeito ',
    value: 'product_defect',
  },
  {
    label: 'Quantidade Incorreta',
    value: 'incorrect_quantity',
  },
];

export const orderClichePriceOptions = [
  { label: '8,00x8,00cm', value: '18750' },
  { label: '9,00x9,00cm', value: '21500' },
  { label: '10,00x10,00cm', value: '22500' },
  { label: '12,00x12,00cm', value: '29400' },
  { label: '15,00x15,00cm', value: '43200' },
  { label: '18,00x18,00cm', value: '60000' },
  { label: '20,00x20,00cm', value: '72500' },
];

export const orderSortList = [
  { label: 'Maior preço', value: 'price_desc' },
  { label: 'Menor preço', value: 'price_asc' },
  { label: 'Mais recente', value: 'created_desc' },
  { label: 'Mais antigo', value: 'created_asc' },
];
