import { AxiosResponse } from 'axios';
import { OrderMaterial } from '../../models/order-material';
import { OrderMaterialVolume } from '../../models/order-material-volume';
import { api } from '../../providers/base-api';
import { OrderMaterialFileResponse } from '../../utils/types/data/services/order-material-files';
import {
  CreateOrderMaterialData,
  CreateOrderMaterialVolumeData,
  OrderMaterialsPaginatedParams,
  OrderMaterialsPaginatedResponse,
  TransferOrderMaterialVolumeData,
  UpdateOrderMaterialData,
  ValidateOrderMaterialData,
} from '../../utils/types/data/services/order-material';

export async function createOrderMaterialRequest(
  data: CreateOrderMaterialData,
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(`order-materials`, data);

  return response.data;
}

export async function paginatedOrderMaterialsRequest(
  params: OrderMaterialsPaginatedParams,
): Promise<AxiosResponse<OrderMaterialsPaginatedResponse>> {
  const response = await api.get<OrderMaterialsPaginatedResponse>(
    'order-materials',
    { params },
  );

  return response;
}

export async function showOrderMaterialsRequest(
  id: number,
): Promise<AxiosResponse<OrderMaterial>> {
  const response = await api.get<OrderMaterial>(`order-materials/${id}`);

  return response;
}

export async function updateOrderMaterialRequest(
  id: number,
  data: UpdateOrderMaterialData,
): Promise<OrderMaterial> {
  const response = await api.put<OrderMaterial>(`order-materials/${id}`, data);

  return response.data;
}

export async function associateOrderMaterialRequest(
  id: number,
  data: { orderId: number },
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(
    `order-materials/associate/${id}`,
    data,
  );

  return response.data;
}

export async function validateOrderMaterialRequest(
  id: number,
  data: ValidateOrderMaterialData,
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(
    `order-materials/validate/${id}`,
    data,
  );

  return response.data;
}

export async function deleteOrderMaterialRequest(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(`order-materials/${id}`);

  return response;
}

export async function getOrderMaterialFilesRequest(
  id: number,
): Promise<AxiosResponse<OrderMaterialFileResponse[]>> {
  const response = await api.get<OrderMaterialFileResponse[]>(
    `order-materials/files/${id}`,
  );

  return response;
}

export async function deleteOrderMaterialFileRequest(
  fileId: number,
): Promise<AxiosResponse<void>> {
  const response = await api.delete<void>(
    `order-materials/remove-file/${fileId}`,
  );

  return response;
}

export async function paginatedOrderMaterialsPendingRequest(
  params: OrderMaterialsPaginatedParams,
): Promise<OrderMaterialsPaginatedResponse> {
  const response = await api.get<OrderMaterialsPaginatedResponse>(
    'order-materials/company/pending',
    { params },
  );

  return response.data;
}

export async function associateOrderMaterialToComapanyRequest(
  id: number,
  data: { companyId: number },
): Promise<OrderMaterial> {
  const response = await api.post<OrderMaterial>(
    `order-materials/associate/company/${id}`,
    data,
  );

  return response.data;
}

export async function getOrderMaterialVolumes(
  id: number,
): Promise<AxiosResponse<OrderMaterialVolume[]>> {
  const response = await api.get(`order-materials/${id}/volumes`);

  return response;
}

export async function getOrderMaterialByOrder(
  orderId: number,
): Promise<AxiosResponse<OrderMaterial[]>> {
  const response = await api.get(`order-materials/by-order/${orderId}`);

  return response;
}

export async function createOrderMaterialVolume(
  id: number,
  data: CreateOrderMaterialVolumeData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `order-materials/${id}/add-volumes`,
    data,
  );

  return response;
}

export async function transferOrderMaterialVolume(
  volumeId: number,
  data: TransferOrderMaterialVolumeData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `order-materials/volume/${volumeId}/transfer`,
    data,
  );

  return response;
}

export async function finishOrderMaterialVolumeTransfer(
  volumeId?: number,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(
    `order-materials/${volumeId}/finish-transfer`,
  );

  return response;
}

export async function requestMaterialTransfer(
  id: number,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `order-materials/${id}/request-transfer`,
  );

  return response;
}
