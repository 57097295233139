import { useFetch } from '../../../hooks/use-fetch';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseCreateActivityChecklistsProps,
  UseListActivityChecklistsProps,
} from '../../../utils/types/data/hooks/activity-checklists';
import { ListActivityChecklistResponse } from '../../../utils/types/data/services/activity-checklist';
import {
  createActivityChecklistRequest,
  listActivityChecklistRequest,
} from '../../services/activity-checklists';

export function useCreateActivityChecklist() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({
    activityId,
    data,
    onSuccess,
  }: UseCreateActivityChecklistsProps) =>
    fetchData({
      fetcher: () => createActivityChecklistRequest(activityId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar checklist.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useListActivityChecklist() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ListActivityChecklistResponse[]>();

  const fetch = ({ query, onSuccess }: UseListActivityChecklistsProps) =>
    fetchData({
      fetcher: () => listActivityChecklistRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao listar checklist.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
