import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconCopy, IconRefreshDot, IconTestPipe2 } from '@tabler/icons';
import { Order } from '../../../models/order';

export function renderOrderTableRowIcon(order: Order) {
  if (order.rework) {
    return (
      <Tooltip color="grape" label="retrabalho">
        <ThemeIcon color="grape" variant="light">
          <IconRefreshDot size={24} />
        </ThemeIcon>
      </Tooltip>
    );
  } else if (order.isSample) {
    return (
      <Tooltip color="green" label="amostra">
        <ThemeIcon color="green" variant="light">
          <IconTestPipe2 size={24} />
        </ThemeIcon>
      </Tooltip>
    );
  } else if (order.origin) {
    return (
      <Tooltip color="blue" label="repetição">
        <ThemeIcon color="blue" variant="light">
          <IconCopy size={24} />
        </ThemeIcon>
      </Tooltip>
    );
  }
}
