import { Activity } from './activity';
import { ActivityProductionFile } from './activity-production-file';
import { User } from './user';

export enum ActivityProductionStatus {
  TECHNICAL_APPROVE = 'technical_approve',
  APPROVED = 'approved',
  REPROVED = 'reproved',
}

export type ActivityProduction = {
  id: number;
  activity: number;
  artUser: number;
  status: ActivityProductionStatus;
  technicalName: string;
  technicalNote: string;
  approval: string;
  createdAt: string;
};

export type ActivityProductionPopulated = Omit<
  ActivityProduction,
  'activity' | 'artUser'
> & {
  activity: Activity;
  artUser: User;
};

export type ActivityProductionByOrder = {
  activityProduction: ActivityProduction;
  files: ActivityProductionFile[];
};

export type ActivityProductionHistoryResponse = {
  layout: {
    id: number;
    status: ActivityProductionStatus;
    technicalName: string;
    technicalNote: string;
    createdAt: string;
    approval: string;
    files: ActivityProductionFile[];
  };
};
