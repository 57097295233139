import {
  Badge,
  Button,
  Flex,
  Grid,
  Menu,
  Modal,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCirclePlus,
  IconDotsVertical,
  IconTrash,
  IconTrashOff,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import {
  useCreateServiceCheckItem,
  useListServiceCheckItem,
  useRemoveServiceCheckItem,
  useRestoreServiceCheckItem,
} from '../../data/hooks/service-check-items';
import { formatLocale } from '../../providers/dayjs-plugins';
import { RootState } from '../../providers/store';
import { getServicesDispatcher } from '../../store/services';

type PageModalVisibleState = null | 'create-check-item';

export function ServiceCheckItemList() {
  const dispatch = useDispatch<any>();
  const { items: serviceItems, loading: serviceLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const { fetch: createCheckItemFetcher, loading: createCheckItemLoader } =
    useCreateServiceCheckItem();
  const { fetch: removeCheckItemFetcher, loading: removeCheckItemLoader } =
    useRemoveServiceCheckItem();
  const {
    fetch: listCheckItemFetcher,
    reponseData: listCheckItemData,
    loading: listCheckItemLoader,
  } = useListServiceCheckItem();
  const { fetch: restoreCheckItemFetcher, loading: restoreCheckItemLoader } =
    useRestoreServiceCheckItem();
  const [pageModalVisible, setPageModalVisible] =
    useState<PageModalVisibleState>(null);

  const createItemForm = useForm({
    initialValues: {
      text: '',
      service: '',
    },
  });

  async function handleCreate(values: typeof createItemForm.values) {
    await createCheckItemFetcher({
      data: {
        text: values.text,
      },
      serviceId: Number(values.service),
      onSuccess: () => {
        listCheckItems();
        setPageModalVisible(null);
        createItemForm.reset();
      },
    });
  }

  async function handleRemove(serviceCheckItemId: number) {
    await removeCheckItemFetcher({
      serviceId: serviceCheckItemId,
      onSuccess: () => {
        listCheckItems();
      },
    });
  }

  async function handleRestore(serviceCheckItemId: number) {
    await restoreCheckItemFetcher({
      serviceId: serviceCheckItemId,
      onSuccess: () => {
        listCheckItems();
      },
    });
  }

  async function listCheckItems() {
    await listCheckItemFetcher({});
  }

  useEffect(() => {
    dispatch(getServicesDispatcher());
    listCheckItems();
  }, []);

  return (
    <Page title="Itens de checklist">
      <CustomLoader
        loading={
          createCheckItemLoader ||
          removeCheckItemLoader ||
          restoreCheckItemLoader ||
          serviceLoading ||
          listCheckItemLoader
        }
      />
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <Button
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => setPageModalVisible('create-check-item')}
          >
            Novo Item
          </Button>
        </Flex>
        <DataTable
          minHeight={200}
          noRecordsText="Sem itens"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          height={390}
          records={listCheckItemData}
          columns={[
            {
              accessor: 'serviceName',
              title: 'Serviço',
              render: ({ service }) => service.name,
            },
            {
              accessor: 'text',
              title: 'Texto',
              width: 410,
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ deletedAt }) => (
                <Badge color={deletedAt ? 'red' : 'green'}>
                  {deletedAt ? 'inativo' : 'ativo'}
                </Badge>
              ),
            },
            {
              accessor: 'finishedAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'menu',
              title: '',
              render: (checkItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      disabled={!!checkItem.deletedAt}
                      onClick={() => handleRemove(checkItem.id)}
                      icon={<IconTrash size={14} />}
                    >
                      Remover
                    </Menu.Item>
                    <Menu.Item
                      disabled={!checkItem.deletedAt}
                      onClick={() => handleRestore(checkItem.id)}
                      icon={<IconTrashOff size={14} />}
                    >
                      Restaurar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        size={400}
        opened={pageModalVisible === 'create-check-item'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Criar novo item</Text>}
      >
        <form
          onSubmit={createItemForm.onSubmit((values) => handleCreate(values))}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                withAsterisk
                name="service"
                label="Serviço"
                placeholder="selecione o serviço"
                data={serviceItems.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                mb={16}
                {...createItemForm.getInputProps('service')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TextInput
                label="Texto"
                placeholder="texto do item de checklist"
                mb={16}
                mr={8}
                type="text"
                name="text"
                maxLength={254}
                {...createItemForm.getInputProps('text')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Criar
            </Button>
          </Flex>
        </form>
      </Modal>
    </Page>
  );
}
