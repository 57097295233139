import { AxiosResponse } from 'axios';
import {
  ActivityProduction,
  ActivityProductionByOrder,
  ActivityProductionHistoryResponse,
} from '../../models/activity-production';
import { ActivityProductionFile } from '../../models/activity-production-file';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  ActivityProductionTechnicalApproverRequestData,
  ActivityProductionToApproveResponse,
  AddActivityProductionFilesRequestData,
  GetActivityProductionFileRequestQuery,
  GetActivityProductionToApproveRequestQuery,
} from '../../utils/types/data/services/activity-productions';

export async function createActivityProductionRequest(
  activityId: number,
): Promise<AxiosResponse<ActivityProduction>> {
  const response = await api.post<ActivityProduction>(
    `activity-productions/${activityId}`,
  );

  return response;
}

export async function technicalApproveActivityProductionRequest(
  id: number,
  data: ActivityProductionTechnicalApproverRequestData,
): Promise<AxiosResponse<ActivityProduction>> {
  const response = await api.put<ActivityProduction>(
    `activity-productions/technical-approve/${id}`,
    data,
  );

  return response;
}

export async function requestTechnicalApprovalActivityProductionRequest(
  id: number,
): Promise<AxiosResponse<ActivityProduction>> {
  const response = await api.put<ActivityProduction>(
    `activity-productions/request-techinical/${id}`,
  );

  return response;
}

export async function getActivityProductionsRequest(
  activityId: number,
): Promise<AxiosResponse<ActivityProduction[]>> {
  const response = await api.get<ActivityProduction[]>(
    `activity-productions/${activityId}`,
  );

  return response;
}

export async function addActivityProductionsFilesRequest(
  id: number,
  data: AddActivityProductionFilesRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(
    `activity-productions/files/${id}`,
    data,
  );

  return response;
}

export async function getActivityProductionFilesRequest(
  query: GetActivityProductionFileRequestQuery,
): Promise<AxiosResponse<ActivityProductionFile[]>> {
  const response = await api.get<ActivityProductionFile[]>(
    `activity-productions/files`,
    { params: query },
  );

  return response;
}

export async function getActivityProductionToApproveRequest(
  query: GetActivityProductionToApproveRequestQuery,
): Promise<
  AxiosResponse<PaginatedResponse<ActivityProductionToApproveResponse>>
> {
  const response = await api.get<
    PaginatedResponse<ActivityProductionToApproveResponse>
  >(`activity-productions/to-approve`, { params: query });

  return response;
}

export async function getActivityProductionByOrderRequest(
  orderId: number,
): Promise<AxiosResponse<ActivityProductionByOrder>> {
  const response = await api.get<ActivityProductionByOrder>(
    `activity-productions/order/${orderId}`,
  );

  return response;
}

export async function getActivityProductionHistoryRequest(
  orderId: number,
): Promise<AxiosResponse<ActivityProductionHistoryResponse[]>> {
  const response = await api.get<ActivityProductionHistoryResponse[]>(
    `activity-productions/historic/${orderId}`,
  );

  return response;
}
